import React, { useState, useEffect } from 'react';
import { makeStyles, Paper } from '@material-ui/core';

import clsx from 'clsx';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ApartmentIcon from '@material-ui/icons/Apartment';
import DescriptionIcon from '@material-ui/icons/Description';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import RoomIcon from '@material-ui/icons/Room';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import './index.css';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link, animateScroll as scroll } from 'react-scroll';

const useStyles = makeStyles((theme) => ({
  sideBarContainer: {
    // boxShadow:
    //   'inset 0 4px 7px 1px #ffffff, inset 0 -5px 20px rgba(173, 186, 204, 0.25), 0 2px 6px rgba(0, 21, 64, 0.14), 0 10px 20px rgba(0, 21, 64, 0.05)',
    //boxShadow: '0px 5px 9px 8px rgba(241, 243, 255, 1)',
  },
  sidebarList: {},
  sidebarItem: {
    color: 'black',
    marginBottom: 24,
    padding: 15,
  },
  sidebarItemActive: {
    transition: 'all ease .4s',
    color: 'white',
    marginLeft: '2rem',
    maxWidth: '180px',
    borderRadius: '5px',
    marginBottom: 24,
    padding: 15,
    background: '#ffb703 0% 0% no-repeat padding-box',
    boxShadow: '0px 0px 20px #00000029',
    '&:hover': {
      background: '#ffb703',
    },
    '&:focus': {
      background: '#ffb703',
    },
  },
  sidebarIconActive: {
    fontSize: '2rem',
    fill: 'white',
  },
  sidebarIcon: {
    fontSize: '2rem',
    fill: 'black',
  },
}));

const marketPlaceDetails = [
  {
    menu: 'Investment overview',
    link: 'investment',
    icons: AssessmentIcon,
  },
  {
    menu: 'Property Highlights',
    link: 'property',
    icons: ApartmentIcon,
  },
  {
    menu: 'Legal Documents',
    link: 'documents',
    icons: DescriptionIcon,
  },
  {
    menu: 'Financial Overview',
    link: 'financial',
    icons: CreditCardIcon,
  },
  {
    menu: 'Webinars',
    link: 'webinars',
    icons: OndemandVideoIcon,
  },
  {
    menu: 'Location',
    link: 'location',
    icons: RoomIcon,
  },
  {
    menu: 'FAQs',
    link: 'faqs',
    icons: LiveHelpIcon,
  },
];

const dashboardSideBarData = [
  {
    menu: 'Overview',
    link: 'overview',
    icons: '/assets/icons/dashboard/overview.svg',
  },
  {
    menu: 'My Investments',
    link: 'investment',
    icons: '/assets/icons/dashboard/my_investments.svg',
  },
  {
    menu: 'My Earnings',
    link: 'earnings',
    icons: '/assets/icons/dashboard/my_earnings.svg',
  },
  {
    menu: 'My DocVault',
    link: 'docVault',
    icons: '/assets/icons/dashboard/my_doc_vault.svg',
  },
  {
    menu: 'Authorised Users',
    link: 'users',
    icons: '/assets/icons/dashboard/authorised_users.svg',
  },
];

const accountSideBarData = [
  {
    menu: 'Account Details',
    link: 'account',
    icons: '/assets/icons/account/account.svg',
  },
  {
    menu: 'My Wallet',
    link: 'wallet',
    icons: '/assets/icons/account/wallet.svg',
  },
  {
    menu: 'My Preferences',
    link: 'preferences',
    icons: '/assets/icons/account/preferences.svg',
  },
  {
    menu: 'KYC Verification',
    link: 'verify',
    icons: '/assets/icons/account/kyc_verif.svg',
  },
  {
    menu: 'Change Password',
    link: 'password',
    icons: '/assets/icons/account/lock.svg',
  },
];

export default function SideBar(props) {
  const classes = useStyles();
  const [sideBarData, setSideBarData] = useState([]);
  const [type, setType] = useState('');
  const [mobileView, setMobileView] = useState(false);

  const handleType = (value) => {
    setType(value);
    props.sendOption(value);
  };

  useEffect(() => {
    if (window.location.pathname === '/dashboard') {
      setSideBarData(dashboardSideBarData);
      setType('overview');
    } else if (window.location.pathname === '/account') {
      setSideBarData(accountSideBarData);
      setType('account');
    } else {
      setSideBarData(marketPlaceDetails);
      setType('investment');
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (window.outerWidth < 576) {
      setMobileView(true);
    }
  }, [window.outerWidth]);

  return (
    <>
      {mobileView ? (
        <div className="sideBarMobileView">
          {sideBarData.map((menu, index) => {
            return (
              <label
                key={index}
                style={{ padding: 20 }}
                className={clsx(
                  type === menu.link
                    ? classes.sidebarItemActive
                    : classes.sidebarItem,
                )}
                onClick={() => handleType(menu.link)}
              >
                {menu.menu}
              </label>
            );
          })}
        </div>
      ) : (
        <>
          <Paper elevation={1} className={classes.sideBarContainer}>
            <List
              component="nav"
              className={classes.sidebarList}
              aria-label="sidebar navigation"
            >
              {sideBarData.map((menu, index) => {
                return (
                  <ListItem
                    className={clsx(
                      type === menu.link
                        ? classes.sidebarItemActive
                        : classes.sidebarItem,
                    )}
                    key={index}
                    button
                    onClick={() => handleType(menu.link)}
                    disableRipple
                    disableFocusRipple
                  >
                    <ListItemIcon style={{ minWidth: '42px' }}>
                      <img
                        src={menu.icons}
                        className={clsx(
                          type === menu.link
                            ? classes.sidebarIconActive
                            : classes.sidebarIcon,
                        )}
                      />
                    </ListItemIcon>
                    {menu.menu}
                  </ListItem>
                );
              })}
            </List>
          </Paper>
        </>
      )}
    </>
  );
}
