import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {currConv} from '../../../../services/currency';
import { useSelector, useDispatch } from 'react-redux';
import {getBaseUrl} from '../../../../utils/request'


const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(name, calories, fat) {
  return { name, calories, fat };
}

const rows = [
  createData('Rental Revenue', 159, 6.0),
  createData('Expense Reimbursements', 237, 9.0),
  createData('Profit Share', 262, 16.0),
  createData('Operating Expense', 305, 3.7),
  createData('Cash Flow', 356, 16.0),
];



export default function FinancialOverviewTable() {



const userLogin = useSelector((state) => state.userLogin);
// const chatbot = useSelector(state=>state.botterm)
const { userInfo } = userLogin;
console.log(userInfo)
  const classes = useStyles();

  function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
    
  }

  
  
  
  const [userCountry, setuserCountry] = useState('');
    
    useEffect(()=>{
    fetch(getBaseUrl('users'),{
      headers:{
        Authorization: 'Bearer ' + userInfo.accessToken,
        'Content-Type': 'application/json',
      }
    }).then((res)=>res.json()).then((data)=>setuserCountry(data.address.country)).catch((err)=>console.log(err));
    },[])

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow style={{ background: '#FFF9E8' }}>
            <TableCell style={{ borderBottom: '0px' }}>Details</TableCell>
            <TableCell
              align="right"
              style={{ fontWeight: 'bold', borderBottom: '0px' }}
            >
              Monthly
            </TableCell>
            <TableCell
              align="right"
              style={{ fontWeight: 'bold', borderBottom: '0px' }}
            >
              Annual
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={row.name}
              style={{
                background: `${index}` & 1 ? '#FFF9E8' : '',
              }}
            >
              <TableCell
                component="th"
                scope="row"
                style={{
                  borderBottom: '0px',
                }}
              >
                {row.name}
              </TableCell>
              <TableCell
                align="right"
                style={{
                  fontWeight: 'bold',
                  borderBottom: '0px',
                }}
              >
                 {numberWithCommas(currConv(row.calories,userCountry))}
           
              </TableCell>
              <TableCell
                align="right"
                style={{
                  fontWeight: 'bold',
                  borderBottom: '0px',
                }}
              >
                 {numberWithCommas(currConv(row.fat,userCountry))}
              
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
