import React from 'react';
import Select from 'react-select';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    width: 150,
  }),
  control: (provided, state) => ({
    ...provided,
    border: '1px solid #FBAF62',
    width: 150,
  }),
  menu: (provided, state) => ({
    ...provided,
    border: '1px solid #FBAF62',
    width: 150,
  }),
};

// export default class SelectDropDown extends React.Component {
//   state = {
//     selectedOption: null,
//     options: [],
//   };

//   componentDidMount() {
//     if (this.props.dataDetails) {
//       console.log('🐨');
//       console.log('this.props', this.props);
//       console.log('⚡');
//       this.setState({
//         options: this.props.dataDetails,
//         selectedOption: {
//           value: this.props.value,
//           label: this.props.value,
//         },
//       });
//     }
//   }

//   componentWillReceiveProps(newProps) {
//     if (newProps.dataDetails) {
//       console.log('newProps', newProps);
//       this.setState(
//         {
//           options: newProps.dataDetails,
//           selectedOption: {
//             value: newProps.value,
//             label: newProps.value,
//           },
//         },
//         () => {
//           if (window.location.pathname === 'account') {
//             this.props.getIncome(this.state.selectedOption);
//           }
//         },
//       );
//     }
//   }

//   handleChange = (selectedOption) => {
//     this.setState({ selectedOption }, () => {
//       this.props.getIncome(selectedOption);
//       if (window.location.pathname === 'account') {
//         this.props.sendWallet(selectedOption);
//       }
//     });
//     console.log(`Option selected:`, selectedOption);
//   };
//   render() {
//     const { selectedOption, options } = this.state;

//     return (
//       <Select
//         styles={customStyles}
//         value={selectedOption}
//         onChange={this.handleChange}
//         options={options}
//       />
//     );
//   }
// }

const SelectDropDown = (props) => {
  const [selectedOption, setSelectedOption] = React.useState('All');
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    if (props.dataDetails) {
      console.log('🐨');
      console.log('this.props', props);
      console.log('⚡');
      setOptions(props.dataDetails);
      setSelectedOption({ value: props.value, label: props.label });
      if (window.location.pathname === 'account') {
        props.getIncome(selectedOption);
      }
    }
  }, [window.location.pathname]);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    if (props.getIncome) {
      props.getIncome(selectedOption);
    }
    if (window.location.pathname === 'account') {
      props.sendWallet(selectedOption);
    }
    console.log(`Option selected:`, selectedOption);
  };

  return (
    <Select
      styles={customStyles}
      value={selectedOption}
      onChange={handleChange}
      options={options}
      placeholder={'All'}
    />
  );
};

export default SelectDropDown;
