import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import {Typography} from '@material-ui/core'

import { useInvestNowSection } from '../../../../context/investnow-context';




function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (

 <Tooltip
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={ <React.Fragment>
        <Typography color="inherit">Amount: $ {value}</Typography>
       
      </React.Fragment>}
      style={{ background: '#fff', color: '#000' }}
    >
      {children}
    </Tooltip>
   
   
  );
}

// function ValueLabelComponent(props) {
//   const { children, open, value } = props;

//   return (
//     <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
//       {children}
//     </Tooltip>
//   );
// }

// ValueLabelComponent.propTypes = {
//   children: PropTypes.element.isRequired,
//   open: PropTypes.bool.isRequired,
//   value: PropTypes.number.isRequired,
// };

const useStyles = makeStyles((theme) => ({
  boxCls: {
    background: '#FFF9E8 0% 0% no-repeat padding-box',
    borderRadius: 10,
    padding: 20,
  },
}));

// const PrettoSlider = withStyles({
//   root: {
//     color: '#FDC424',
//     height: 8,
//   },
//   thumb: {
//     height: 36,
//     width: 36,
//     backgroundColor: '#fff',
//     boxShadow: '3px 3px 6px #00000029',
//     marginTop: -10,
//     marginLeft: -12,
//     '&:focus, &:hover, &$active': {
//       boxShadow: 'inherit',
//     },
//   },
//   active: {},
//   valueLabel: {
//     left: 'calc(-50% + 20px)',
//   },
//   track: {
//     height: 18,
//     borderRadius: 13,
//   },
//   rail: {
//     height: 8,
//     borderRadius: 13,
//     marginTop: 5,
//     color: '#fff',
//   },
// })(Slider);

const ConfirmInvestment = () => {
  const classes = useStyles();
  const [amount, setAmount] = useState([1000]);
  const [investmentdata, setInvestmentData] = useInvestNowSection();

  const handleChange = (event, value) => {
    setAmount(value);
    setInvestmentData({
      ...investmentdata,
      amount: value[0],
    });
  };
  console.log('🐨');
  console.log(amount);
  console.log('🐨');
  console.log(investmentdata);

  const PrettoSlider = withStyles({
    root: {
      color: '#FDC424',
      height: 8,
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      marginTop: -8,
      marginLeft: -12,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 4px)',
    },
    track: {
      height: 8,
      borderRadius: 4,
    },
    rail: {
      height: 8,
      borderRadius: 4,
    },
    slider: {
      trackColor: "yellow",
      selectionColor: "red"
    }
  })(Slider);



  return (
    <div>
      <h3 className="text-center mt-5 mb-4">Confirm investment amount</h3>
      <div className={classes.boxCls}>
        <div className="mb-5">
          <label className="" style={{fontWeight:"bold"}}>Available Fractions 10</label>
          <label className="float-right" style={{fontWeight:"bold"}}>Price Per Fraction $10,000</label>
        </div>
        
       
        <PrettoSlider
          ValueLabelComponent={ValueLabelComponent}
          valueLabelDisplay="on"
          aria-label="pretto slider"
          min={20000}
          step={10000}
         
          max={100000}
          defaultValue={amount}
          value={amount}
          onChange={handleChange}
        />
        
        
        <div className="mt-5">
          <label className="" style={{fontWeight:"bold"}}>Min $20,000</label>
          <label className="float-right" style={{fontWeight:"bold"}}>Available $100,000</label>
        </div>
      </div>
    </div>
  );
};

export default ConfirmInvestment;
