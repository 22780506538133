import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useInvestNowSection } from '../../../../context/investnow-context';

const useStyles = makeStyles((theme) => ({
  boxCls: {
    background: '#FFF9E8 0% 0% no-repeat padding-box',
    borderRadius: 10,
    textAlign: 'center',
    margin: '20px 100px',
    padding: 10,
  },
}));

const ConfirmMeetingDetails = () => {
  const classes = useStyles();
  const [investmentdata] = useInvestNowSection();

  console.log('🐨');
  console.log(investmentdata);

  const { mode, date, time } = investmentdata;
  // console.log(date.toLocaleString());
  // console.log(date.toLocaleDateString());

  return (
    <div>
      <h5 className="text-center mt-5 mb-4">
        You have scheduled a call with our investment advisor at {time}
      </h5>
      <div className={classes.boxCls}>
        <h4>{date.toString()}</h4>
      </div>
      <p className="text-center">
        {mode} link for the call will be sent to your registered Email soon
      </p>
    </div>
  );
};

export default ConfirmMeetingDetails;
