import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Container,
  Grid,
  Paper,
  CardHeader,
  Avatar,
  Typography,
  Card,
  CardContent,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Helmet } from 'react-helmet-async';
import Chat from '../../components/Chatbot/Chat'

import newsBlogs from './../../static/home.json';
import notification from './../../static/notification.json';
import topicsDetails from './../../static/topics.json';
import Footer from './../../components/Footer';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import PropertyListings from './../../components/MarketPlace/PropertyListings';
import Carousel from 'react-multi-carousel';
import moment from 'moment';
import 'react-multi-carousel/lib/styles.css';
import './home.css';
import { Redirect } from 'react-router';
import CurrentTime from '../../components/CurrentTime';
import { CuratedInvestment } from './components';
import {settext} from '../../store/chatbot/chatbot.actions'
import { getBaseUrl } from '../../utils/request';


const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const useStyles = makeStyles((theme) => ({
  eventsCard: {
    borderRadius: '10px',
    margin: theme.spacing(1, 0),
    boxShadow: '0px 3px 10px #0000001A !important',
    // '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important',
  },
  cardContent: {
    padding: theme.spacing(1, 2),
  },
  cardHeader: {
    padding: theme.spacing(2, 2),
    paddingBottom: theme.spacing(1),
  },
  avatar: {
    color: 'gray',
    background: '#ffffff',
  },
  eventNum: {
    borderRadius: '22px',
    background: '#FDC424',
    color: '#ffffff',
    width: theme.spacing(7),
    height: theme.spacing(4),
    marginTop: '.5rem',
    '&:hover': {
      background: '#fdc424',
    },
  },

  
  yellowDots: {
    background: '#FECF0E',
    display: 'inline-block',
    marginRight: '.4rem',
    borderRadius: '50%',
    width: theme.spacing(1.4),
    height: theme.spacing(1.4),
  },
}));
const Tabs = (props) => {
  const [selected, setSelected] = useState(props.selected || 0);

  const handleChange = (index) => {
    setSelected(index);
  };

  return (
    <div>
      <ul className="inline">
        {props.children.map((elem, index) => {
          let style = index == selected ? 'selected' : '';
          return (
            <li
              className={style}
              key={index}
              onClick={() => handleChange(index)}
            >
              {elem.props.title}
            </li>
          );
        })}
      </ul>
      <div className="tab p-0">{props.children[selected]}</div>
    </div>
  );
};

const Panel = (props) => {
  return <div>{props.children}</div>;
};

const HomePage = () => {
  const classes = useStyles();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [newsData, setNewsData] = useState([]);
  const [investmentUpdateData, setInvestmentUpdateData] = useState([]);
  const [webinarData, setWebinarData] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
  const [meetingData, setMeetingData] = useState([]);

  useEffect(() => {
    if (userInfo) {
      const config = {
        headers: {
          Authorization: 'Bearer ' + userInfo.accessToken,
          'Content-Type': 'application/json',
        },
        query: {
          page: 1,
          per_page: 10,
        },
      };
      axios
        .get(getBaseUrl('news/all'), config)
        .then((res) => {
          console.log('ree', res);
          setNewsData(res.data.articles);
        });
        axios
        .get(getBaseUrl('tenants/current'), config)
        .then((res) => {
          console.log('TENANT', res);
         
        });
      axios
        .get(
         getBaseUrl('events/investmentUpdates'),
          config,
        )
        .then((res) => {
          console.log('ree', res);
          setInvestmentUpdateData(res.data);
        });
      axios
        .get(getBaseUrl('event/webinar'), config)
        .then((res) => {
          console.log('ree', res);
          setWebinarData(res.data);
        });
      axios
        .get(getBaseUrl('event/notification'), config)
        .then((res) => {
          console.log('ree', res);
          setNotificationData(res.data);
        });
      axios
        .get(getBaseUrl('event/meeting'), config)
        .then((res) => {
          console.log('ree', res);
          setMeetingData(res.data);
        });
    }
  }, [userInfo]);
  // console.log('🐨');
  // console.log(meetingData);
  // console.log(`🔥`);

const searchterm = useSelector(state=>state.searchterm);
const dispatch= useDispatch();

const [term, setterm] = useState('');





const hist = useHistory()
const [userTerm, setuserTerm] = useState('')



  const handleKey = (e)=>{
    if(e.key==='Enter')
    {
      
      console.log('Submitted')
      dispatch(settext(term))
      localStorage.setItem("term", term);
      setuserTerm(term)
      

    }
  }

  const handleTopicClick =(e)=>{
    dispatch(settext(e))
    localStorage.setItem("term", term);
    setuserTerm(term)
  }

  const [toggle, settoggle] = useState(false)

  useEffect(()=>{
if(userTerm!=='' || userTerm!==null)
{
  
  settoggle(true)
  
}

  },[userTerm])


const toggleFloat =()=>{
  settoggle(!toggle)
}

const botterm = useSelector((state) => state.chatReducer.botterm);
console.log(botterm);
 


  return (
    <>
      <Helmet>
        <title>Home Page</title>
        <meta name="description" content="A Boilerplate application homepage" />
      </Helmet>
      <Container component="main">
        <div className="welcomeCls">
          <Grid container>
            <Grid item sm={12} md={1} lg={2}>
              <img src="/assets/images/Sarah.svg" alt="sarah" height="370px" />
            </Grid>
            <Grid item sm={12} md={10} lg={8} className="pt-5">
              <CurrentTime />
              <input
                type="text"
                placeholder="I’m Mia, tell me what are you looking for today ?"
                className="welcomeSearch"
                onChange={(e)=>setterm(e.target.value)}
                onKeyDown={handleKey}
              />
              <div className="mt-5">
                <label className="mr-5">Suggested Topics</label>
                {topicsDetails.map((topic, id) => {
                  return (
                    <label key={id} className="topicBox" onClick={()=>handleTopicClick(topic.topic)}>
                      {topic.topic}
                    </label>
                  );
                })}
              </div>
            </Grid>
            <Grid item sm={12} md={1} lg={2} className="text-center mt-4">
              <img src="/assets/images/mia.svg" alt="Mia" height="300px" />
            </Grid>
          </Grid>
        </div>
        <Grid container className="mt-4">
          <Grid item sm={12} md={7} lg={7}>
            <div className="mb-5">
              <label className="headingCls pt-2">
                Mia’s curated personalized suggestions
              </label>
              <button className="btnCls" style={{ float: 'right' }} onClick={()=>hist.push('/account')}>
                Edit preferences
              </button>
            </div>
            <div className="">
              <Tabs selected={0}>
              <Panel title="News & Blogs">
                  <Carousel responsive={responsive}>
                    {newsData &&
                      newsData.map((data, id) => {
                        return (
                          <div className='newsCard' key={id}>
                            <img
                              src={data.urlToImage}
                              alt={data.title}
                              width="100%"
                              height="150px"
                            />
                            <div style={{padding:10}}>
                            <p className="pt-3">
                              <b>{data.title}</b>
                            </p>
                            <p className="contentCls">{data.content}</p>

                            </div>
                         
                          </div>
                        );
                      })}
                  </Carousel>
                  {/* <button className="btnCls" style={{float: "right"}}>Show more news</button> */}
                </Panel>
                <Panel title="Investment Updates">
                  <Carousel responsive={responsive}>
                    {investmentUpdateData.map((data, id) => {
                      return (
                        <div key={id} className='newsCard'>
                          <img
                            src="/assets/images/meeting.png"
                            alt="meeting"
                            width="100%"
                            height="150px"
                          />
                          <div style={{padding:10}}>
                          <p className="pt-3">
                            <b>{data.name}</b>
                          </p>
                          <p className="contentCls">{data.description}</p>

                          </div>
                   
                        </div>
                      );
                    })}
                  </Carousel>
                </Panel>
                <Panel title="Webinars">
                  <Carousel responsive={responsive}>
                    {webinarData &&
                      webinarData.map((data, id) => {
                        return (
                          <div key={id} className='newsCard'>
                            <img
                              src="/assets/images/meeting.png"
                              alt="meeting"
                              width="100%"
                              height="150px"
                            />
                            <div style={{padding:10}}>
                            <p className="pt-3">
                              <b>{data.name}</b>
                            </p>
                            <p className="contentCls">{data.description}</p>
                            </div>
                           
                          </div>
                        );
                      })}
                  </Carousel>
                </Panel>
              
              </Tabs>
            </div>
          </Grid>
          <Grid item sm={12} md={1} lg={1}></Grid>
          <Grid item sm={12} md={4} lg={4}>
            <p className="headingCls">Events and Notifications</p>
            <Tabs selected={0}>
              <Panel title="Upcoming Events">
                <Grid container>
                  {meetingData &&
                    meetingData.map((data, id) => {
                      let wow = new Date(data.startTime);
                      let meetingDate = wow.toDateString().split(' ');

                      let day = meetingDate[0];
                      let date = meetingDate[2];
                      let month = meetingDate[1];
                      let year = meetingDate[3];

                      return (
                        <Card className={classes.eventsCard}>
                          <CardHeader
                            className={classes.cardHeader}
                            avatar={
                              <Typography variant="h4" color="textSecondary">
                                {date}
                              </Typography>
                            }
                            action={
                              // <Paper className={classes.eventNum}>{id}</Paper>
                              <IconButton
                                aria-label="eventNum"
                                className={classes.eventNum}
                              >
                                {id}
                              </IconButton>
                            }
                            title={
                              <Typography variant="body2" color="textSecondary">
                                {month} {year}
                              </Typography>
                            }
                            subheader={day}
                          />
                          <CardContent className={classes.cardContent}>
                            <Typography
                              variant="body2"
                              className={classes.eventText}
                            >
                              <div className={classes.yellowDots}> </div>
                              {data.description}
                            </Typography>
                          </CardContent>
                        </Card>
                      );
                    })}
                </Grid>
              </Panel>
              <Panel title="Notifications">
                <Grid container>
                  {notification.map((data, id) => {
                    return (
                      <Paper className="notificationCls" key={id}>
                        <div style={{ margin: '20px' }}>
                          <p>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              style={{
                                width: '10px',
                                height: '10px',
                                marginRight: 10,
                              }}
                            >
                              <circle cx="5" cy="5" r="5" fill="#FBAF62" />
                            </svg>
                            {data.notify}
                          </p>
                        </div>
                      </Paper>
                    );
                  })}
                </Grid>
              </Panel>
            </Tabs>
          </Grid>
        </Grid>
        <div className="mt-5 mr-4">
          <Grid container>
            <Grid item sm={12} md={6} lg={8}>
              <p className="headingCls">
                Curated Investment Recommendations based on your preferences
              </p>
            </Grid>
            <Grid item sm={12} md={6} lg={4}>
              <button className="btnCls" style={{ float: 'right' }}  onClick={()=>hist.push('/account')}>
                Edit Investment preferences
              </button>
            </Grid>
            <hr />
            <CuratedInvestment />

            {/* <PropertyListings /> */}
            {/* <button className="btnCls" style={{ float: 'right' }}>
              Show all
            </button> */}
              
          </Grid>
        </div>
        {/* <Chat openChat={toggle} toggleChat={toggleFloat} labelTerm={botterm?botterm : 'Show the best investment opportunity for me'}   /> */}
      </Container>
      <Footer />
    </>
  );
};

export default HomePage;
