import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import clsx from 'clsx';
import { InputAdornment, IconButton } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Alert from '@material-ui/lab/Alert';
import { GoogleLogin } from 'react-google-login';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import styled from 'styled-components';
import Google from './../../assets/icons/google.svg';
import signInBackground from './../../assets/icons/niv-rozenberg-ySNtJhDX-cw-unsplash.png';
import { googleLogin, login } from './../../store/user/user.actions';
import loginlogo from '../../project_assets/login.svg'
import {getBaseUrl} from '../../utils/request'
const useStyles = makeStyles((theme) => ({
  parentContainer: {
    background: `#fff`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    minHeight: '100vh',
    marginTop: '5vh',
    // minHeight: '100vh',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // display: 'grid',
    // placeItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  signinContainer: {
    margin: theme.spacing(1, 'auto', 1),

    // marginTop: '80px',

    padding: theme.spacing(2, 4),
    // display: 'flex',
    // flexDirection: 'column',
    background: 'white',
    maxWidth: '440px',
    borderRadius: '5px',
  },
  signInResponsive: {
    maxWidth: '90vw',
  },
  paper: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
    textTransform: 'none',
    height: '40px',
    boxShadow: 'none',
  },
  submitButton: {
    margin: theme.spacing(2, 0, 2),
    textTransform: 'none',
    border: '1px solid #FECF0E',
    '&:hover': {
      background: theme.common.yellowOrange,
      color: 'white',
    },
  },
  termsConditionsLink: {
    textDecoration: 'none',
    color: '#4285F4ff',
    '&:hover': {
      color: '#4285F4ff',
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  border: {
    borderBottom: '2px solid lightgray',
    width: '100%',
  },
  content: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    fontWeight: 500,
    fontSize: 16,
    color: 'gray',
    width: '25%',
  },
  googleButton: {
    flex: 1,
    textTransform: 'none',
    alignSelf: 'center',
  },

  navlink: {
    textDecoration: 'none',
    color: '#1f1f1f',
    border: 'none',
    '&:active': {
      color: '#1f1f1f',
    },
  },
  label: {
    color: '#1F1F1F',
  },
  inputBorder: {
    '& label.Mui-focused': {
      color: theme.common.lighterBlue,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.common.lighterBlue,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '5px',
      '& fieldset': {
        borderColor: theme.common.lighterBlue,
      },
      '&:hover fieldset': {
        borderColor: theme.common.lighterBlue,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.common.lighterBlue,
      },
    },
  },
}));

export default function SignIn(props) {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const { userInfo, error: serverError } = userLogin;
  const responseGoogle = (response) => {
    const { profileObj, tokenObj } = response;
    if (tokenObj) {
      const { id_token } = tokenObj;
      dispatch(googleLogin(id_token));
    }
  };

  const [loginoption, setLoginoption] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  useEffect(() => {
    let mounted = true;
    if (loginoption === 'google') {
    }

    return () => {
      mounted = false;
    };
  }, [loginoption]);
  useEffect(() => {
    if (serverError) {
      if (serverError.length !== 0) {
        toast.error(serverError, {
          position: 'bottom-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          transition: Zoom,
        });
      }
    }
  }, [serverError]);
  useEffect(() => {
    if (userInfo) {
      props.history.push('/');
    }
  }, [dispatch, userInfo, props.history]);

  const id='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVlMTQ5NTg1NDQ5YjQ4MjQwMTJmNzJlMyIsImVtYWlsIjoiYnV5ZXJAcmVhbHRvLmVzdGF0ZSIsImZpcnN0bmFtZSI6ImJ1eWVyIiwibGFzdG5hbWUiOiJzYW1wbGUiLCJyb2xlIjpbImJ1eWVyIl0sImlhdCI6MTYyNDU0NDY5OCwiZXhwIjoxNjI0NjMxMDk4fQ.YDWiFLFWo_ywCuysykxY7OODiCbIOwJhGgxeGI81CFA'
  const config = {
    headers: {
      Authorization: 'Bearer ' + id,
      'Content-Type': 'application/json',
    },
    query: {
      page: 1,
      per_page: 10,
    },
  };
  useEffect(() => {

    fetch(getBaseUrl('tenants/current'),config).then((res)=>res.json().then((data)=>console.log(data))).catch((err)=>console.log(err))
  },[])
  

  const InfoContainer = styled.div`
  width: 50%;
  height: 100vh;
  background: #0f1723;
  display: flex;
  align-items: center;
  position: relative;
  justify-content:center;
  flex-direction: column;
  
  
  @media screen and (max-width:768px){
    display: none;
  }
  `

  return (
    <div className={classes.parentContainer}>
      <InfoContainer>
      <img src={loginlogo} style={{height:"100%", width:"100%", position:"relative"}}/>

      </InfoContainer>
      <Container
        component="main"
        className={clsx(
          classes.signinContainer,
          matches ? classes.signInResponsive : '',
        )}
      >
        <div className={classes.paper}>
          {/* <Typography component="h1" variant="h4" gutterBottom>
            Login
          </Typography> */}
          <ToastContainer
            position="bottom-center"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            transition={Zoom}
          />
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
              password: Yup.string().max(255).required('Password is required'),
            })}
            onSubmit={(data) => {
              dispatch(login(data));
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit} className={classes.form}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    sm={12}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <InputLabel
                      className={classes.label}
                      htmlFor="email"
                      shrink
                      variant="outlined"
                    >
                      Email *
                    </InputLabel>
                    <TextField
                      size="small"
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      className={classes.inputBorder}
                      helperText={touched.email && errors.email}
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <InputLabel
                      className={classes.label}
                      htmlFor="password"
                      shrink
                      variant="outlined"
                    >
                      Password *
                    </InputLabel>
                    <TextField
                      size="small"
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      className={classes.inputBorder}
                      helperText={touched.password && errors.password}
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      variant="outlined"
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        // <-- This is where the toggle button is added.
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              disableRipple
                              size="small"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="outlined"
                  className={classes.submitButton}
                  disableRipple
                  disabled={isSubmitting && !serverError}
                >
                  Sign In
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link to="#" className={classes.navlink} variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      to="/signup"
                      className={classes.navlink}
                      variant="body2"
                    >
                      New here?{' '}
                      <span className={classes.termsConditionsLink}>
                        Sign Up
                      </span>
                    </Link>
                  </Grid>
                </Grid>

                <div className={classes.container}>
                  <div className={classes.border} />
                  <span className={classes.content}>Or</span>
                  <div className={classes.border} />
                </div>

                <GoogleLogin
                  clientId="744106296275-1eee3ah5o209650jdukl8uogjehegod8.apps.googleusercontent.com"
                  render={(renderProps) => (
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      style={{
                        background: '#4285F4ff',
                        padding: '.1rem .2rem',
                      }}
                      onClick={renderProps.onClick}
                      disabled={
                        renderProps.disabled || (isSubmitting && !serverError)
                      }
                      // disabled={isSubmitting && !serverError}
                    >
                      <Grid container>
                        <Avatar
                          variant="square"
                          style={{
                            background: 'white',
                            borderRadius: '.2rem',
                            padding: '.2rem',
                            width: '30px',
                            height: '30px',
                          }}
                          src={Google}
                        ></Avatar>

                        <Grid item className={classes.googleButton}>
                          Continue With Google
                        </Grid>
                      </Grid>
                    </Button>
                  )}
                  buttonText="Login"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy={'single_host_origin'}
                />
              </form>
            )}
          </Formik>
        </div>
      </Container>
    </div>
  );
}
