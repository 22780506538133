import React from 'react';
import Select from 'react-select';
import {
    Container,
    Grid,
    makeStyles,
    Paper
  } from '@material-ui/core';
import './../dashboard.css';

const customStyles = {
    option: (provided, state) => ({
      ...provided,
      width: 150
    }),
    control: (provided, state) => ({
        ...provided,
      border: '1px solid #FBAF62',
      width: 150,
      marginBottom: 20
    }),
    menu: (provided, state) => ({
        ...provided,
      border: '1px solid #FBAF62',
      width: 150
    })
}

export default class CustomSelectDropDown extends React.Component {
  state = {
    selectedOption: null,
    options: [],
    toggleCustomOptions: false
  };

  componentDidMount(){
      if(this.props.dataDetails){
          console.log("this.props", this.props)
        this.setState({
            options: this.props.dataDetails,
            selectedOption: this.props.dataDetails[0].label
        })
      }
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption });
    console.log(`Option selected:`, selectedOption);
    if(selectedOption.value === "Custom Range"){
        this.setState({
            toggleCustomOptions: true
        })
    }else{
        this.setState({
            toggleCustomOptions: false
        }) 
    }
  };


  render() {
    const { selectedOption, options, toggleCustomOptions } = this.state;

    return (
        <>
      <Select
        styles={customStyles}
        value={selectedOption}
        onChange={this.handleChange}
        options={options}
      />
      {(toggleCustomOptions) ?
      <div className="customDropDownDate">
          <p>From</p>
          <Grid container spacing={4} className="text-center">
              <Grid item xs={12} sm={2} md={2} lg={3}>
                <label>Day</label>
                <input type="number" placeholder="-" min="1" max="31" className="dateInputText" />
              </Grid>
              <Grid item xs={12} sm={2} md={2} lg={3}>
                <label>Month</label>
                <input type="number" placeholder="-" min="1" max="31" className="dateInputText" />
              </Grid>
              <Grid item xs={12} sm={2} md={2} lg={6}>
                <label>Year</label>
                <input type="number" placeholder="-" min="1" max="10000" className="dateInputText" />
              </Grid>
          </Grid>
          <p className="mt-4">To</p>
          <Grid container spacing={4} className="text-center">
              <Grid item xs={12} sm={2} md={2} lg={3}>
                <label>Day</label>
                <input type="number" placeholder="-" min="1" max="31" className="dateInputText" />
              </Grid>
              <Grid item xs={12} sm={2} md={2} lg={3}>
                <label>Month</label>
                <input type="number" placeholder="-" min="1" max="31" className="dateInputText" />
              </Grid>
              <Grid item xs={12} sm={2} md={2} lg={6}>
                <label>Year</label>
                <input type="number" placeholder="-" min="1" max="10000" className="dateInputText" />
              </Grid>
          </Grid>
          <button className="btnYellowBorder pl-3 pr-3 float-right mt-4" onClick={() => this.setState({toggleCustomOptions: false})}>Apply</button>
      </div>: null }
      </>
    );
  }
}