import React, {useEffect, useState} from 'react';
import {
    Container,
    Grid,
    makeStyles,
    Paper
} from '@material-ui/core';
import Investment from './Preference/Investment/Investment';
import Other from './Preference/Other/Other';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { getBaseUrl } from '../../utils/request';


const Tabs = (props) => {
    const [selected, setSelected] = useState(props.selected || 0)
    
    
    const handleChange = (index) => {
      setSelected(index);
    }
  
      return (
      <div>
        <ul className="inline">
          {props.children.map((elem,index)=>{
            let style = index == selected ? 'selected': '';
           return <li className={style} key={index} onClick={() => handleChange(index)}>{elem.props.title}</li>
          })}
        </ul>
        <div className="tab p-0">{props.children[selected]}</div>
      </div>
      )
  }
  
  const Panel = (props) => {
      return <div>{props.children}</div>
  }
  

const MyPreferences = () => {

    const [investPreference, setInvestPreference] = useState(false);
    const [otherPreference, setOtherPreference] = useState(false);
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    const dispatch = useDispatch();

    const processPreference = (data) => {
        if(data === "Investment"){
            setInvestPreference(true);
            setOtherPreference(false);
        }else if(data === "Other"){
            setOtherPreference(true);
            setInvestPreference(false);
        }
    }

    useEffect(() => {
        const config = {
            headers: {
            'Authorization': 'Bearer ' + userInfo.accessToken,
            'Content-Type': 'application/json',
            },
        }
        axios.get(getBaseUrl('preferences'), config)
        .then(res => {
            console.log("res", res);
        })
    }, [])

    return(
        <div>
            <Paper className="p-5">
                <h5>My Preferences</h5>
                <Tabs selected={0}>
                    <Panel title="Investment Preferences">
                    {(!investPreference) ?
                    <>
                    <p>Tell us a bit about your investment preferences and we’ll bring you contents that best fits your investment goals.</p>
                    <button className="btnYellowBorder pl-5 pr-5" onClick={() => processPreference("Investment")}>Begin</button>
                    </>: <label></label>  }
                    </Panel>
                    <Panel title="Other preferences">
                    {(!otherPreference) ?
                    <>
                    <p>Tell us a bit about your investment preferences and we’ll bring you contents that best fits your investment goals.</p>
                    <button className="btnYellowBorder pl-5 pr-5" onClick={() => processPreference("Other")}>Begin</button>
                    </>: <label></label>  }</Panel>
                </Tabs>
                {(investPreference) && <Investment />}
                {(otherPreference) && <Other />}
            </Paper>
            
        </div>
    )
}

export default MyPreferences;
