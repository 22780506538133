import React from 'react';
import logo from './../../assets/icons/logo.png';

const Footer = () => {
    return(
        <div className="footerCls">
            <img src={logo} alt="logo" width="130px" />
            <p>5899 Preston Road,</p>
            <p>Suite # 403</p>
            <p>Frisco, TX 75034, USA.</p>
            <p className="mb-0 text-right">Copyright © 2002 - 2018 Realto. All rights reserved.</p>
        </div>
    )
}

export default Footer;