import React, { useState, memo } from 'react';
import { MinInvestmentType } from './../../../services/Tags';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
  checkBoxContainer: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(4),
    borderRadius: '5px',
    background: '#FDC424',
    border: '1px solid #FDC424',

    // border: '1px solid #C5CACE',
  },
  checkBoxContainerChecked: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(4),
    borderRadius: '5px',
    border: '1px solid #C5CACE',
  },

  checkBox: {
    width: theme.spacing(2.2),
    height: theme.spacing(2.2),
    // color: 'white',
    color: '#F8F9FC',
    // fill: 'white',
    '&$checked': {
      background: 'orange',
      color: 'orange',
      fill: 'white',
      color: '#F8F9FC',
    },
  },
  uncheckBox: {
    width: theme.spacing(2.2),
    height: theme.spacing(2.2),
    // this totally depends on the background color, gotta use white just in case if we change.
    color: '#F8F9FC',
  },
}));

export const MinimumInvestMentFilter = memo((props) => {
  const classes = useStyles();

  const [minimumInvestmentTypes, setMinimumInvestmentTypes] = useState([]);
  const handleSelectAll = (event) => {
    const selectedOrders = event.target.checked
      ? MinInvestmentType.map((property) => property.investment)
      : [];

    setMinimumInvestmentTypes(selectedOrders);
  };

  const handleMinimumInvestMentTypeFilters = (event, investment) => {
    const selectedIndex = minimumInvestmentTypes.indexOf(investment);
    let newSelectedOrders = [];

    if (selectedIndex === -1) {
      newSelectedOrders = newSelectedOrders.concat(
        minimumInvestmentTypes,
        investment,
      );
    } else if (selectedIndex === 0) {
      newSelectedOrders = newSelectedOrders.concat(
        minimumInvestmentTypes.slice(1),
      );
    } else if (selectedIndex === minimumInvestmentTypes.length - 1) {
      newSelectedOrders = newSelectedOrders.concat(
        minimumInvestmentTypes.slice(0, -1),
      );
    } else if (selectedIndex > 0) {
      newSelectedOrders = newSelectedOrders.concat(
        minimumInvestmentTypes.slice(0, selectedIndex),
        minimumInvestmentTypes.slice(selectedIndex + 1),
      );
    }

    setMinimumInvestmentTypes(newSelectedOrders);
  };

  React.useEffect(() => {
    console.log('🐨');
    console.log(setMinimumInvestmentTypes);
    props.handleFilters(minimumInvestmentTypes);
  }, [minimumInvestmentTypes]);
  return (
    <>
      <FormControlLabel
        className={
          minimumInvestmentTypes.length === MinInvestmentType.length
            ? classes.checkBoxContainer
            : classes.checkBoxContainerChecked
        }
        control={
          <Checkbox
            checked={minimumInvestmentTypes.length === MinInvestmentType.length}
            disableRipple
            disableFocusRipple
            checkedIcon={
              <CheckCircleIcon size="small" className={classes.checkBox} />
            }
            icon={
              <CircleUnchecked size="small" className={classes.uncheckBox} />
            }
            onChange={handleSelectAll}
            name="All"
            value="All"
          />
        }
        label={<Typography variant="body1">All</Typography>}
      />
      {MinInvestmentType.slice(0).map((property) => (
        <FormControlLabel
          className={
            minimumInvestmentTypes.indexOf(property.investment) !== -1
              ? classes.checkBoxContainer
              : classes.checkBoxContainerChecked
          }
          key={property.id}
          control={
            <Checkbox
              onChange={(event) =>
                handleMinimumInvestMentTypeFilters(event, property.investment)
              }
              disableRipple
              disableFocusRipple
              checked={
                minimumInvestmentTypes.indexOf(property.investment) !== -1
              }
              checkedIcon={
                <CheckCircleIcon size="small" className={classes.checkBox} />
              }
              icon={
                <CircleUnchecked size="small" className={classes.uncheckBox} />
              }
              name={property.name}
              value={minimumInvestmentTypes.indexOf(property.investment) !== -1}
            />
          }
          label={<Typography variant="body1">{property.name}</Typography>}
        />
      ))}
    </>
  );
});
