import React, {useState, useEffect} from 'react';
import {
    Container,
    Grid,
    makeStyles,
    Paper
} from '@material-ui/core';
import Select from 'react-select';

const options = [{"value":"s","label":"Small"},{"value":"m","label":"Medium"},{"value":"l","label":"Large"}];


const useStyles = makeStyles((theme) => ({

}));

const propertyTypes = [
    {
        "name": "All",
        "statusData": 1
    },
    {
        "name": "Hospitality",
        "statusData": 0
    },
    {
        "name": "Industrial",
        "statusData": 0
    },
    {
        "name": "Land",
        "statusData": 0
    },
    {
        "name": "Medical Office",
        "statusData": 0
    },
    {
        "name": "Mixed Use",
        "statusData": 0
    },
    {
        "name": "Multi-Asset",
        "statusData": 0
    },
    {
        "name": "Residential",
        "statusData": 0
    },
    {
        "name": "Retail",
        "statusData": 0
    },
    {
        "name": "Senior Housing",
        "statusData": 0
    }
]


const PropertyType = (props) => {
    const classes = useStyles();
    const [isSearchable] = useState(true);
    const [propertyData, setPropertyData] = useState(propertyTypes);

    const selectedPropertyType = (id) => {
        var updatedTodos = propertyData.map((todo, index) => index === id ? {
            ...todo,
            statusData: (todo.statusData === 0) ? 1 :0
          } : todo);
        setPropertyData(updatedTodos);
    }

    useEffect(() => {
        if(props.propertyData.length > 0){
            var updatedTodos = props.propertyData.map((todo, index) => todo.statusData === 1 ? {
                ...todo
              } : todo);
              setPropertyData(updatedTodos);
        }
    }, [])

    return(
        <div>
            <h3 className="mt-4">Property types</h3>
            <p>Select preferred property types to invest in</p>
            
            <div style={{position: 'relative'}}>
            {(propertyData.map((data, id) => {
                if(data.statusData === 1){
                    return(
                        <label key={id} className="locationBorderSelectedBox" onClick={() => selectedPropertyType(id)}>{data.name}</label>
                    )
                }else{
                    return(
                        <label key={id} className="locationBorderBox" onClick={() => selectedPropertyType(id)}>{data.name}</label>
                    )
                }
            }))}
            </div>
            <Grid container spacing={4} className="mt-5">
                <Grid item xs={12} sm={12} md={2} lg={2}>
                    <button className="btnYellowBorder" style={{width: "100%", background: "#F5F5F5", border: "none"}} onClick={() => props.previousStep2()}>Back</button> 
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                    <button className="btnYellowBorder" style={{width: "100%"}} onClick={() => props.completeStep3(propertyData)}>Next</button>
                </Grid>
            </Grid>
            
        </div>
    )
}

export default PropertyType;

