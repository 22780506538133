import React, { useEffect, useState, memo } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import countries from './data.json';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1, 0),
    minWidth: 320,
    maxWidth: 300,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  multipleChecks: {
    border: '1px solid #DFE9F6',
    padding: '.2rem',
    borderRadius: '5px',
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      marginTop: '3rem',
      border: '1px solid #DFE9F6',
      borderRadius: '5px',
    },
  },
};

export const LocationsFilter = memo((props) => {
  const classes = useStyles();
  const [countryName, setCountryName] = React.useState([]);

  let updatedCountries = countries.map(({ name, isoCode }) => ({
    name,
    isoCode,
  }));

  const handleChange = (event) => {
    setCountryName([...event.target.value]);
    // const currentType = countryName.indexOf(event.target.value);
    // const newCurrentType = [...currentType];
    // if (currentType === -1) {
    //   newCurrentType.push(event.target.value);
    // } else {
    //   newCurrentType.splice(currentType, 1);
    // }
    // setCountryName(newCurrentType);
    props.handleFilters(countryName);
  };
  return (
    <FormControl className={classes.formControl}>
      <Select
        labelId="demo-mutiple-checkbox-label"
        id="demo-mutiple-checkbox"
        multiple
        value={countryName}
        onChange={handleChange}
        renderValue={(selected) => selected.join(', ')}
        input={<Input disableUnderline className={classes.multipleChecks} />}
        MenuProps={MenuProps}
      >
        {updatedCountries.map((country) => (
          <MenuItem
            disableRipple
            disableFocusRipple
            key={country.name}
            value={country.name}
          >
            <Checkbox checked={countryName.indexOf(country.name) > -1} />
            <ListItemText primary={country.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});
