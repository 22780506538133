import React, { useEffect, useState } from 'react';
import { Container, Grid, makeStyles, Paper } from '@material-ui/core';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
// todos image upload for upload document

import { Formik, Field } from 'formik';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {getBaseUrl} from '../../utils/request'
import * as yup from 'yup';
import UploadImage from '../Dashboard/components/UploadImage';

const TextInputComponent = ({ field, ...props }) => {
  console.log(field);
  console.log(props);
  const { errorMessage, touched } = props;
  const { name, value, onChange, onBlur } = field;
  return (
    <div>
      <TextField
        fullWidth
        name={name}
        error={touched && errorMessage ? true : false}
        label="Insert some text"
        helperText={touched && errorMessage ? errorMessage : undefined}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  );
};

const documentOptions = [
  { value: 'Passport', label: 'Passport' },
  { value: 'Driving License', label: 'Driving License' },
  { value: 'Photo ID', label: 'Photo ID' },
];

const useStyles = makeStyles((theme) => ({
  detailsText: {
    width: '100%',
    border: '1px solid #C5CACE',
    borderRadius: 10,
    padding: 16,
  },
}));

const KYCVerification = () => {
  const classes = useStyles();
  const [selectedDocumentOption, setSelectedDocumentOption] = useState(null);
  const [name, setName] = useState('');
  const [number, setNumber] = useState('');
  const [frontImage, setFrontImage] = useState('');
  const [backImage, setBackImage] = useState('');
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [getKyc, setKyc] = useState([]);
  const [verification, setVerification] = useState(null);
  console.log('userInfo', userInfo);

  const handleInputNameChange = (event) => {
    setName(event.target.value);
  };

  const handleInputNumberChange = (event) => {
    setNumber(event.target.value);
  };

  const handleDocumentChange = (selected) => {
    setSelectedDocumentOption(selected);
  };

  const handleFoto = (e) => {
    console.log('@@@', e.target.id);
    const config = {
      headers: {
        Authorization: 'Bearer ' + userInfo.accessToken,
      },
    };
    if (e) {
      var formData = new FormData();
      formData.append('file', e.target.files[0]);
      axios
        .post(
          getBaseUrl('image/upload'),
          formData,
          config,
        )
        .then((res) => {
          console.log('res', res);
          if (e.target.id === 'front-file') {
            setFrontImage(res.data.url);
          } else if (e.target.id === 'back-file') {
            setBackImage(res.data.url);
          }
        });
    }
  };

  const submitKyc = () => {
    let obj = {
      userId: userInfo.id,
      document: [
        {
          type: selectedDocumentOption.value,
          documentName: selectedDocumentOption.value,
          docNumber: number,
          name: name,
          frontImage: frontImage,
          backImage: backImage,
        },
      ],
    };
    console.log('obj', obj);
    const config = {
      headers: {
        Authorization: 'Bearer ' + userInfo.accessToken,
        'Content-Type': 'application/json',
      },
    };
    axios
      .post(getBaseUrl('users/kyc'), obj, config)
      .then((res) => {
        console.log('res', res);
      });
  };

  useEffect(() => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + userInfo.accessToken,
        'Content-Type': 'application/json',
      },
    };
    axios
      .get(getBaseUrl('users/kyc'), config)
      .then((res) => {
        console.log('res', res);
        setKyc(res.data.document);
        setVerification(res.data.document[0].verified);
      });
  }, []);

  // image upload

  const FILE_SIZE = 2000 * 1024;
  const SUPPORTED_FORMATS = [
    'image/jpg',
    'image/jpeg',
    'image/gif',
    'image/png',
    'application/pdf',
  ];
  const validationSchema = yup.object().shape({
    text: yup.string().required('A text is required'),
    front: yup
      .mixed()
      .required('A file is required')
      .test(
        'fileSize',
        'File too large',
        (value) => value && value.size <= FILE_SIZE,
      )
      .test(
        'fileFormat',
        'Unsupported Format',
        (value) => value && SUPPORTED_FORMATS.includes(value.type),
      ),
    back: yup
      .mixed()
      .required('A file is required')
      .test(
        'fileSize',
        'File too large',
        (value) => value && value.size <= FILE_SIZE,
      )
      .test(
        'fileFormat',
        'Unsupported Format',
        (value) => value && SUPPORTED_FORMATS.includes(value.type),
      ),
    file: yup
      .mixed()
      .required('A file is required')
      .test(
        'fileSize',
        'File too large',
        (value) => value && value.size <= FILE_SIZE,
      )
      .test(
        'fileFormat',
        'Unsupported Format',
        (value) => value && SUPPORTED_FORMATS.includes(value.type),
      ),
  });
  return (
    <div>
      <Paper className="p-5">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={6} lg={1}>
            <img
              src={
                verification
                  ? '/assets/icons/verified.svg'
                  : '/assets/icons/fail.svg'
              }
              alt=""
              width="50px"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={11}>
            <h5>{verification ? 'KYC Verified !' : 'Verification Failed'}</h5>
            <p>
              {verification
                ? 'You had been verified successfully !'
                : 'The documents you ahd submitted cannot be verified. Please re-upload any of the suggested docuemnts below'}
            </p>
          </Grid>
        </Grid>
      </Paper>
      <Paper className="p-5 mt-3">
        <h5>KYC Verification</h5>
        <Grid
          container
          spacing={2}
          display="flex"
          alignItems="center"
          justify="center"
        >
          <Grid item xs={12} sm={12}>
            <p className="mb-0">Document Type</p>
            <Select
              value={selectedDocumentOption}
              onChange={handleDocumentChange}
              options={documentOptions}
              placeholder="Select Document Type"
            />
            {selectedDocumentOption && (
              <>
                <p className="mb-0 mt-3">Name on Passport</p>
                <input
                  type="text"
                  placeholder="Type full name"
                  onChange={handleInputNameChange}
                  className={classes.detailsText}
                />
                <p className="mb-0 mt-3">Passport Number</p>
                <input
                  type="text"
                  placeholder="Type Passport Number"
                  onChange={handleInputNumberChange}
                  className={classes.detailsText}
                />
              </>
            )}
          </Grid>
          <Grid item>
            {selectedDocumentOption && (
              <>
                <Formik
                  initialValues={{
                    file: undefined,
                    front: undefined,
                    back: undefined,
                    selfie: undefined,
                    text: undefined,
                  }}
                  validationSchema={validationSchema}
                  validateOnBlur={true}
                  render={({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                  }) => {
                    console.log(errors);
                    return (
                      <form>
                        <Field
                          name="file"
                          component={UploadImage}
                          title="Front Image"
                          setFieldValue={setFieldValue}
                          errorMessage={
                            errors['file'] ? errors['file'] : undefined
                          }
                          touched={touched['file']}
                          style={{ display: 'flex' }}
                          onBlur={handleBlur}
                        />
                        <Field
                          name="front"
                          component={UploadImage}
                          title="Back Image"
                          setFieldValue={setFieldValue}
                          errorMessage={
                            errors['front'] ? errors['front'] : undefined
                          }
                          touched={touched['front']}
                          style={{ display: 'flex' }}
                          onBlur={handleBlur}
                        />
                        <Field
                          name="back"
                          component={UploadImage}
                          title="Selfie"
                          setFieldValue={setFieldValue}
                          errorMessage={
                            errors['back'] ? errors['back'] : undefined
                          }
                          touched={touched['back']}
                          style={{ display: 'flex' }}
                          onBlur={handleBlur}
                        />
                        <Field
                          name="text"
                          component={TextInputComponent}
                          errorMessage={errors['text']}
                          touched={touched['text']}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <pre>
                          {values.file
                            ? JSON.stringify(
                                {
                                  fileName: values.file.name,
                                  type: values.file.type,
                                  size: `${values.file.size} bytes`,
                                },
                                null,
                                2,
                              )
                            : null}
                        </pre>
                        <pre>{values.text ? values.text : null} </pre>
                      </form>
                    );
                  }}
                />
                {/* <Grid item xs={12} sm={12} md={6} lg={4}>
                <p className="mb-0">Upload Driving Passport Front</p>
                <div>
                  {frontImage ? (
                    <div className="imgBorderCls">
                      <img src={frontImage} alt="front-image" />
                    </div>
                  ) : (
                    <label className="custom-file-upload">
                      <img
                        src="/assets/icons/down.png"
                        className="pr-4 mt-3"
                        alt="down-arrow"
                        height="30px"
                      />
                      <input
                        type="file"
                        id="front-file"
                        onChange={handleFoto}
                      />
                      Drag and drop your document here
                      <br />
                      <label className="mt-4 mb-4">OR</label>
                      <br />
                      Click here to upload
                    </label>
                  )}
                </div>
                <p className="mb-0 mt-3">Upload Selfie</p>
                <div>
                  <label className="custom-file-upload">
                    <img
                      src="/assets/icons/down.png"
                      className="pr-4 mt-3"
                      alt="down-arrow"
                      height="30px"
                    />
                    <input type="file" />
                    Drag and drop your document here
                    <br />
                    <label className="mt-4 mb-4">OR</label>
                    <br />
                    Click here to upload
                  </label>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <p className="mb-0">Upload Driving Passport Back</p>
                <div>
                  {backImage ? (
                    <div className="imgBorderCls">
                      <img src={backImage} alt="front-image" />
                    </div>
                  ) : (
                    <label className="custom-file-upload">
                      <img
                        src="/assets/icons/down.png"
                        className="pr-4 mt-3"
                        alt="down-arrow"
                        height="30px"
                      />
                      <input type="file" id="back-file" onChange={handleFoto} />
                      Drag and drop your document here
                      <br />
                      <label className="mt-4 mb-4">OR</label>
                      <br />
                      Click here to upload
                    </label>
                  )}
                </div>
              </Grid> */}
              </>
            )}
          </Grid>
        </Grid>
        <button className="btnYellowBorder float-right" onClick={submitKyc}>
          Submit
        </button>
      </Paper>
    </div>
  );
};

export default KYCVerification;
