import React, { useState } from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Paper,
  Box,
  CardHeader,
  Avatar,
  Typography,
  Button,
  Hidden,
} from '@material-ui/core';

import SideBar from './../../components/SideBar';
import './dashboard.css';
import DashboardOverview from './DashboardOverview';
import DashboardInvestment from './DashboardInvestment';
import DashboardEarnings from './DashboardEarnings';
import DashboardDocVault from './DashboardDocVault';
import DashboardAuthUsers from './DashboardAuthUsers';
import Chat from '../../components/Chatbot/Chat'


function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    marginBottom: theme.spacing(7),
    

  
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const [getMenu, setMenu] = useState('overview');

  const getOptions = (data) => {
    console.log('data', data);
    setMenu(data);
  };

  return (
    <div style={{ background: '#F8F9FC' }}>
      <Container className={classes.container}>
        <Grid style={{ display: 'flex' }} container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={2}>
            <SideBar sendOption={getOptions} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={10} >
            {getMenu === 'overview' && <DashboardOverview setMenu={setMenu} />}
            {getMenu === 'investment' && <DashboardInvestment />}
            {getMenu === 'earnings' && <DashboardEarnings />}
            {getMenu === 'docVault' && <DashboardDocVault />}
            {getMenu === 'users' && <DashboardAuthUsers />}
          </Grid>
        </Grid>
        {/* <Chat  /> */}
      </Container>
    </div>
  );
};

export default Dashboard;
