import React, { useState,useEffect } from 'react';

import {
  Box,
  CardContent,
  Container,
  Grid,
  makeStyles,
  Paper,
  Card,
  CardHeader,
  Avatar,
  Typography,
  Button,
  Dialog,
  Icon,
} from '@material-ui/core';
import { ToastContainer, toast, Zoom } from 'react-toastify';

import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import {currConv} from '../../../../services/currency'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DialogContent from '@material-ui/core/DialogContent';
import { useTheme } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import {IconButton} from '@material-ui/core'
import {  withStyles } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import Select from 'react-select'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import TextField from '@material-ui/core/TextField';
import MeetingSlotsTimezones from '../../../../pages/Dashboard/components/InvestNow/MeetingSlotsTimezones';
import MeetingTimes from '../../../../pages/Dashboard/components/InvestNow/MeetingTimes';
import { useInvestNowSection } from '../../../../context/investnow-context';




import InvestNow from '../../../../pages/Dashboard/components/InvestNow/';

import CustomizedSlider from '../PropertyHighlights/Slider';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import amber from "@material-ui/core/colors/amber";
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ShareIcon from '@material-ui/icons/Share';

import PropertyHighlightsGallery from '../PropertyHighlights/PropertyHighlightsGallery';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { getBaseUrl } from '../../../../utils/request';

const themeTime = createMuiTheme({
  palette: {
    primary: amber,
  },
});

const useStyles = makeStyles((theme) => ({
  margin: {
    height: theme.spacing(2),
  },
  boxCls: {
    background: '#FFF9E8 0% 0% no-repeat padding-box',
    borderRadius: 10,
    padding: 20,
  },
  marginTop: {
    marginTop: theme.spacing(4),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop:10
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 250,
  },
  cover: {
    borderRadius: '5px',
    height: '60px',
    width: '60px',
    boxShadow: '4px 4px 10px rgba(0,0,0,.15)',
  },
  locationIcon: {
    fill: 'lightgrey',
  },
  propertySubheader: {
    display: 'flex',
    alignItems: 'center',
  },
  propertyStats: {
    background: '#FFF9E8',
    textAlign: 'center',
    borderRadius: '5px',
    padding: theme.spacing(1),
    margin: theme.spacing(0, 0.1),
    flexGrow: '0',
    maxWidth: '32.833333%',
    flexBasis: '32.633333%',
  },
  investmentStat: {
    background: '#FFF9E8',
  },
  investmentButton: {
    color: 'white',
    background: '#FDC424',
    padding: theme.spacing(1, 3),
    border: '1px solid #FECF0E',
    textTransform: 'none',
    borderRadius: '10px !important',
    '&:hover': {
      background: '#FDC424',
    },
  },
  wishlistArea: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    cursor: 'pointer',
  },

  assetStats: {
    border: '1px solid #FDC424',
    margin: theme.spacing(1, 0),
    borderRadius: '5px',
    padding: theme.spacing(2),
    flexGrow: '0',
    maxWidth: '49.533333%',
    flexBasis: '49.633333%',
  },
  minimumInvestmentStat: {
    background: '#FFF9E8',
    padding: theme.spacing(1, 2),
    borderRadius: '5px',
    margin: theme.spacing(1, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: '0',
    maxWidth: '49.533333%',
    flexBasis: '49.633333%',
  },
  imageIcon: {
    padding: theme.spacing(0, 0.4),
  },
  iconRoot: {
    textAlign: 'center',
  },
  expand: {
    fill: '#FDC424',
    cursor: 'pointer',
  },

}));

const platformData = [
  {
    value:'Zoho',
    label:<div ><img src="../../../../assets/icons/zoom.png" style={{height:50, width:120}}/></div>
  },
  {
    value:'Google Meet',
    label:<div ><img src="../../../../assets/icons/g-meet.png" style={{height:50, width:120}}/></div>
  },
  {
    value:'Phone',
  label:<div ><img src="../../../../assets/icons/phone.png" style={{height:50, width:120}}/></div>
  },

]

// { name: '09:00 AM ' },
// { name: '09:30 AM ' },
// { name: '10:00 AM ' },
// { name: '10:30 AM ' },
// { name: '11:00 AM ' },
// { name: '11:30 AM ' },
// { name: '12:00 PM ' },
// { name: '12:30 PM ' },
// { name: '01:00 PM ' },
// { name: '01:30 PM ' },
// { name: '02:00 PM ' },



const TimeData =[
  {
    value:'09:00 AM ',
    label:'09:00 AM '
  },

  {
    value:'09:30 AM ',
    label:'09:30 AM '
  },

  {
    value:'10:00 AM ',
    label:'10:00 AM '
  },

  {
    value:'10:30 AM ',
    label:'10:30 AM '
  },

  {
    value:'11:30 AM ',
    label:'11:30 AM '
  },

  {
    value:'12:00 PM ',
    label:'12:00 PM '
  },

  {
    value:'12:30 PM ',
    label:'12:30 PM '
  },

  {
    value:'01:00 PM ',
    label:'01:00 PM '
  },

  {
    value:'01:30 PM ',
    label:'01:30 PM '
  },

  {
    value:'02:00 PM ',
    label:'02:00 PM '
  },

]

const InvestmentOverview = ({
  roi,
  token,
  address,
  name,
  coverImage,
  images,
  id,
}) => {
  const classes = useStyles();
  if (!roi && !token && !address) {
    return <></>;
  }

  const { city, state, country } = address;
  const { IRR, averageCashYield, equityMultiple, minHoldPeriod } = roi;
  const { value, pricePerFraction, minimumInvestment } = token;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const userLogin = useSelector((state) => state.userLogin);
  // const chatbot = useSelector(state=>state.botterm)
  const { userInfo } = userLogin;
  console.log(userInfo)

  

  const styles = {
    border: "0.0625rem solid #9c9c9c",
    borderRadius: "0.25rem",
  };
  

  // watchlisted properties ,to display favourite icon.
  const [favourite, setFavourite] = useState([]);
  React.useEffect(() => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + userInfo.accessToken,
        'Content-Type': 'application/json',
      },
    };
    const getFavouriteProperties = async () => {
      const response = await axios.get(
        getBaseUrl('watchlist'),
        config,
      );

      setFavourite(response.data.propertyId);
    };
    getFavouriteProperties();
  }, []);


  async function createPdf() {
    const pdfDoc =  await PDFDocument.create()
  
  
    const page = await pdfDoc.addPage()
    const { width, height } = page.getSize()
    const fontSize = 30
    page.drawText('Creating PDFs in JavaScript is awesome!', {
      x: 50,
      y: height - 4 * fontSize,
      size: fontSize,
      color: rgb(0, 0.53, 0.71),
    })
  
    const pdfBytes = await pdfDoc.save()
  }
  const moveToWishList = async (id) => {
    console.log('id', id);
    const config = {
      headers: {
        Authorization: 'Bearer ' + userInfo.accessToken,
        'Content-Type': 'application/json',
      },
    };
    let res = await axios.get(
      getBaseUrl('watchlist'),
      config,
    );
    let watchlistedProperties = [...res.data.propertyId];
    let updatedProperties = [];
    let wasPresent = false;
    for (let i = 0; i < watchlistedProperties.length; i++) {
      if (watchlistedProperties[i] === id) {
        wasPresent = true;
        continue;
      } else {
        updatedProperties = [...updatedProperties, watchlistedProperties[i]];
      }
    }
    if (!wasPresent) {
      updatedProperties = [...updatedProperties, id];
    }

    // favourite dependencies to be updated
    let obj = {
      userId: userInfo.id,
      propertyId: updatedProperties,
    };

    const response = await axios.post(
      getBaseUrl('watchlist'),
      obj,
      config,
    );
    if (response && response.status === 201) {
      if (!wasPresent) {
        toast.success('Added to wishlist', {
          position: 'bottom-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          transition: Zoom,
        });
      } else {
        toast.success('Removed from wishlist', {
          position: 'bottom-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          transition: Zoom,
        });
      }
      setFavourite(updatedProperties);
    }
    console.log(`🐨🦁`);
    console.log(response);
    console.log('🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥');
  };
  console.log('🔥');
  console.log(id);
  const theme = useTheme();
  console.log('🥜 Let us make this work 😙');
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const handleClose =()=>{
    setOpen(false)
  }

  const [stepnext, setstepnext] = useState(false);
  const [finalStep, setfinalStep] = useState(false)
  const [submit, setsubmit] = useState(false);
  const [amount, setAmount] = useState([20000]);

  const canvas = React.createRef()

  const currencyya = currConv(3,'India');

  const [userCountry, setuserCountry] = useState('');
  
  useEffect(()=>{
  fetch(getBaseUrl('users'),{
    headers:{
      Authorization: 'Bearer ' + userInfo.accessToken,
      'Content-Type': 'application/json',
    }
  }).then((res)=>res.json()).then((data)=>setuserCountry(data.address.country)).catch((err)=>console.log(err));
  },[])
  



  const triggernext=()=>{
    
    console.log(selectedOption);
    console.log(id.id);
    console.log(amount[0])
    console.log(selectedDate.getDate()+"-"+(selectedDate.getMonth()+1)+"-"+selectedDate.getFullYear())
    fetch(getBaseUrl('meeting'),{
      method:"post",
      headers: {
        Authorization: 'Bearer ' + userInfo.accessToken,
        'Content-Type': 'application/json',
      },
      body:JSON.stringify({
        propertyId: id.id,
  mode: 'zoho',
  startTime:'2021-05-22',
  investment: 10000,
  endTimeExpected: '2021-05-22'
      })
    }).then((res)=>res.json()).then((data)=>console.log(data)).catch((err)=>console.log(err))
setstepnext(true)
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      width: 150,
    }),
    control: (provided, state) => ({
      ...provided,
      border: '1px solid #FBAF62',
      width: 200,
    }),
    menu: (provided, state) => ({
      ...provided,
      border: '1px solid #FBAF62',
      width: 150,
    }),
    
  };

  const customnewStyles = {
    option: (provided, state) => ({
      ...provided,
      width: 150,
    }),
    control: (provided, state) => ({
      ...provided,
      border: '1px solid #FBAF62',
      width: 250,
    }),
    menu: (provided, state) => ({
      ...provided,
      border: '1px solid #FBAF62',
      width: 150,
    }),
    
  };

  const handleChange = (event, value) => {
    setAmount(value);
    // setInvestmentData({
    //   ...investmentdata,
    //   amount: value[0],
    // });
  };

  function ValueLabelComponent(props) {
    const { children, open, value } = props;
  
    return (
  
   <Tooltip
        open={open}
        enterTouchDelay={0}
        placement="top"
        title={ <React.Fragment>
          <Typography color="inherit">Amount: $ {value}</Typography>
         
        </React.Fragment>}
        style={{ background: '#fff', color: '#000' }}
      >
        {children}
      </Tooltip>
     
     
    );
  }

  function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
    
}

const [newDate, setnewDate] = useState(new Date ())
const [selectedOption, setselectedOption] = useState('');
const [selectedStartOption, setselectedStartOption] = useState('');
const [selectedEndOption, setselectedEndOption] = useState('');


const handleShare =(id)=>{
  navigator.clipboard.writeText('http://localhost:3000/marketplaces/'+id);
  toast.success('Link successfully copied')
}


  
  const PrettoSlider = withStyles({
    root: {
      color: '#FDC424',
      height: 8,
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      marginTop: -8,
      marginLeft: -12,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 4px)',
    },
    track: {
      height: 8,
      borderRadius: 4,
    },
    rail: {
      height: 8,
      borderRadius: 4,
    },
    slider: {
      trackColor: "yellow",
      selectionColor: "red"
    }
  })(Slider);

  const [selectedDate, setSelectedDate] = React.useState(
    new Date('2021-03-18T21:11:54'),
  );
  


  const handleDate =(date)=>{
    console.log(date)
    setSelectedDate(date)
  }

  const handleTime =(time)=>{
    console.log(time)
  }


  const handleDateChange = (date) => {
    setSelectedDate(date);
    // setInvestmentData({ ...investmentdata, date: selectedDate });
  };

  function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
    
}
  
  const handlePlatform =(e)=>{
    setselectedOption(e)
  }

  return (
    <>
    <div style={{display:'flex', flexDirection: window.outerWidth>768?'row':'column'}}>

   
      <Grid item xs={12} md={12} lg={12}>
        <Card elevation={0}>
          <CardHeader
            avatar={
              <Avatar
                className={classes.cover}
                src={coverImage}
                alt=""
                variant="square"
              />
            }
            title={<Typography variant="h4" style={{padding:5}}>{name}</Typography>}
            subheader={
              <Typography variant="h6" className={classes.propertySubheader} style={{fontSize:18}}>
                <LocationOnIcon className={classes.locationIcon} />
                {city}, {state}, {country}
              </Typography>
            }
            // action={
            //   <Box
            //     m={4}
            //     display="flex"
            //     alignItems="center"
            //     justifyContent="space-evenly"
            //   >
            //     <Typography variant="body2">Enquire:</Typography>
            //     <MailOutlineIcon style={{ margin: '0 .2rem' }} />
            //     <PhoneIcon />
            //   </Box>
            // }
          />
          <CardContent>
            <Grid container alignItems="center" justify="space-between">
              <Grid item xs={6} className={classes.assetStats}>
                <Typography align="center" variant="body2">
                  Value of Asset
                </Typography>
                <Typography align="center" variant="h5" style={{fontWeight:"bold"}}>
                  {(currConv(value,userCountry))}
                </Typography>
              </Grid>
              <Grid item xs={6} className={classes.assetStats}>
                <Typography align="center" variant="body2">
                  Price per fraction
                </Typography>
                <Typography align="center" variant="h5" style={{fontWeight:"bold"}}>
                {(currConv(pricePerFraction,userCountry))}
                  
                </Typography>
              </Grid>
            </Grid>
            <Grid item container justify="space-between">
              <Grid item className={classes.propertyStats}>
              <Typography style={{fontSize:15}}>
                  Targeted
                  <br />
                  Investor IRR
                </Typography>
                <Typography variant="h6" style={{fontWeight:"bold"}}>{IRR}%</Typography>
              </Grid>
              <Grid item className={classes.propertyStats}>
                <Typography style={{fontSize:15}}>
                  Targeted
                  <br />
                  Equity Multiple
                </Typography>
                <Typography variant="h6" style={{fontWeight:"bold"}}>{equityMultiple}%</Typography>
              </Grid>
              <Grid item className={classes.propertyStats}>
              <Typography style={{fontSize:15}}>
                  Targeted
                  <br />
                  Avg Cash Yield
                </Typography>
                <Typography variant="h6" style={{fontWeight:"bold"}}>{averageCashYield}%</Typography>
              </Grid>
            </Grid>
            <Grid item container justify="space-between">
              <Grid item xs={6} className={classes.minimumInvestmentStat}>
                <Typography variant="body">Minimum Investment: </Typography>
                <Typography variant="subtitle2" style={{fontWeight:"bold"}}>
                {(currConv(minimumInvestment,userCountry))}
             
                </Typography>
              </Grid>
              <Grid item xs={6} className={classes.minimumInvestmentStat}>
                <Typography variant="body">Minimum Hold Period: </Typography>
                <Typography variant="subtitle2" style={{fontWeight:"bold"}}>{minHoldPeriod}</Typography>
              </Grid>
            </Grid>
            <ToastContainer
              position="bottom-center"
              autoClose={2000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              transition={Zoom}
            />
            <Grid container style={{display:"flex",position:"relative",padding:5, flexDirection:"column", flex:1}}>
              <Grid
                item
                container
                
                alignItems="center"
                justify="space-between"
               
                
              >
                <Button
                  className={classes.investmentButton}
                  disableRipple
                  variant="outlined"
                  size="large"
                  onClick={handleClickOpen}
                >
                  Invest Now
                </Button>
                <div
                  className={classes.wishlistArea}
                  onClick={() => moveToWishList(id.id)}
                >
                  {favourite && favourite.indexOf(id.id) !== -1 ? (
                    <FavoriteIcon
                      className={classes.expand}
                      // onClick={() => moveToWishList(id)}
                      htmlColor="#fff"
                    />
                  ) : (
                    <FavoriteBorderIcon
                      className={classes.expand}
                      // onClick={() => moveToWishList(id)}
                      htmlColor="#fff"
                    />
                  )}
                  Add to Wishlist
                </div>
                <div>
                  <Icon classes={{ root: classes.iconRoot }} onClick={()=>handleShare(id.id)}>
                    <img
                      className={classes.imageIcon}
                      src="/assets/icons/share.svg"
                    />
                  </Icon>
                  Share
                </div>
              </Grid>
            
              <Grid
                item
               
                container
                style={{margin:10}}
                alignItems="center"
                justify="space-between"
              >
                <Typography variant="body2" color="textSecondary">
                  *Only for KYC verified
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  *Only for accreditted investors
                </Typography>
              </Grid>
            </Grid>
            <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="Invest Here"
          maxWidth={'md'}
          
          fullWidth={true}
        >
          <DialogTitle>
            <div style={{width:"100%", display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-between"}}>
            <h2>Invest Now</h2>
            <IconButton onClick={handleClose} >
            <CloseIcon style={{fontSize:40}} />
            </IconButton>
         
            </div>
           
          </DialogTitle>
          <DialogContent style={{flex:1}}>
                 {/*Step 1*/}
            
                    
                 {/*Step 2*/}
                 {
                   stepnext?(
                  //   <div style={{width:"100%",backgroundColor:"red" ,height:300, display:"flex", alignItems:"center", justifyContent:"center"}}>
                  //   <span>Step 2</span>
                  //   <button onClick={()=>setstepnext(false)}>Click to 1</button>
                  // </div>
                  
                    // <div style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center"}}>
                    //   <img src="https://www.vhv.rs/dpng/d/356-3568543_check-icon-green-tick-hd-png-download.png" style={{width:100,height:80}}/>
                    //   <h3>
                    //   Congratulations !
                    //   Your meeting is confirmed
                    //   </h3>
                     
                    // </div>
                    <div>

<h5 style={{marginTop:10}}> Schedule your call</h5>
<div className={classes.boxCls}>




                      <div style={{display:"flex",marginTop:20 ,flexDirection: window.innerWidth<768?"column": "row", alignItems: window.innerWidth<768?"flex-start":"center", justifyContent: window.innerWidth<768?"flex-start": "space-between"}}>
                   <div>
                   <h6>Select Mode of Call</h6>
                    <Select
                    options={platformData}
                    styles={customStyles}
                    
                    value={selectedOption}
                      onChange={(e)=>{handlePlatform(e.value)}}
                   
                    isSearchable={false}
                    placeholder={selectedOption !==''? selectedOption:'Select Platform'}
                    />
                   </div>
                   
                   

                    {/* <KeyboardDatePicker
          margin="normal"
          id="date-picker-dialog"
          label="Select Date"
          format="dd/MM/yyyy"
          value={selectedDate}
          onChange={e=>handleDate(e)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        /> */}

        <div>
          
       
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={themeTime}>
        <h6 style={{marginBottom:-10}} >Select Date of Call</h6>
        <KeyboardDatePicker
          margin="normal"
          id="date-picker-dialog"
          label=""
          format="dd/MM/yyyy"
          value={selectedDate}
          onChange={e=>handleDate(e)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        {/* <KeyboardTimePicker
          margin="normal"
          id="time-picker"
          label="Select Time"
          value={selectedDate}
        
          onChange={e=>handleDate(e)}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
        /> */}

        </ThemeProvider>
        

                    </MuiPickersUtilsProvider>
                    </div>
                   
                    {/* <form className={classes.container} noValidate>
                    <TextField
                       id="datetime-local"
                      label="Please select date and time"
                      type="datetime-local"
                      defaultValue={newDate}
                      className={classes.textField}
                      //  onChange={()=>alert()}
                     InputLabelProps={{
                       shrink: true,
                       }}
                      />
                    </form> */}

                      </div>
                      <div style={{flexDirection:"row", marginTop:20 , display:"flex", alignItems:"center",justifyContent:"space-between"}}>
                      <h6>Select Time of Call</h6>
                      <div style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-evenly"}}> 
                      <h6 style={{ marginTop:10, marginRight:10}}>Time zone</h6>
                      <MeetingSlotsTimezones/>
                      </div>
                     
                      </div>
                      <div style={{flexDirection:"row", marginTop:20 , display:"flex", alignItems:"center",justifyContent:"space-between"}}>
                        <Select
                         options={TimeData}
                         styles={customStyles}
                         value={selectedStartOption}
                         onChange={(e)=>{setselectedStartOption(e.label)}}
                         placeholder={selectedStartOption?selectedStartOption:'Hour : Minute : AM/PM'}
                        />

                        <h6>to</h6>
                        <Select 
                         options={TimeData}
                         styles={customStyles}
                         value={selectedEndOption}
                         onChange={(e)=>{setselectedEndOption(e.label)}}
                         placeholder={selectedEndOption?selectedEndOption:'Hour : Minute : AM/PM'}
                        />

                      </div>
                      
                    
                      </div>
                      <div style={{marginTop:30, display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-between"}}>
                      <button
                        className=  "btnYellowBorder pl-4 pr-4 float-right mb-5 mt-4"
                        onClick={()=>setstepnext(false)}
                        style={{fontSize:16}}
                      >
                        Back
                      </button>
                     
                  <button
                        className=  "btnYellowBorder pl-4 pr-4 float-right mb-5 mt-4"
                        onClick={triggernext}
                        style={{backgroundColor:"#FDC420", fontSize:16}}
                      >
                        Confirm
                      </button>
                  </div>
                    </div>

                   ):(

                    <div >
                      <div style={{width:"100%", display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-between"}}>
                      <h5 >Confirm investment amount</h5>
                   <h5>$ {numberWithCommas(amount)}</h5>
                      </div>
                    
                    <div className={classes.boxCls}>
                      <div className="mb-5">
                        <label className="" style={{fontWeight:"bold"}}>Available Fractions 10</label>
                        <label className="float-right" style={{fontWeight:"bold"}}>Price Per Fraction $10,000</label>
                      </div>
                      
                     
                      <PrettoSlider
                        ValueLabelComponent={ValueLabelComponent}
                        valueLabelDisplay="on"
                        aria-label="pretto slider"
                        min={20000}
                        step={10000}
                       
                        max={100000}
                        defaultValue={amount}
                        value={amount}
                        onChange={handleChange}
                      />
                      
                      
                      <div className="mt-5">
                        <label className="" style={{fontWeight:"bold"}}>Min $20,000</label>
                        <label className="float-right" style={{fontWeight:"bold"}}>Available $100,000</label>
                      </div>
                    </div>
                    <div>
                  
                     
                    <button
                          className=  "btnYellowBorder pl-4 pr-4 float-right mb-5 mt-4"
                          onClick={triggernext}
                          style={{backgroundColor:"#FDC420", fontSize:16}}
                        >
                          Continue
                        </button>
                    </div>
                  </div>
                   )
                 }
               
                


          </DialogContent>
          {/* <DialogTitle id="responsive-dialog-title">{"Invest Now"}</DialogTitle> */}
       
        
        </Dialog>
            {/* <Dialog>
         
            </Dialog>
             */}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={12} lg={12} style={{ overflow: 'hidden', marginLeft:5}}>
        <PropertyHighlightsGallery images={images} />
      </Grid>
      </div>
    </>
  );
};

export default InvestmentOverview;
