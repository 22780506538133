import React from 'react';
import { makeStyles, Box, IconButton, Typography } from '@material-ui/core';

import SortIcon from '@material-ui/icons/Sort';
import NotificationsPopover from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
  },
  flexGrow: {
    flexGrow: 1,
  },
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit',
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit',
    },
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100,
  },
  searchPopperContent: {
    marginTop: theme.spacing(1),
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.white,
    backgroundColor: '#43aa8b',
    '&:hover': {
      backgroundColor: '#43aa8b',
    },
  },
  trialIcon: {
    marginRight: theme.spacing(1),
  },
  notificationsButton: {
    marginLeft: theme.spacing(1),
  },
  notificationsBadge: {
    backgroundColor: '#43aa8b',
  },
  logoutButton: {
    marginLeft: theme.spacing(1),
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
  },
}));

function CustomFilterDropdown() {
  const classes = useStyles();
  const notificationsRef = React.useRef(null);
  const [openNotifications, setOpenNotifications] = React.useState(false);

  const [notifications, setNotifications] = React.useState([
    {
      id: 'appkalsdjfldf',
      title: 'Recently Listed',
      type: 'recent',
    },
    // {
    //   id: 'saekjaklsdfsdf',
    //   title: 'Closing Soon',
    //   type: 'closing',
    // },
    {
      id: 'wewejesdff',
      title: 'IRR (High - Low)',
      type: 'irr',
    },
    {
      id: 'ljkjxiojewkljsd',
      title: 'Avg. Cash Yield (High - Low)',
      type: 'averagecash',
    },
    {
      id: 'ljkjxiojewksdddljsd',
      title: 'Min. Investment (Low - High)',
      type: 'minimuminvestment',
    },
    {
      id: 'ljkjxiojewsddfekljsd',
      title: 'Min. Hold Period (Low - High)',
      type: 'minimumholdperiod',
    },
  ]);
  const handleNotificationsOpen = () => {
    setOpenNotifications(true);
  };

  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };

  return (
    <Box m={1} p={1} display="flex" alignItems="center">
      <Typography variant="subtitle2" style={{fontSize: 17}}>Sort By</Typography>
      <IconButton
        className={classes.notificationsButton}
        disableFocusRipple
        disableRipple
        color="inherit"
        onClick={handleNotificationsOpen}
        ref={notificationsRef}
      >
        <SortIcon />
      </IconButton>
      <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications}
        onClose={handleNotificationsClose}
        open={openNotifications}
      />
    </Box>
  );
}

export default CustomFilterDropdown;
