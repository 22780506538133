import React from 'react';
import { useInvestNowSection } from '../../../../context/investnow-context';

function FinalStepDetails() {
  const [value] = useInvestNowSection();
  const { date } = value;

  return (
    <div className="text-center">
      <h3 className="mt-5">Call Confirmed!</h3>
      <img src="/assets/icons/tick.png" alt="tick" />
      <p className="mt-3">You will be contacted by our investment advisor on</p>
      <h4>{date.toString()}</h4>
    </div>
  );
}

export default FinalStepDetails;
