import React from 'react';
import {
  Typography,
  makeStyles,
  Button,
  Box,
  Paper,
  Container,
  Grid,
} from '@material-ui/core';

import FinancialOverviewTable from './FinancialOverviewTable';

const useStyles = makeStyles((theme) => ({}));

const FinancialOverview = () => {
  const classes = useStyles();
  return (
    <Paper elevation={0}>
      <Box p={6}>
        <Typography variant="h4" gutterBottom>
          Financial Overview
        </Typography>
        <Typography variant="body1">
          On October 18, 2020 we entered into an agreement to acquire 46 Waller
          Street for $1,800,000. We have estimated a total capitalization for
          the acquisition to be $1,950,000 which includes closing costs
          associated with the purchase of the property, offering costs, (for
          this offering and potentially other equity offerings), fees paid to
          Republic, and operating and capital reserves. In the event that we
          have overestimated the total capital required, we intend to distribute
          any excess capital to investors. The closing of the acquisition is
          expected to occur prior to April 14, 2021.
        </Typography>
        <Box m={4} />
        <Typography variant="body2">
          We intend to make semi-annual dividend payments based on cash
          available.
        </Typography>
        <Box m={2} />
        <FinancialOverviewTable />
      </Box>
    </Paper>
  );
};

export default FinancialOverview;
