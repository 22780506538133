import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Paper,
  Button as ButtonUploading,
} from '@material-ui/core';
import SideBar from './../../components/SideBar';
import './dashboard.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import MyDocuments from './components/RealDocs/MyDocuments';
import Agreements from './components/RealDocs/Agreements';
import Tax from './components/RealDocs/Tax';
import Rental from './components/RealDocs/Rental';
import MyUploads from './components/RealDocs/MyUploads';
import ModalDialog from './components/Dialog/index';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

// todos image upload for upload document

import { Formik, Field } from 'formik';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import UploadDocument from './components/UploadDocument';
import {getBaseUrl} from '../../utils/request'
import * as yup from 'yup';
import { Button } from 'react-scroll';

const useInStyles = makeStyles((theme) => ({
  inputField: {
    '& label.Mui-focused': {
      color: theme.common.lighterBlue,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.common.lighterBlue,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '5px',
      '& fieldset': {
        borderColor: theme.common.lighterBlue,
      },
      '&:hover fieldset': {
        borderColor: theme.common.lighterBlue,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.common.lighterBlue,
      },
    },
  },
}));

const TextInputComponent = ({ field, ...props }) => {
  console.log(field);
  console.log(props);
  const classes = useInStyles();
  const { errorMessage, touched } = props;
  const { name, value, onChange, onBlur } = field;
  return (
    <div>
      <TextField
        className={classes.inputField}
        fullWidth
        name={name}
        size="small"
        placeholder="Document Title"
        variant="outlined"
        error={touched && errorMessage ? true : false}
        helperText={touched && errorMessage ? errorMessage : undefined}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  );
};

const Tabs = (props) => {
  const [selected, setSelected] = useState(props.selected || 0);

  const handleChange = (index, elem) => {
    setSelected(index);
    props.selectedIndex(elem.props.title);
  };

  return (
    <div>
      <div className="line"></div>
      <ul className="inline">
        {props.children.map((elem, index) => {
          let style = index == selected ? 'selected' : '';
          return (
            <li
              className={style}
              key={index}
              onClick={() => handleChange(index, elem)}
            >
              {elem.props.title}
            </li>
          );
        })}
      </ul>
      <div className="tab p-0">{props.children[selected]}</div>
    </div>
  );
};

const Panel = (props) => {
  return <div>{props.children}</div>;
};

function createData(data1, data2, data3, data4, data5, data6, data7, data8) {
  return { data1, data2, data3, data4, data5, data6, data7, data8 };
}

const rows = [
  createData(
    '09 JAN 2020',
    'Prestige Sky High',
    '100',
    '$20,000',
    '$20,000',
    '$20,000',
    '$20,000',
    '$20,000',
  ),
  createData(
    '09 JAN 2020',
    'Shoba Sea View',
    '100',
    '$20,000',
    '$20,000',
    '$20,000',
    '$20,000',
    '$20,000',
  ),
];

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
  },
  margin: {
    height: theme.spacing(2),
  },
  marginTop: {
    marginTop: theme.spacing(4),
  },
  tilePaperCls: {
    width: '100%',
    padding: 20,
    marginBottom: 20,
  },
  gridBorder: {
    borderRight: '5px solid #F8F9FC',
  },
  chartPaper: {
    padding: 10,
  },
  docTitle: {
    border: '2px solid #C5CACE',
    padding: '10px 20px',
    borderRadius: 4,
    marginBottom: 10,
  },
  uploadButton: {
    borderRadius: '5px',
    textTransform: 'none',
    background: '#fecf0e',
    color: '#fff',
    border: '1px solid #fecf0e',
    '&:hover': {
      background: '#fecf0e',
      color: '#fff',
    },
  },
}));

const DashboardDocVault = () => {
  const classes = useStyles();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [toggleModal, setToggleModal] = useState(false);
  const [tableDetails, setTableDetails] = useState([]);
  const [getTab, setTab] = useState('All Documents');
  const downloadData = () => {
    setToggleModal(true);
  };
  const closeModalDownload = () => setToggleModal(false);

  const getTabDetails = (id) => {
    let setTabData = 'all';
    if (id === 'All Documents') {
      setTabData = 'all';
    } else if (id === 'Agreements') {
      setTabData = 'agreement';
    } else if (id === 'Tax') {
      setTabData = 'tax';
    } else if (id === 'Rental') {
      setTabData = 'rental';
    } else if (id === 'My Uploads') {
      setTabData = 'uploads';
    }
    const config = {
      headers: {
        Authorization: 'Bearer ' + userInfo.accessToken,
        'Content-Type': 'application/json',
      },
      query: {
        page: 1,
        per_page: 10,
      },
    };
    axios
      .get(
        getBaseUrl('contract/type='+setTabData),
        config,
      )
      .then((res) => {
        console.log('res', res);
        setTableDetails(res.data.contracts);
      });
  };

  const getSelectedTab = (id) => {
    console.log('id', id);
    getTabDetails(id);
  };

  useEffect(() => {
    getTabDetails('All Documents');
  }, []);

  // image upload

  const FILE_SIZE = 160 * 1024;
  const validationSchema = yup.object().shape({
    text: yup.string().required('A text is required'),
    file: yup
      .mixed()
      .required('A file is required')
      .test(
        'fileSize',
        'File too large',
        (value) => value && value.size <= FILE_SIZE,
      )
      .test(
        'fileFormat',
        'Unsupported Format',
        (value) => value && value.type === 'application/pdf',
      ),
  });

  return (
    <div>
      <Paper className={classes.tilePaperCls}>
        <Grid container className="mb-3">
          <Grid item xs={12} sm={12} md={6} lg={10}>
            <label className="dashboardTileCls pl-3">My Earnings</label>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={2}
            className="d-flex justify-content-end"
          >
            <div className="btnYellowIcon" onClick={downloadData}>
              <img
                src="/assets/icons/download.svg"
                alt="Download"
                width="18px"
              />
              <label className="downloadText">Upload Document</label>
            </div>
          </Grid>
        </Grid>

        <ModalDialog openDialog={toggleModal} closeModal={closeModalDownload}>
          {/* <h2>Upload Document</h2>
            <p>Document Title</p>
            <input type="text" placeholder="Type document title" className={classes.docTitle} />
            <p>Upload Document</p>
            <label className="custom-file-upload">
                <input type="file"/>
                Drag and drop your document here<br />
                <label className="mt-4 mb-4">OR</label><br />
                Click here to upload
            </label><br />
            <button className="btnYellowBorder pl-3 pr-3 float-right">Upload</button> */}
          <Box p={2} display="flex" alignItems="center">
            <Formik
              initialValues={{
                file: undefined,
                text: undefined,
              }}
              validationSchema={validationSchema}
              validateOnBlur={true}
              render={({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                setFieldValue,
              }) => {
                console.log(errors);
                return (
                  <form>
                    <Field
                      name="text"
                      component={TextInputComponent}
                      errorMessage={errors['text']}
                      touched={touched['text']}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Field
                      name="file"
                      component={UploadDocument}
                      title="Select a file"
                      setFieldValue={setFieldValue}
                      errorMessage={errors['file'] ? errors['file'] : undefined}
                      touched={touched['file']}
                      style={{ display: 'flex' }}
                      onBlur={handleBlur}
                    />
                    <Box display="flex" justifyContent="flex-end">
                      <ButtonUploading
                        variant="outlined"
                        className={classes.uploadButton}
                      >
                        Upload
                      </ButtonUploading>
                    </Box>
                  </form>
                );
              }}
            />
          </Box>
        </ModalDialog>

        <Tabs selected={0} selectedIndex={getSelectedTab}>
          <Panel title="All Documents">
            <MyDocuments tableDetails={tableDetails} />
          </Panel>
          <Panel title="Agreements">
            <MyDocuments tableDetails={tableDetails} />
          </Panel>
          <Panel title="Tax">
            <MyDocuments tableDetails={tableDetails} />
          </Panel>
          <Panel title="Rental">
            <MyDocuments tableDetails={tableDetails} />
          </Panel>
          <Panel title="My Uploads">
            <MyDocuments tableDetails={tableDetails} />
          </Panel>
        </Tabs>
      </Paper>
    </div>
  );
};

export default DashboardDocVault;
