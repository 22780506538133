import { combineReducers } from 'redux';
import {
  fetchPropertiesReducer,
  fetchCuratedPropertiesReducer,
  fetchPropertyDetailsReducer,
  sortPropertyReducer,
} from './properties/properties.reducer';
import { fetchCountriesReducer } from './countries/countries.reducer';
import chatReducer from './chatbot/chatbot.reducer'

import {
  userGoogleLoginReducer,
  userLoginReducer,
  userRegisterReducer,
} from './user/user.reducer';

const reducer = combineReducers({
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  propertyListings: fetchPropertiesReducer,
  countriesList: fetchCountriesReducer,
  userGoogleLogin: userGoogleLoginReducer,
  propertyDetails: fetchPropertyDetailsReducer,
  sortedProperties: sortPropertyReducer,
  curatedPropertyListings: fetchCuratedPropertiesReducer,
  chatReducer: chatReducer
});
export default reducer;
