import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Paper,
  Box,
  CardHeader,
  Avatar,
  Typography,
  Button,
  Hidden,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ErrorBoundary } from 'react-error-boundary';
import SideBar from './../../components/SideBar';
import './dashboard.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {currConv} from '../../services/currency';
import Chat from '../../components/Chatbot/Chat'
import {getBaseUrl} from '../../utils/request';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Select from 'react-select'
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import SelectDropDown from './components/SelectDropDown';

import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';

const data = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
  { name: 'Group D', value: 200 },
];

const propertyData = [
  {
    value: 'Prestige Sky High',
    label: 'Prestige Sky High',
  },
  {
    value: 'Shoba Sea View',
    label: 'Shoba Sea View',
  },
];

const yearData = [
  { value: '3', label: '3 months' },
  { value: '6', label: '6 months' },
  { value: '9', label: '9 months' },
  { value: '12', label: '12 months' },
  
];

// filters table sotring methods

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// function createData(name, goals, age, average, points, birthdate) {
//   return { name, goals, age, average, points, birthdate };
// }
// function createData(name, calories, fat, carbs, protein) {
//   return { name, calories, fat, carbs, protein };
// }

function createData(propertyName, investments, distributions, assetClass) {
  return {
    propertyName,
    investments,
    distributions,
    assetClass,
  };
}

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}
const headCells = [
  {
    id: 'propertyName',
    numeric: false,
    disablePadding: true,
    label: 'Property Name',
  },
  {
    id: 'investments',
    numeric: true,
    disablePadding: false,
    label: 'Investment Amount',
  },
  {
    id: 'distributions',
    numeric: true,
    disablePadding: false,
    label: 'Yield',
  },
  {
    id: 'assetClass',
    numeric: false,
    disablePadding: false,
    label: 'Type of Property',
  },
];


const customStyles = {
  option: (provided, state) => ({
    ...provided,
    width: 150,
  }),
  control: (provided, state) => ({
    ...provided,
    border: '1px solid #FBAF62',
    width: 150,
  }),
  menu: (provided, state) => ({
    ...provided,
    border: '1px solid #FBAF62',
    width: 150,
  }),
  
};

const useStylesTableHead = makeStyles((theme) => ({
  tableHeaderCls: {
    background: '#FFF3E7 0% 0% no-repeat padding-box',
    borderRadius: 10,
    padding: '6px 10px',
  },
}));
function EnhancedTableHead(props) {
  const classes = useStylesTableHead();
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHeaderCls}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? 'right' : 'left'}

            align="center"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
  },
  margin: {
    height: theme.spacing(2),
  },
  marginTop: {
    marginTop: theme.spacing(4),
  },
  tilePaperCls: {
    width: '100%',
    padding: 20,
  },
  gridBorder: {
    borderRight: '5px solid #F8F9FC',
  },
  table: {
    minWidth: 650,
  },
  tableHeaderCls: {
    background: '#FFF3E7 0% 0% no-repeat padding-box',
    borderRadius: 10,
  },
  tableData: {
    padding: 25,
  },
  tableTotalCls: {
    background: '#FFF3E7 0% 0% no-repeat padding-box',
    borderRadius: 10,
    padding: '6px 10px',
  },
  chartPaper: {
    padding: 10,
  },
}));

const DashboardOverview = (props) => {
  const classes = useStyles();
  const [width, setWidth] = useState(650);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [dashboardData, setDashboardData] = useState([]);
  const [property, setproperty] = useState('')
  const [propertyTotal, setPropertyTotal] = useState(0);
  const [investmentTotal, setInvestmentTotal] = useState(0);
  const [distributionTotal, setDistributionTotal] = useState(0);
  const [currentValTotal, setCurrentValTotal] = useState(0);
  const [chartData, setChartData] = useState([ {distribution: 1583333.3333333333, name: "June"},
   {distribution: 395833.3333333333, name: "June"},
   {distribution: 15833.333333333332, name: "June"},
   {distribution: 1583.3333333333333, name: "June"},
   {distribution: 4583.333333333333, name: "June"},
   {distribution: 458333.3333333333, name: "June"},
   {distribution: 114583.33333333333, name: "June"},
   {distribution: 83.33333333333334, name: "June"},
   {distribution: 8333.333333333334, name: "June"},
   {distribution: 2083.3333333333335, name: "June"},
   {distribution: 8.333333333333334, name: "June"},
   
   {distribution: 458.3333333333333, name: "June"},
   {distribution: 12000, name: "June"},
 {distribution: 1200000, name: "June"}]);
  const [propertyNames, setPropertyNames] = useState([]);
  const [propertyNamesChart, setPropertyNamesChart] = useState([]);
  const [locationChart, setLocationChart] = useState([]);
  const [assetChart, setAssetChart] = useState([]);
  const [sellerChart, setSellerChart] = useState([]);
  const [propNameData, setpropNameData] = useState([])
  const [incomeDrop, setIncomeDrop] = useState('');
  const [colors, setColors] = useState([
    '#E0706F',
    '#FBAF62',
    '#06d6a0',
    '#00bbf9',
  ]);

  useEffect(() => {
    if (window.outerWidth < 576) {
      setWidth(window.outerWidth - 70);
    }
  }, [window.outerWidth]);


  const getIncomeChartData = (selectedOption, dashboardDetails) => {
    // setIcomeDrop(selectedOption.label);
    let getPropertyData = [];
    let getDashboardDetails = dashboardDetails
      ? dashboardDetails
      : dashboardData;
    if (getDashboardDetails.length > 0) {
      getPropertyData = getDashboardDetails.filter((data) => {
        if (data.propertyName[0] === selectedOption.label) {
          return data;
        }
      });
    }

    if (getPropertyData.length > 0) {
      let getChartData = getPropertyData[0].income.map((data, id) => {
        if (id < 10) {
          return {
            distribution: data.distribution,
            name: moment(data.time.fromDate).format('MMMM'),
          };
        }
      });
      let finalData = getChartData.filter(function (element) {
        return element !== undefined;
      });

      // let distributionData = getPropertyData[0]?.income[0].distribution;
      let distributionData = getPropertyData[0]?.income.map(
        (propertyStat) => propertyStat.distribution,
      );

      let timingData = getPropertyData[0]?.income.map(
        (timer) => timer.time.fromDate,
      );

      let chartsData = [];
      for (let i = 0; i < distributionData.length; i++) {
        let koala = {
          distribution: distributionData[i],
          name: moment(timingData[i].fromDate).format('MMMM'),
        };
        chartsData = [...chartsData, koala];
      }
      setChartData(chartsData);
     console.log(chartsData)
    }
  };

  const generateRandomColor = (count) => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const montres = [{distribution: 1583333.3333333333, name: "June"},
  {distribution: 23833.3333333333, name: "June"},
  {distribution: 19433.333333333332, name: "June"},
  {distribution: 483.3333333333333, name: "June"},
  {distribution: 4583.333333333333, name: "June"},
  {distribution: 48333.3333333333, name: "June"},
  {distribution: 1583.33333333333, name: "June"},
  {distribution: 83.33333333333334, name: "June"},
  {distribution: 8333.333333333334, name: "June"},
  {distribution: 2083.3333333333335, name: "June"},
  {distribution: 8.333333333333334, name: "June"},
  
  {distribution: 458.3333333333333, name: "June"},
  {distribution: 12000, name: "June"},
{distribution: 1200000, name: "June"}]

const monsix =[{distribution: 1583333.3333333333, name: "June"},
{distribution: 395833.3333333333, name: "June"},
{distribution: 32833.333333333332, name: "June"},
{distribution: 2583.3333333333333, name: "June"},
{distribution: 4583.333333333333, name: "June"},
{distribution: 414333.3333333333, name: "June"},
{distribution: 104583.33333333333, name: "June"},
{distribution: 83.33333333333334, name: "June"},
{distribution: 8333.333333333334, name: "June"},
{distribution: 1483.3333333333335, name: "June"},
{distribution: 8.333333333333334, name: "June"},

{distribution: 458.3333333333333, name: "June"},
{distribution: 12000, name: "June"},
{distribution: 1200000, name: "June"}]

const monine =[ {distribution: 1583333.3333333333, name: "June"},
 {distribution: 395833.3333333333, name: "June"},
{distribution: 15833.333333333332, name: "June"},
 {distribution: 1583.3333333333333, name: "June"},
 {distribution: 83.33333333333334, name: "June"},
 {distribution: 8333.333333333334, name: "June"},
 {distribution: 2083.3333333333335, name: "June"},
 {distribution: 8.333333333333334, name: "June"},
 {distribution: 4583.333333333333, name: "June"},
 {distribution: 458333.3333333333, name: "June"},
{distribution: 114583.33333333333, name: "June"},



 {distribution: 12000, name: "June"},
 {distribution: 1200000, name: "June"},
{distribution: 300000, name: "June"},
{distribution: 1200, name: "June"},
{distribution: 1583333.3333333333, name: "June"},
 {distribution: 395833.3333333333, name: "June"},
 {distribution: 15833.333333333332, name: "June"},
 {distribution: 1583.3333333333333, name: "June"},
{distribution: 83.33333333333334, name: "June"},
 {distribution: 8333.333333333334, name: "June"},
 {distribution: 2083.3333333333335, name: "June"},
 {distribution: 8.333333333333334, name: "June"},
 {distribution: 4583.333333333333, name: "June"},
 {distribution: 458333.3333333333, name: "June"},
 {distribution: 114583.33333333333, name: "June"},
{distribution: 458.3333333333333, name: "June"},
 {distribution: 12000, name: "June"},
 {distribution: 1200000, name: "June"},
 {distribution: 300000, name: "June"},
 {distribution: 1200, name: "June"},
 {distribution: 1583333.3333333333, name: "June"},
 {distribution: 395833.3333333333, name: "June"},
 {distribution: 15833.333333333332, name: "June"},
 {distribution: 1583.3333333333333, name: "June"},
 {distribution: 83.33333333333334, name: "June"},
 {distribution: 8333.333333333334, name: "June"},
 {distribution: 2083.3333333333335, name: "June"},
 {distribution: 8.333333333333334, name: "June"},
 {distribution: 4583.333333333333, name: "June"},
{distribution: 458333.3333333333, name: "June"},
 {distribution: 114583.33333333333, name: "June"},
 {distribution: 458.3333333333333, name: "June"},
 {distribution: 12000, name: "June"},
 {distribution: 1200000, name: "June"},
 {distribution: 300000, name: "June"},
{distribution: 1200, name: "June"}]









  useEffect(()=>{
    if(property==='3'){
setChartData(montres)
    }
    else if(property==='6')
    {
      setChartData(monsix)
    }
    else if(property==='9')
    {
      setChartData(monine)
    }
  },[property])

  const disDropper =[{
    value:'Celina Station',
    label:'Celina Station'
  },{
    value:'DLF Cybercity',
    label:'DLF Cybercity'
  },{
    value:'Celina Station Land',
    label:'Celina Station Land'
  },{
    value:'DLF Cybercity',
    label:'DLF Cybercity'
  }]
  const allNames =[{
    value:'All Properties',
    label:'All Properties'
  }]
  useEffect(() => {
    //"#F10531"
    const config = {
      headers: {
        Authorization: 'Bearer ' + userInfo.accessToken,
        'Content-Type': 'application/json',
      },
      query: {
        page: 1,
        per_page: 10,
      },
    };
    axios
      .get(getBaseUrl('investment/summary'), config)
      .then((res) => {
        console.log('res', res.data);
        setDashboardData(res.data);

        let propertyArr = res.data.map((data) => {
          return data.properties;
        });
        setPropertyTotal(
          propertyArr.reduce((partial_sum, a) => partial_sum + a, 0),
        );

        let investmentArr = res.data.map((data) => {
          return data.investments;
        });
        setInvestmentTotal(
          investmentArr.reduce((partial_sum, a) => partial_sum + a, 0),
        );

        let distributionArr = res.data.map((data) => {
          return data.distributions;
        });
        setDistributionTotal(
          distributionArr.reduce((partial_sum, a) => partial_sum + a, 0),
        );

        let currentValArr = res.data.map((data) => {
          return data.distributions;
        });
        setCurrentValTotal(
          currentValArr.reduce((partial_sum, a) => partial_sum + a, 0),
        );

   
          
        let getPropertyDetails = res.data.map(
          (property) =>allNames.push ({ value: property.propertyName[0],label:property.propertyName[0]}),
        );

        console.log(allNames)
        let unique = allNames.filter((item, i, ar) => ar.indexOf(item) === i);
        console.log(unique)
        let getNumber = getPropertyDetails.length;

        // console.log(res.data)
          // const disDown =()=>{
          //   res.data.map((i)=>{
          //     i.propertyName.map((j)=>{
          //       disDropper.push({value:j,label:j})
          //     })
          //   })
          //   console.log(disDropper)
          // }

          // disDown()
          // console.log(disDropper)

        let getPropertyDropDownData = getPropertyDetails.map((data, id) => {
          return {
            value: data,
            label: data,
            colorsData: colors[id],
          };
        });
        let getPropertyChartData = getPropertyDetails.map((data, id) => {
          return {
            name: data,
            value: 300,
            colorsData: colors[id],
          };
        });
        let getLocationChartData = res.data.map((item, id) => {
          return {
            name: item.location[0].city,
            value: item.distributions,
            colorsData: colors[id],
          };
        });
        let getAssetChartData = res.data.map((item, id) => {
          return {
            name: item.assetClass[0],
            value: item.investments,
            colorsData: colors[id],
          };
        });
        let getSellerChartData = res.data.map((item, id) => {
          return {
            name: item.seller[0].id,
            value: item.properties,
            colorsData: colors[id],
          };
        });

        {
          console.log('getPropertyChartData', getPropertyChartData);
        }

        getIncomeChartData(getPropertyDropDownData[0], res.data);
        setPropertyNames(getPropertyDropDownData);
        setPropertyNamesChart(getPropertyChartData);
        setLocationChart(getLocationChartData);
        setAssetChart(getAssetChartData);
        setSellerChart(getSellerChartData);
      });
  }, []);

  const onPieEnter = (e) => {
    console.log(e);
    if (e) {
      let toolTipWrapper = document.getElementsByClassName(
        'recharts-tooltip-wrapper',
      )[0];
      toolTipWrapper.style.transition = 'transform 400ms ease 0s';
      toolTipWrapper.style.transform =
        'translate(' + (e.chartX + 10) + 'px, ' + (e.chartY + 10) + 'px)';
    }
  };

  // Investment table
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('propertyName');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, dashboardData.length - page * rowsPerPage);

    function numberWithCommas(x) {
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      let temp=[];
      temp=parts[0].split(",");
      console.log(temp)
      let str = temp[0]+temp[1]+temp[2];
      
      
     

      
     
    if (str < 1e3) console.log (str);
  if (str >= 1e3 && str < 1e6) console.log ((str / 1e3).toFixed(1) + "K");
  if (str >= 1e6 && str < 1e9) console.log( (str / 1e6).toFixed(1) + "M");
  if (str >= 1e9 && str < 1e12) console.log ((str / 1e9).toFixed(1) + "B");
  if (str >= 1e12) console.log( (str / 1e12).toFixed(1) + "T");
    
  return str
     
     
  }

  console.log(numberWithCommas(123232445));

  


const [userCountry, setuserCountry] = useState('');
  
useEffect(()=>{
fetch(getBaseUrl('users'),{
  headers:{
    Authorization: 'Bearer ' + userInfo.accessToken,
    'Content-Type': 'application/json',
  }
}).then((res)=>res.json()).then((data)=>setuserCountry(data.address.country)).catch((err)=>console.log(err));
},[])


  return (
    <div>
      <Paper className={classes.tilePaperCls}>
        <Grid container style={{ textAlign: 'center' }}>
          <Grid item xs={6} sm={6} md={6} lg={3} className={classes.gridBorder}>
            <label className="dashboardTileCls">Properties</label>
            <br />
            <label className="dashboardTileValueCls">
              {propertyTotal.toFixed(2)}
            </label>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={3} className={classes.gridBorder}>
            <label className="dashboardTileCls">Total Invested </label>
            <br />
            <label className="dashboardTileValueCls">
              {(currConv(investmentTotal.toFixed(2),userCountry))}
            </label>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={3} className={classes.gridBorder}>
            <label className="dashboardTileCls">Current Value </label>
            <br />
            <label className="dashboardTileValueCls">
               {(currConv(currentValTotal.toFixed(2),userCountry))}
              
            </label>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={3}>
            <label className="dashboardTileCls">Total Distributions </label>
            <br />
            <label className="dashboardTileValueCls">
            {(currConv(distributionTotal.toFixed(2),userCountry))}
              {/* ${numberWithCommas(distributionTotal.toFixed(2))} */}
            </label>
          </Grid>
        </Grid>
      </Paper>
      <Grid container className="mt-1" spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={8}>
          <Paper className={classes.chartPaper}>
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onReset={() => {
                // reset the state of your app so the error doesn't happen again
                props.setMenu('overview');
              }}
            >
              <Grid container className="mb-4">
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <label className="dashboardTileCls pl-3" style={{fontWeight:"bolder"}}>
                    Income Distribution
                  </label>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={3} >
                  <SelectDropDown
                    value={allNames[0]}
                   placeHolder={'All Properties'}
                    dataDetails={allNames}
                    getIncome={getIncomeChartData}
                  />


               
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={3}>
                  {/* <SelectDropDown
                   
                    dataDetails={yearData}
                    getIncome={getIncomeChartData}
                  /> */}

<Select
                         options={yearData}
                         styles={customStyles}
                         value={property}
                         onChange={(e)=>{setproperty(e.value)}}
                         placeholder={property?property:'Quarters'}
                        />
                </Grid>
              </Grid>
              <PerfectScrollbar>
                <LineChart width={width} height={400} data={chartData}>
                  
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <XAxis dataKey="name" axisLine={false} />
                  <YAxis axisLine={false} />
                  <Line
                    type="monotone"
                    dataKey="distribution"
                    stroke="#FBAF62"
                    strokeWidth={4}
                  />
                </LineChart>
              </PerfectScrollbar>
            </ErrorBoundary>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Paper className="circlePaperCls">
            <label className="dashboardTileCls" style={{fontWeight:"bolder"}}>My portfolio</label>
            <PieChart
              width={300}
              height={300}
              style={{ position: 'relative', bottom: '70px', left: '30px' }}
              onMouseMove={onPieEnter}
            >
              <Tooltip />
              <Pie
                data={propertyNamesChart}
                cx={120}
                cy={200}
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={0}
                dataKey="value"
              >
                {/* <Cell key={` ${index}`} fill="#E0706F" /> */}
                {propertyNamesChart.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colors[index]} />
                ))}
              </Pie>
            </PieChart>
            <div className="circleLegend">
              {propertyNames.map((property, index) => {
                if (index < 4) {
                  return (
                    <p className="legendText" key={index}>
                      <span
                        className="circle"
                        style={{ color: colors[index] }}
                      ></span>

                      {property.label}
                    </p>
                  );
                }
              })}
            </div>
          </Paper>
        </Grid>
      </Grid>

      <Grid container className="mt-3" spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Paper className="circlePaperCls">
            <label className="dashboardTileCls" style={{fontWeight:"bolder"}}>By Location</label>
            <PieChart
              width={300}
              height={300}
              style={{ position: 'relative', bottom: '70px', left: '30px' }}
              onMouseMove={onPieEnter}
            >
              <Tooltip />
              <Pie
                data={locationChart}
                cx={120}
                cy={200}
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={0}
                dataKey="value"
              >
                {locationChart.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colors[index]} />
                ))}
              </Pie>
            </PieChart>
            <div className="circleLegend">
              {locationChart.map((property, index) => {
                if (index < 4) {
                  return (
                    <p className="legendText" key={index}>
                      <span
                        className="circle"
                        style={{ color: colors[index] }}
                      ></span>
                      {property.name}
                    </p>
                  );
                }
              })}
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Paper className="circlePaperCls">
            <label className="dashboardTileCls" style={{fontWeight:"bolder"}}>By Asset Class</label>
            <PieChart
              width={300}
              height={300}
              style={{ position: 'relative', bottom: '70px', left: '30px' }}
              onMouseMove={onPieEnter}
            >
              <Tooltip />
              <Pie
                data={assetChart}
                cx={120}
                cy={200}
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={0}
                dataKey="value"
              >
                {assetChart.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colors[index]} />
                ))}
              </Pie>
            </PieChart>
            <div className="circleLegend">
              {assetChart.map((property, index) => {
                if (index < 4) {
                  return (
                    <p className="legendText" key={index}>
                      <span
                        className="circle"
                        style={{ color: colors[index] }}
                      ></span>
                      {property.name}
                    </p>
                  );
                }
              })}
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Paper className="circlePaperCls">
            <label className="dashboardTileCls" style={{fontWeight:"bolder"}}>By Seller</label>
            <PieChart
              width={300}
              height={300}
              style={{ position: 'relative', bottom: '70px', left: '30px' }}
              onMouseMove={onPieEnter}
            >
              <Tooltip />
              <Pie
                data={sellerChart}
                cx={120}
                cy={200}
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={0}
                dataKey="value"
              >
                {sellerChart.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colors[index]} />
                ))}
              </Pie>
            </PieChart>
            <div className="circleLegend">
              {sellerChart.map((property, index) => {
                if (index < 4) {
                  return (
                    <p className="legendText" key={index}>
                      <span
                        className="circle"
                        style={{ color: colors[index] }}
                      ></span>
                      {property.name}
                    </p>
                  );
                }
              })}
            </div>
          </Paper>
        </Grid>
      </Grid>
      <p className="dashboardTileCls pt-3" style={{fontWeight:"bolder"}}>Current Portfolio Distribution</p>
      <Paper className="mt-4" style={{ boxShadow: 'none' }}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={dashboardData.length}
            />
            <TableBody>
              {stableSort(dashboardData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.propertyName);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                    >
                      <TableCell
                        component="th"
                        align="center"
                        id={labelId}
                        scope="row"
                      >
                        {row.propertyName}
                      </TableCell>
                      <TableCell align="center">{(currConv(row.investments,userCountry))}</TableCell>
                      <TableCell align="center">{(currConv(row.distributions,userCountry))}</TableCell>
                      <TableCell align="center">{(row.assetClass)}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={dashboardData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <Grid container className={classes.tableTotalCls}>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <p className="text-center">Total / Average</p>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <p>{currConv(investmentTotal,userCountry)}</p>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={2}>
          <p className="pl-5">{currConv(distributionTotal,userCountry)}</p>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={2}></Grid>
      </Grid>
      {/* <Chat /> */}
    </div>
  );
};

export default DashboardOverview;
