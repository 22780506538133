import React, { useState, memo } from 'react';
import { AverageCashYieldType } from './../../../services/Tags';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
  checkBoxContainer: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(4),
    borderRadius: '5px',
    background: '#FDC424',
    border: '1px solid #FDC424',
  },
  checkBoxContainerChecked: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(4),
    borderRadius: '5px',
    border: '1px solid #C5CACE',
  },

  checkBox: {
    width: theme.spacing(2.2),
    height: theme.spacing(2.2),
    color: '#F8F9FC',
    '&$checked': {
      background: 'orange',
      color: 'orange',
      fill: 'white',
      color: '#F8F9FC',
    },
  },
  uncheckBox: {
    width: theme.spacing(2.2),
    height: theme.spacing(2.2),
    // this totally depends on the background color, gotta use white just in case if we change.
    color: '#F8F9FC',
  },
}));

export const AverageCashYieldTypeFilter = memo((props) => {
  const classes = useStyles();

  const [AverageCashYieldTypes, setAverageCashYieldTypes] = useState([]);

  const handleSelectAll = (event) => {
    const selectedOrders = event.target.checked
      ? AverageCashYieldType.map((property) => property.id)
      : [];

    setAverageCashYieldTypes(selectedOrders);
  };

  const AverageCashYieldTypesFilters = (event, id) => {
    const selectedIndex = AverageCashYieldTypes.indexOf(id);
    let newSelectedOrders = [];

    if (selectedIndex === -1) {
      newSelectedOrders = newSelectedOrders.concat(AverageCashYieldTypes, id);
    } else if (selectedIndex === 0) {
      newSelectedOrders = newSelectedOrders.concat(
        AverageCashYieldTypes.slice(1),
      );
    } else if (selectedIndex === AverageCashYieldTypes.length - 1) {
      newSelectedOrders = newSelectedOrders.concat(
        AverageCashYieldTypes.slice(0, -1),
      );
    } else if (selectedIndex > 0) {
      newSelectedOrders = newSelectedOrders.concat(
        AverageCashYieldTypes.slice(0, selectedIndex),
        AverageCashYieldTypes.slice(selectedIndex + 1),
      );
    }

    setAverageCashYieldTypes(newSelectedOrders);
  };
  React.useEffect(() => {
    console.log('🐨');
    console.log(AverageCashYieldTypes);
    props.handleFilters(AverageCashYieldTypes);
  }, [AverageCashYieldTypes]);
  return (
    <>
      <FormControlLabel
        className={
          AverageCashYieldTypes.length === AverageCashYieldType.length
            ? classes.checkBoxContainer
            : classes.checkBoxContainerChecked
        }
        control={
          <Checkbox
            checked={
              AverageCashYieldTypes.length === AverageCashYieldType.length
            }
            disableRipple
            disableFocusRipple
            checkedIcon={
              <CheckCircleIcon size="small" className={classes.checkBox} />
            }
            icon={
              <CircleUnchecked size="small" className={classes.uncheckBox} />
            }
            onChange={handleSelectAll}
            name="All"
            value="All"
          />
        }
        label={<Typography variant="body1">All</Typography>}
      />
      {AverageCashYieldType.slice(0).map((property) => (
        <FormControlLabel
          className={
            AverageCashYieldTypes.indexOf(property.id) !== -1
              ? classes.checkBoxContainer
              : classes.checkBoxContainerChecked
          }
          key={property.id}
          control={
            <Checkbox
              onChange={(event) =>
                AverageCashYieldTypesFilters(event, property.id)
              }
              disableRipple
              disableFocusRipple
              checked={AverageCashYieldTypes.indexOf(property.id) !== -1}
              checkedIcon={
                <CheckCircleIcon size="small" className={classes.checkBox} />
              }
              icon={
                <CircleUnchecked size="small" className={classes.uncheckBox} />
              }
              name={property.name}
              value={AverageCashYieldTypes.indexOf(property.id) !== -1}
            />
          }
          label={<Typography variant="body1">{property.name}</Typography>}
        />
      ))}
    </>
  );
});
