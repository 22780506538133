import React, { useState } from 'react';
import { makeStyles, Paper, Box, Avatar, Grid } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useMediaQuery, useTheme } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '84.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  faqsAccordion: {
    boxShadow: '0px 2px 4px 2px rgba(241, 243, 255, 1)',
    '&::before': {
      backgroundColor: 'white',
    },
  },
  // redundant header
  cover: {
    borderRadius: '5px',
    height: '80px',
    width: '80px',
    boxShadow: '4px 4px 10px rgba(0,0,0,.15)',
    textAlign: 'center',
  },
  propertyStats: {
    textAlign: 'center',
    borderRadius: '5px',
    padding: theme.spacing(1),
    margin: theme.spacing(0, 0.1),
    flexGrow: '0',
    maxWidth: '30.833333%',
    flexBasis: '30.633333%',
    // boxShadow: '0px 5px 9px 2px rgba(241, 243, 255, 1)',
  },
}));

const PropertyFaqs = ({ roi, name, coverImage }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  const [expanded, setExpanded] = useState(false);

  if (!roi) {
    return <></>;
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const data = [
    {
      id: 'panel1',
      heading: 'What is Prestige?',
      details: '',
    },
    {
      id: 'panel2',
      heading:
        'What is the return of interest rate expected for this property?',
      details: '10',
    },

    {
      id: 'panel3',
      heading:
        'How many years do we have to hold this property before selling it?',
      details: '5',
    },

    {
      id: 'panel4',
      heading: 'Does investing in this property give me ownership?',
      details: 'Yes',
    },
    {
      id: 'panel5',
      heading: 'Does this property have the advantage of tax exemptions?',
      details: 'Yes',
    },
  ];

  const {
    IRR,
    averageCashYield,
    equityMultiple,
    holdPeriod,
    minHoldPeriod,
  } = roi;
  return (
    <Paper elevation={0}>
      <Box p={4}>
        <Typography variant="h4" gutterBottom>
          FAQ
        </Typography>
        <Box m={4} />
        {data.map((accordion) => {
          const { id, heading, details } = accordion;
          return (
            <Accordion
              expanded={expanded === id}
              key={id}
              onChange={handleChange(id)}
              elevation={0}
              className={classes.faqsAccordion}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                elevation={0}
              >
                <Typography className={classes.heading}>{heading}</Typography>
              </AccordionSummary>
              <AccordionDetails elevation={0}>
                <Typography>{details}</Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
        <Box mt={20} />
        {/* <Grid
          item
          container
          alignItems="center"
          justifyContent="space-evenly"
          xs={12}
          sm={6}
          style={{ margin: '0 auto',backgroundColor:"red", display:"flex",flexDirection:"row" }}
        > */}
          <div style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center"}}>
          <Avatar
            className={classes.cover}
            src={coverImage}
            alt=""
            variant="square"
            style={{ margin: '0 1rem' }}
          />

          <Typography variant="h4">{name}</Typography>
          </div>
         
       
        <Box mt={10} />

        <Grid item container>
          <Grid item className={classes.propertyStats}>
            <Typography color="secondary">
              Targeted
              <br />
              Investor IRR
            </Typography>
            <Typography variant="h3" color="secondary" style={{fontWeight:"bold"}}>
              {IRR}%
            </Typography>
          </Grid>
          <Grid item className={classes.propertyStats}>
            <Typography color="secondary">
              Targeted
              <br />
              Equity Multiple
            </Typography>
            <Typography variant="h3" color="secondary" style={{fontWeight:"bold"}}>
              {equityMultiple}%
            </Typography>
          </Grid>
          <Grid item className={classes.propertyStats}>
            <Typography color="secondary">
              Targeted
              <br />
              Avg Cash Yield
            </Typography>
            <Typography variant="h3" color="secondary" style={{fontWeight:"bold"}}>
              {averageCashYield}%
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box pb={15} />
    </Paper>
  );
};

export default PropertyFaqs;
