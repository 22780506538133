import React, { Component } from 'react';
import { Formik } from 'formik';
import { object, ref, string } from 'yup';
import axios from 'axios';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from './components/Spinner';
import Alert from './components/Alert';
import { Box, Container, Grid, makeStyles, TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getBaseUrl } from '../../../utils/request';

const useStyles = makeStyles((theme) => ({
  formContent: {
    boxShadow: '0 2px 2px rgba(0,0,0,.1)',
  },
  inputBorder: {
    '& label.Mui-focused': {
      color: theme.common.lighterBlue,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.common.lighterBlue,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '5px',
      '& fieldset': {
        borderColor: theme.common.lighterBlue,
      },
      '&:hover fieldset': {
        borderColor: theme.common.lighterBlue,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.common.lighterBlue,
      },
    },
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
    textTransform: 'none',
    border: '1px solid #FECF0E',
    '&:hover': {
      background: theme.common.yellowOrange,
      color: 'white',
    },
  },
}));

const PasswordReset = () => {
  const classes = useStyles();
  const [passChangeSuccess, setPassChangeSuccess] = React.useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const _handleSubmit = ({
    currentPass,
    newPass,
    setSubmitting,
    resetForm,
  }) => {
    console.log({
      currentPass,
      newPass,
      setSubmitting,
      resetForm,
    });
    const { accessToken: token } = userInfo;
    console.log(token);
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    let data = {
      oldPassword: currentPass,
      newPassword: newPass,
    };
    setTimeout(async () => {
      try {
        const response = await axios.put(
         getBaseUrl('password'),
          data,
          config,
        );

        if (response && response.status === 200) {
          setSubmitting(false);
          toast.success('Your password has been updated', {
            position: 'bottom-center',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            transition: Zoom,
          });
          setPassChangeSuccess(true);
          resetForm();
        }
      } catch (error) {
        resetForm();
        toast.error('Your old password is wrong', {
          position: 'bottom-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          transition: Zoom,
        });
      }
    }, 1000);
  };

  return (
    <Container maxWidth="xs">
      <Formik
        initialValues={{
          currentPass: '',
          newPass: '',
        }}
        validationSchema={object().shape({
          currentPass: string().required('Current password is required'),
          newPass: string()
            .required('New password is required')
            .max(20, 'Maximum 20 characters are allowed')
            .min(8, 'Password is too short - should be 8 chars minimum.')
            .matches(
              /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
              'Password must contain one uppercase letter, one lowercase letter, one digit and one special character',
            ),
        })}
        onSubmit={({ currentPass, newPass }, { setSubmitting, resetForm }) =>
          _handleSubmit({
            currentPass,
            newPass,

            setSubmitting,
            resetForm,
          })
        }
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          isValid,
        }) => (
          <Box className={classes.formContent}>
            <form
              onSubmit={handleSubmit}
              className={classes.formMain}
              style={{ margin: '3rem 0', padding: '5rem 2rem' }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  sm={12}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <InputLabel
                    htmlFor="password-current"
                    error={Boolean(touched.currentPass && errors.currentPass)}
                  >
                    Current Password
                  </InputLabel>
                  <TextField
                    id="password-current"
                    variant="outlined"
                    size="small"
                    name="currentPass"
                    className={classes.inputBorder}
                    type="password"
                    value={values.currentPass}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.currentPass && errors.currentPass)}
                  />
                  <FormHelperText
                    error={Boolean(touched.currentPass && errors.currentPass)}
                  >
                    {touched.currentPass && errors.currentPass
                      ? errors.currentPass
                      : ''}
                  </FormHelperText>
                </Grid>
                <ToastContainer
                  position="bottom-center"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
                <Grid
                  item
                  sm={12}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <InputLabel
                    htmlFor="password-new"
                    error={Boolean(touched.newPass && errors.newPass)}
                  >
                    {'New Password'}
                  </InputLabel>
                  <TextField
                    className={classes.inputBorder}
                    variant="outlined"
                    id="password-new"
                    size="small"
                    name="newPass"
                    type="password"
                    value={values.newPass}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.newPass && errors.newPass)}
                  />
                  <FormHelperText
                    error={Boolean(touched.newPass && errors.newPass)}
                  >
                    {touched.newPass && errors.newPass ? errors.newPass : ''}
                  </FormHelperText>
                </Grid>
                <Grid item sm={12} style={{ marginTop: '1rem' }}>
                  <Button
                    type="submit"
                    className={classes.submit}
                    variant="outlined"
                    fullWidth
                    disabled={Boolean(!isValid || isSubmitting)}
                  >
                    Change Password
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        )}
      </Formik>
    </Container>
  );
};

export default PasswordReset;
