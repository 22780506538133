import React, { createContext, useContext, useState } from 'react';

// mode of call
// investment amount
// date of meeting ⌚
// time slot

const InvestNowSection = createContext();

export function InvestNowSectionDataProvider(props) {
  const [investmentdata, setInvestmentData] = useState({
    mode: '',
    amount: 0,
    date: '',
    time: '',
  });
  const value = [investmentdata, setInvestmentData];
  return <InvestNowSection.Provider value={value} {...props} />;
}

export function useInvestNowSection() {
  const context = useContext(InvestNowSection);
  if (!context) {
    throw new Error('useContext must be used within InvestNow Provider');
  }
  return context;
}
// refactor the MeetingTimes to functional component

