import React, { useState, memo } from 'react';
import { propertyType } from './../../../services/Tags';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
  checkBoxContainer: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(4),
    borderRadius: '5px',
    background: '#FDC424',
    border: '1px solid #FDC424',

    // border: '1px solid #C5CACE',
  },
  checkBoxContainerChecked: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(4),
    borderRadius: '5px',
    border: '1px solid #C5CACE',
  },

  checkBox: {
    width: theme.spacing(2.2),
    height: theme.spacing(2.2),
    color: 'white',
    color: '#F8F9FC',
    fill: 'white',
    '&$checked': {
      fill: 'white',
      color: '#F8F9FC',
    },
  },
  uncheckBox: {
    width: theme.spacing(2.2),
    height: theme.spacing(2.2),
    // this totally depends on the background color, gotta use white just in case if we change.
    color: '#F8F9FC',
  },
}));

export const PropertyTypeFilter = memo((props) => {
  const classes = useStyles();

  // const [propertyTypes, setPropertyTypes] = useState([]);
  // const handlePropertyTypeFilters = (value) => {
  //   const currentType = propertyTypes.indexOf(value);
  //   const newCurrentType = [...propertyTypes];
  //   if (currentType === -1) {
  //     newCurrentType.push(value);
  //   } else {
  //     newCurrentType.splice(currentType, 1);
  //   }
  //   setPropertyTypes(newCurrentType);
  //   props.handleFilters(newCurrentType);
  // };

  const [PropertyTypes, setPropertyTypes] = useState([]);

  const handleSelectAll = (event) => {
    const selectedOrders = event.target.checked
      ? propertyType.map((property) => property.name)
      : [];

    setPropertyTypes(selectedOrders);
  };

  const handlePropertyTypeFilters = (event, name) => {
    const selectedIndex = PropertyTypes.indexOf(name);
    let newSelectedOrders = [];

    if (selectedIndex === -1) {
      newSelectedOrders = newSelectedOrders.concat(PropertyTypes, name);
    } else if (selectedIndex === 0) {
      newSelectedOrders = newSelectedOrders.concat(PropertyTypes.slice(1));
    } else if (selectedIndex === PropertyTypes.length - 1) {
      newSelectedOrders = newSelectedOrders.concat(PropertyTypes.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedOrders = newSelectedOrders.concat(
        PropertyTypes.slice(0, selectedIndex),
        PropertyTypes.slice(selectedIndex + 1),
      );
    }

    setPropertyTypes(newSelectedOrders);
  };

  React.useEffect(() => {
    console.log('🐨');
    console.log(PropertyTypes);
    props.handleFilters(PropertyTypes);
  }, [PropertyTypes]);
  return (
    <>
      <FormControlLabel
        className={
          PropertyTypes.length === propertyType.length
            ? classes.checkBoxContainer
            : classes.checkBoxContainerChecked
        }
        control={
          <Checkbox
            checked={PropertyTypes.length === propertyType.length}
            disableRipple
            disableFocusRipple
            checkedIcon={
              <CheckCircleIcon size="small" className={classes.checkBox} />
            }
            icon={
              <CircleUnchecked size="small" className={classes.uncheckBox} />
            }
            onChange={handleSelectAll}
            name="All"
            value="All"
          />
        }
        label={<Typography variant="body1">All</Typography>}
      />
      {propertyType.slice(0).map((property) => (
        <FormControlLabel
          className={
            PropertyTypes.indexOf(property.name) !== -1
              ? classes.checkBoxContainer
              : classes.checkBoxContainerChecked
          }
          key={property.id}
          control={
            <Checkbox
              onChange={(event) =>
                handlePropertyTypeFilters(event, property.name)
              }
              disableRipple
              disableFocusRipple
              checked={PropertyTypes.indexOf(property.name) !== -1}
              checkedIcon={
                <CheckCircleIcon size="small" className={classes.checkBox} />
              }
              icon={
                <CircleUnchecked size="small" className={classes.uncheckBox} />
              }
              name={property.name}
              value={PropertyTypes.indexOf(property.name) !== -1}
            />
          }
          label={<Typography variant="body1">{property.name}</Typography>}
        />
      ))}
    </>
    // <>
    //   {propertyType.map((property) => (
    //     <FormControlLabel
    //       className={
    //         propertyTypes.indexOf(property.name) !== -1
    //           ? classes.checkBoxContainer
    //           : classes.checkBoxContainerChecked
    //       }
    //       key={property.id}
    //       control={
    //         <Checkbox
    //           onChange={() => handlePropertyTypeFilters(property.name)}
    //           disableRipple
    //           disableFocusRipple
    //           checkedIcon={
    //             <CheckCircleIcon size="small" className={classes.checkBox} />
    //           }
    //           icon={
    //             <CircleUnchecked size="small" className={classes.uncheckBox} />
    //           }
    //           name={property.name}
    //           value={property.name}
    //         />
    //       }
    //       label={<Typography variant="body1">{property.name}</Typography>}
    //     />
    //   ))}
    // </>
  );
});
