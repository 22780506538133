import axios from 'axios';

export const getBaseUrl = (str = '') => {
    const environment = process.env.REACT_APP_STAGE;
    console.log(`running environment: ${environment}`);
        // if (environment === "dev") {
    //     return 'https://server.realto.estate/api/v2/' + str;
    // } else 
    // if (environment === "stage") {
    //     return 'https://staging.realto.estate/api/v2/' + str;
    // } else if (environment === "production") {
    //     return 'https://api.realto.estate/api/v2/' + str;
    // }
    // return 'http://localhost:5000/api/v2/' + str;
    return 'https://server.realto.estate/api/v2/' + str;
};

const baseUrl = getBaseUrl();
export const getUrl = (str) => {
    return `${baseUrl}${str}`;
};
export const request = async ({
    host = baseUrl,
    url = '',
    method,
    params = {},
    data = {},
    headers = {},
    query = {},
    token,
    auth = true
}) => {
    if (auth) {
        const userInfoFromStorage = localStorage.getItem('userInfo')
            ? JSON.parse(localStorage.getItem('userInfo'))
            : null;
        const authorization = token || userInfoFromStorage.accessToken;
        headers.Authorization = `Bearer ${authorization}`;
    }
    let config = {
        method,
        params,
        data,
        query,
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            ...headers,
        },
    };

    // if (method === 'GET' || method === 'DELETE') {
    //     config = omit(config, ['data']);
    // }
    let result = await axios({
        url: `${host}${url}`,
        ...config,
    });

    return result;
};
