const exchange =[
    {
     id:1,
     country:'India',
     currency:'₹',
     rate:73
    },
    {
        id:2,
        country:'United Kingdom',
        currency:'£',
        rate:0.71
    },
    {
        id:3,
        country:'Europe',
        currency:'€',
        rate:0.82
    },
    {

        id:4,
        country:'Japan',
        currency:'¥',
        rate:109.47
    },
    {

        id:5,
        country:'USA',
        currency:'$',
        rate:1
    }
 ]

 const  formatter =(str)=>{
    if (str < 1e3) return (str);
    if (str >= 1e3 && str < 1e6) return ((str / 1e3).toFixed(1) + "K");
    if (str >= 1e6 && str < 1e9) return ( (str / 1e6).toFixed(1) + "M");
    if (str >= 1e9 && str < 1e12) return ((str / 1e9).toFixed(1) + "B");
    if (str >= 1e12) return ( (str / 1e12).toFixed(1) + "T");
 }

 export const currConv =(amount=string,location)=>{

    let num = parseInt(amount)
   
var str='';
     switch(location)
     {
         case 'India':
        
           
            str = formatter(num*73);
         
         return'₹'+ str;
         break

         case 'United Kingdom':
           
          
            str = formatter(num*0.71);
            return'£'+ str;
            
            break

            case 'Europe':
               
            
                str = formatter(num*0.82);
             
                return'€'+ str;
                break

                case 'Japan':
                
                    str = formatter(num*110);
                    return'¥'+ str;
                    break

                    default:
                        return'$'+ amount;
            

     }
 }