import React, { useState, useEffect } from 'react';
import {
    Container,
    Grid,
    makeStyles,
    Paper
  } from '@material-ui/core';
import './../../../pages/Dashboard/dashboard.css';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getBaseUrl } from '../../../utils/request';

const useStyles = makeStyles((theme) => ({
    detailsText: {
        width: '100%',
        border: '1px solid #C5CACE',
        borderRadius: 10,
        padding: 16
    }
}));

const ChangePassword = (props) => {
    const classes = useStyles();
    const [password, setPassword] = useState({});
    const [errors, setErrors] = useState({});
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    console.log("userInfo", userInfo)

    const handleChange = (event) => {
        const { name, value } = event.target;
        setPassword(password => ({
            ...password,
            [name]: value
        }));
    }

    const handleValidation = () => {
        let event = password;
        let errors = {};
        let formIsValid = true;

        if(!password["oldPassword"]){
           formIsValid = false;
           errors["oldPassword"] = "Old Password cannot be empty !";
        }
        if(!password["newPassword"]){
            formIsValid = false;
            errors["newPassword"] = "New Password cannot be empty !";
        }
        setErrors(errors);
        return formIsValid;
    }

    const toggleCheckPassword = () => {
        if(handleValidation()){
            const config = {
                headers: {
                'Authorization': 'Bearer ' + userInfo.accessToken,
                'Content-Type': 'application/json',
                },
            }
            axios.put(getBaseUrl('password'), password, config)
            .then(res => {
                console.log("res", res);
                setPassword({});
                props.passwordChanged();
            })
        }
    }

    return(
    <div>
     <div className="p-5">
     <img src="/assets/icons/arrow-left.svg" alt="arrow" width="20px" />
     <h3 className="mt-3">Change Password</h3>
     <p>Enter a new password for your account</p>
     <p className="mb-0">New Password</p>
    <input type="text" placeholder="SarType New Password" className={classes.detailsText} name="oldPassword" onChange={handleChange} />
    <span>{(errors.oldPassword) ? errors.oldPassword : ""}</span>                 
    <p className="mb-0 mt-3">Confirm Password</p>
    <input type="text" placeholder="Confirm Password" className={classes.detailsText} name="newPassword" onChange={handleChange} />
    <span>{(errors.newPassword) ? errors.newPassword : ""}</span>                        
     <Grid container spacing={4} className="mt-3 mb-4">
         <Grid item xs={12} sm={12} md={12} lg={12}>
             <button className="btnYellowBorder" style={{width: "100%"}} onClick={toggleCheckPassword}>Change Password</button>
         </Grid>
     </Grid>
    </div>  
    </div>   
    )
}

export default ChangePassword;



