/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, {useState,useEffect} from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, BrowserRouter } from 'react-router-dom';
import { Route } from "react-router";
import CssBaseline from '@material-ui/core/CssBaseline';
import {useSelector} from 'react-redux'
import HomePage from './../pages/HomePage/index';
import Dashboard from './../pages/Dashboard/index';
import Account from './../pages/Account/index';
import SignUp from '../pages/SignUpPage';
import SignIn from './../pages/SignInPage/';
import NavBar from './../components/NavBar/';
import PropertyListings from './../components/MarketPlace/PropertyListings';
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import PropertyDetails from './../components/MarketPlace/PropertyDetails/PropertyDetails';
import Journal from './../pages/Journal';
import WishList from './../pages/WishList';
import Chat from './../components/Chatbot/Chat';



// export default class RouterPage extends Component{
//   constructor(props){
//     super(props);
//     this.state={toggle: false}
//   }





//   render(){
//     function getTerm() {
//     var data = localStorage.getItem("term");
//     if(data!==null)
//       return true
//     else{
//       return false
//     }
//   }

   
  
//     return (
//       <BrowserRouter>
//         <CssBaseline />
//         <Helmet titleTemplate="%s - Realto" defaultTitle="Realto">
//           <meta name="description" content="Realto" />
//         </Helmet>
//         <NavBar />
//         <Switch>
//           <Route exact path="/" component={HomePage} />
//           <PublicRoutes exact path="/signup" component={SignUp} />
//           <PublicRoutes exact path="/signin" component={SignIn} />
//           <PublicRoutes exact path="/journal" component={Journal} />
//           <PrivateRoutes
//             exact
//             path="/dashboard"
//             component={Dashboard}
//           />
//           <PrivateRoutes
//             exact
//             path="/wish-list"
//             component={WishList}
//           />
//           <PrivateRoutes
//             exact
//             path="/account"
//             component={Account}
//           />
//           <PrivateRoutes
//             exact
//             path="/marketplaces"
//             component={PropertyListings}
//           />
//           <PrivateRoutes
//             exact
//             path="/marketplaces/:id/"
//             component={PropertyDetails}
//           />
//         </Switch>
//         <Chat openChat={getTerm()} />
//       </BrowserRouter>
//     );
//   }

// }
 const RouterPage =()=>{
   const [toggle, settoggle] = useState(false);
   const botterm = useSelector(state => state.botterm)
   


    return (
      <BrowserRouter>
        <CssBaseline />
        <Helmet titleTemplate="%s - Realto" defaultTitle="Realto">
          <meta name="description" content="Realto" />
        </Helmet>
        <NavBar />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <PublicRoutes exact path="/signup" component={SignUp} />
          <PublicRoutes exact path="/signin" component={SignIn} />
          <PublicRoutes exact path="/journal" component={Journal} />
          <PrivateRoutes
            exact
            path="/dashboard"
            component={Dashboard}
          />
          <PrivateRoutes
            exact
            path="/wish-list"
            component={WishList}
          />
          <PrivateRoutes
            exact
            path="/account"
            component={Account}
          />
          <PrivateRoutes
            exact
            path="/marketplaces"
            component={PropertyListings}
          />
          <PrivateRoutes
            exact
            path="/marketplaces/:id/"
            component={PropertyDetails}
          />
        </Switch>
      
      </BrowserRouter>
    );
}

export default RouterPage

