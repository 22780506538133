import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { TextField, InputLabel } from '@material-ui/core/';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import Google from './../../assets/icons/google.svg';
import { IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import Alert from '@material-ui/lab/Alert';
import { shadows } from '@material-ui/system';
import sampleimage from '../../project_assets/sample.svg'

import { register } from './../../store/user/user.actions';
import { Link } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Checkbox as MuiCheckbox } from './Checkbox';

import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { GoogleLogin } from 'react-google-login';
import { googleSignUp } from './../../store/user/user.actions';

import PhoneInput from 'react-phone-input-2';
import signInBackground from './../../assets/icons/niv-rozenberg-ySNtJhDX-cw-unsplash.png';

import 'react-phone-input-2/lib/material.css';
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from 'react-country-region-selector';

import './../../styles/theme/customizedStyle.css';
import styled from 'styled-components';

const useStyles = makeStyles((theme) => ({
  parentContainer: {
    background: `#fff`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    minHeight: '100vh',
    marginTop: '5vh',
    // minHeight: '100vh',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // display: 'grid',
    // placeItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',

   
  },
  signupContainer: {
    // margin: theme.spacing(12, 'auto', 12),
    // margin: theme.spacing(1, 'auto', 1),
    height: 'auto',
    marginTop: '10vh',
    marginBottom: '2rem',
    // marginTop: '80px',
    // padding: theme.spacing(2, 4),
    // display: 'flex',
    // flexDirection: 'column',
    background: '#fff',
    maxWidth: '500px',
    
    
  },
  signUpResponsive: {
    maxWidth: '90vw',
  },
  paper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    textTransform: 'none',
    height: '40px',
    boxShadow: 'none',
  },
  submitButton: {
    margin: theme.spacing(3, 0, 2),
    textTransform: 'none',
    border: '1px solid #FECF0E',
    '&:hover': {
      background: theme.common.yellowOrange,
      color: 'white',
    },
    // borderRadius: '10px',
  },
  button: {
    margin: theme.spacing(1),
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  border: {
    borderBottom: '1px solid lightgray',
    width: '100%',
  },
  content: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    fontWeight: 500,
    fontSize: 16,
    color: 'gray',
    width: '25%',
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  navlink: {
    textDecoration: 'none',
    color: '#1f1f1f',
    '&:active': {
      color: '#1f1f1f',
    },
  },
  googleButton: {
    flex: 1,
    textTransform: 'none',
    alignSelf: 'center',
  },
  termsConditionsLink: {
    textDecoration: 'none',
    color: '#4285F4ff',
    '&:hover': {
      color: '#4285F4ff',
    },
  },
  label: {
    color: '#1F1F1F',
   
  },
  inputBorder: {
    '& label.Mui-focused': {
      color: theme.common.lighterBlue,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.common.lighterBlue,
    },
    '& .MuiOutlinedInput-root': {
      // borderRadius: '5px',
      '& fieldset': {
        borderColor: theme.common.lighterBlue,
      },
      '&:hover fieldset': {
        borderColor: theme.common.lighterBlue,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.common.lighterBlue,
      },
    },
  },
  countryDropdown: {
    paddingTop: '10.5px',
    paddingBottom: '10.5px',
    // borderRadius: '5px',
    border: '1px solid #c5cace',
    '&:hover': {
      border: '1px solid #c5cace',
    },
  },
}));

export default function SignUp(props) {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const userRegister = useSelector((state) => state.userRegister);
  const { error: serverError } = userRegister;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  // state for the other libraries installed
  const [user, setUser] = useState({
    state: '',
    country: '',
  });
  const [contact, setContact] = useState(0);
  const [countryCode, setCountryCode] = useState('');
  const [Lock, setLocks] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    if (userInfo) {
      props.history.push('/');
    }
  }, [dispatch, userInfo, props.history]);
  const handleCountryCodePhone = (value, country) => {
    setContact(value);
    setCountryCode(country.dialCode);
  };
  const submitHandler = (values) => {
    const { firstName, lastName, email, password } = values;
    const { state, country } = user;
    const userData = {
      email: email,
      firstName: firstName,
      lastName: lastName,
      password: password,
      contact: contact,
      countryCode: countryCode,
      countryName: country,
      stateName: state,
    };
    dispatch(register(userData));
    props.history.push('/signup');
  };
  const selectCountry = (country) => {
    setLocks(false);
    setUser((user) => ({ ...user, country: country }));
  };

  const selectRegion = (region) => {
    setUser((user) => ({ ...user, state: region }));
  };

  const responseGoogle = (response) => {
    const { profileObj, tokenObj } = response;
    if (tokenObj) {
      const { id_token } = tokenObj;
      dispatch(googleSignUp(id_token));
    }
  };

  const InfoContainer = styled.div`
  width: 50%;
  height: 100vh;
  background: #0f1723;
  display: flex;
  align-items: center;
  position: relative;
  justify-content:center;
  flex-direction: column;
  
  
  @media screen and (max-width:768px){
    display: none;
  }
  `

  const TitlePage= styled.span`
  font-size: 40px;
  color:#fff;
  `
  const Subtitle = styled.span`
  font-size: 25px;
  color:#fff;
  padding: 20px;
  `
  

  return (
    <div className={classes.parentContainer}>
      <InfoContainer>
      <img src={sampleimage} style={{height:"100%", width:"100%", position:"relative"}}/>

      </InfoContainer>
      
      
     
      <Container
        
        component="main"
        className={clsx(
          classes.signupContainer,
          matches ? classes.signUpResponsive : '',
        )}
      >
        <CssBaseline />
        <div className={classes.paper}>
          {/* <Typography component="h1" variant="h4" gutterBottom>
            Sign up
          </Typography> */}
          {serverError && (
            <Alert severity="error" color="error">
              {serverError}
            </Alert>
          )}
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              contact: '',
              country: '',
              state: '',
              password: '',
              confirmPassword: '',
              acceptTerms: false,
            }}
            validationSchema={Yup.object().shape({
              firstName: Yup.string().required('First name is required'),
              lastName: Yup.string().required('Last name is required'),
              email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
              password: Yup.string()
                .max(255)
                .required('Password is required')
                .min(8, 'Password is too short - should be 8 chars minimum.')
                .matches(
                  /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                  'Password must contain one uppercase letter, one lowercase letter, one digit and one special character',
                ),
              confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required('Confirm Password is required'),
              acceptTerms: Yup.bool().oneOf(
                [true],
                'Please accept Terms & Conditions',
              ),
            })}
            onSubmit={(data) => {
              submitHandler(data);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit} className={classes.form}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <InputLabel
                      className={classes.label}
                      htmlFor="firstName"
                      shrink
                      variant="outlined"
                    >
                      First Name *
                    </InputLabel>

                    <TextField
                      autoComplete="fname"
                      onBlur={handleBlur}
                      name="firstName"
                      size="small"
                      variant="outlined"
                      error={Boolean(touched.firstName && errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                      onChange={handleChange}
                      value={values.firstName}
                      fullWidth
                      className={classes.inputBorder}
                      id="firstName"
                      autoFocus
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <InputLabel
                      className={classes.label}
                      htmlFor="lastName"
                      shrink
                      variant="outlined"
                    >
                      Last Name *
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      onBlur={handleBlur}
                      className={classes.inputBorder}
                      fullWidth
                      error={Boolean(touched.lastName && errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                      onChange={handleChange}
                      value={values.lastName}
                      size="small"
                      id="lastName"
                      name="lastName"
                      autoComplete="lname"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <InputLabel
                      className={classes.label}
                      htmlFor="email"
                      shrink
                      variant="outlined"
                    >
                      Email ID *
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      onBlur={handleBlur}
                      className={classes.inputBorder}
                      fullWidth
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      onChange={handleChange}
                      value={values.email}
                      size="small"
                      id="email"
                      name="email"
                      autoComplete="email"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <InputLabel
                      className={classes.label}
                      htmlFor="phone"
                      shrink
                      variant="outlined"
                    >
                      Mobile Number *
                    </InputLabel>
                    <PhoneInput
                      country={'us'}
                      onBlur={handleBlur}
                      id="phone"
                      label=""
                      name="contact"
                      value={user.contact}
                      onChange={(value, country, e) =>
                        handleCountryCodePhone(value, country)
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <InputLabel
                      className={classes.label}
                      htmlFor="country"
                      shrink
                      variant="outlined"
                    >
                      Country *
                    </InputLabel>
                    <CountryDropdown
                      onBlur={handleBlur}
                      value={user.country}
                      className={classes.countryDropdown}
                      onChange={(val) => selectCountry(val)}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <InputLabel
                      className={classes.label}
                      htmlFor="state"
                      shrink
                      variant="outlined"
                    >
                      State *
                    </InputLabel>
                    <RegionDropdown
                      className={classes.countryDropdown}
                      onBlur={handleBlur}
                      disabled={Lock}
                      country={user.country}
                      value={user.state}
                      onChange={(val) => selectRegion(val)}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <InputLabel
                      className={classes.label}
                      htmlFor="password"
                      shrink
                      variant="outlined"
                    >
                      Password *
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      onBlur={handleBlur}
                      className={classes.inputBorder}
                      fullWidth
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                      onChange={handleChange}
                      value={values.password}
                      size="small"
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      id="password"
                      autoComplete="current-password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              disableRipple
                              size="small"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <InputLabel
                      className={classes.label}
                      htmlFor="confirmPassword"
                      shrink
                      variant="outlined"
                    >
                      Confirm Password *
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      onBlur={handleBlur}
                      className={classes.inputBorder}
                      fullWidth
                      color="primary"
                      error={Boolean(
                        touched.confirmPassword && errors.confirmPassword,
                      )}
                      helperText={
                        touched.confirmPassword && errors.confirmPassword
                      }
                      onChange={handleChange}
                      value={values.confirmPassword}
                      size="small"
                      name="confirmPassword"
                      type={showPassword ? 'text' : 'password'}
                      id="confirmPassword"
                      autoComplete="current-password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              disableRipple
                              size="small"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ display: 'flex' }}>
                      {/* <Field type="checkbox" name="acceptTerms" /> */}
                      <MuiCheckbox name="acceptTerms" />

                      <div>
                        <Typography variant="subtitle2">
                          I have reviewed and agree to the
                          <Link
                            href="#"
                            variant="subtitle2"
                            className={classes.termsConditionsLink}
                          >
                            {' '}
                            Terms of Service
                          </Link>{' '}
                          and
                          <Link
                            href="#"
                            variant="subtitle2"
                            className={classes.termsConditionsLink}
                          >
                            {' '}
                            Privacy Policy
                          </Link>
                        </Typography>
                      </div>
                    </div>

                    <ErrorMessage
                      name="acceptTerms"
                      component="div"
                      className={
                        errors.acceptTerms && touched.acceptTerms
                          ? 'MuiFormHelperText-root Mui-error MuiFormHelperText-contained MuiFormHelperText-root'
                          : ''
                      }
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  size="large"
                  disableRipple
                  variant="outlined"
                  className={classes.submitButton}
                  onClick={handleSubmit}
                  disabled={isSubmitting && !serverError}
                >
                  Sign Up
                </Button>

                <Grid container justify="flex-end">
                  <Grid item>
                    <Link
                      to="/signin"
                      className={classes.navlink}
                      variant="body2"
                    >
                      Already have an account?{' '}
                      <span className={classes.termsConditionsLink}>Login</span>
                    </Link>
                  </Grid>
                </Grid>

                <div className={classes.container}>
                  <div className={classes.border} />
                  <span className={classes.content}>Or</span>
                  <div className={classes.border} />
                </div>

                <GoogleLogin
                  clientId="744106296275-1eee3ah5o209650jdukl8uogjehegod8.apps.googleusercontent.com"
                  render={(renderProps) => (
                    <Button
                      type="submit"
                      fullWidth
                      size="large"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      style={{
                        background: '#4285F4ff',
                        padding: '.1rem .2rem',
                      }}
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                    >
                      <Grid container>
                        <Avatar
                          variant="square"
                          style={{
                            background: 'white',
                            borderRadius: '5px',
                            padding: '.4rem',
                            width: '32px',
                            height: '32px',
                          }}
                          src={Google}
                        ></Avatar>

                        <Grid item className={classes.googleButton}>
                          Continue With Google
                        </Grid>
                      </Grid>
                    </Button>
                  )}
                  buttonText=""
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy={'single_host_origin'}
                />
              </form>
            )}
          </Formik>
        </div>
      </Container>
    </div>
  );
}
