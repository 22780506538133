import React from 'react';

import ImageGallery from 'react-image-gallery';
import './../../../../theme/ImageSlider.css';

const ImagesH = [
  {
    original:
      'https://images.unsplash.com/photo-1593696140826-c58b021acf8b?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
    thumbnail: 'https://picsum.photos/id/1020/250/150/',
  },
  {
    original:
      'https://images.unsplash.com/photo-1599809275671-b5942cabc7a2?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
    thumbnail: 'https://picsum.photos/id/1018/250/150/',
  },
  {
    original:
      'https://images.unsplash.com/photo-1592595896551-12b371d546d5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
    thumbnail: 'https://picsum.photos/id/1019/250/150/',
  },
  {
    original:
      'https://images.unsplash.com/photo-1560185127-6ed189bf02f4?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
    thumbnail: 'https://picsum.photos/id/1019/250/150/',
  },
  {
    original:
      'https://images.unsplash.com/photo-1560448204-e02f11c3d0e2?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
    thumbnail: 'https://picsum.photos/id/1015/250/150/',
  },
];
const ImagesV = [
  {
    original:
      'https://images.unsplash.com/photo-1593696140826-c58b021acf8b?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
    thumbnail: 'https://picsum.photos/id/1020/250/150/',
  },
  {
    original:
      'https://images.unsplash.com/photo-1599809275671-b5942cabc7a2?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
    thumbnail: 'https://picsum.photos/id/1018/250/150/',
  },
  {
    original:
      'https://images.unsplash.com/photo-1592595896551-12b371d546d5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
    thumbnail: 'https://picsum.photos/id/1019/250/150/',
  },
  {
    original:
      'https://images.unsplash.com/photo-1560185127-6ed189bf02f4?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
    thumbnail: 'https://picsum.photos/id/1019/250/150/',
  },
  {
    original:
      'https://images.unsplash.com/photo-1560448204-e02f11c3d0e2?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
    thumbnail: 'https://picsum.photos/id/1015/250/150/',
  },
];
const Images = [
  {
    original: 'https://picsum.photos/id/1018/1000/600/',
    thumbnail: 'https://picsum.photos/id/1018/250/150/',
  },
  {
    original: 'https://picsum.photos/id/1015/1000/600/',
    thumbnail: 'https://picsum.photos/id/1015/250/150/',
  },
  {
    original: 'https://picsum.photos/id/1019/1000/600/',
    thumbnail: 'https://picsum.photos/id/1019/250/150/',
  },
];
function PropertyHighlightsGallery({ images }) {
  const updatedSlides = images.map((image) => ({
    original: image,
    thumbnail: image,
  }));
  console.table(updatedSlides);
  for (let slide of updatedSlides) {
    console.log(slide);
  }
  return (
  
 <ImageGallery
      items={Images}
     
      // items={updatedSlides.length !== 0 ? updatedSlides : ImagesH}
      showPlayButton={false}
      // autoPlay={true}
      lazyLoad
      showFullscreenButton
    />
 
   
  );
}

export default PropertyHighlightsGallery;
