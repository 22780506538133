import React from 'react';
import {
  Grid,
  makeStyles,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';
import SimpleMap from './SimpleMap';

const useStyles = makeStyles((theme) => ({}));

function PropertyLocation(props) {
  const classes = useStyles();
  const { location } = props;
  let center = {
    lat: 34,
    lng: 34,
  };
  return (
    <Card elevation={0}>
      <CardContent>
        <Typography variant="h4" gutterBottom>
          Location
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} sm={6}>
            <Typography variant="body1" gutterBottom>
              Thane is one of the few industrially advanced districts in the
              state of Maharashtra. Thane district is on the North of Konkan
              division. According to census 2011, it is third in the state in
              terms of population. Area of ​​the district is 4214 sq. Km. It is
              1.37 percent of the state.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Thane is a city just outside Mumbai, in the western Indian state
              of Maharashtra. It’s known as the ‘City of Lakes’, and its more
              than 30 lakes include tree-lined Upvan Lake, a popular
              recreational spot. Beside Talao Pali Lake, Kopineshwar Mandir is
              an old, domed Hindu temple dedicated to Lord Shiva. To the west,
              leopards, monkeys and parakeets inhabit the teak forest and bamboo
              groves of Sanjay Gandhi National Park.
            </Typography>
            <Typography variant="body1" gutterBottom>
              According to census 2011, it is third in the state in terms of
              population. Area of ​​the district is 4214 sq. Km. It is 1.37
              percent of the state.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Area of ​​the district is 4214 sq. Km. It is 1.37 percent of the
              state.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            <SimpleMap
              latitude={location?.latitude}
              longitude={location?.longitude}
              center={center}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default PropertyLocation;
