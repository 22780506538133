import React, { useState } from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import { useInvestNowSection } from '../../../../context/investnow-context';

const useStyles = makeStyles((theme) => ({
  boxCls: {
    background: '#FFF9E8 0% 0% no-repeat padding-box',
    borderRadius: 10,
    padding: '20px 50px',
    // maxWidth: '556px',
    width: '100%',
    margin: '0 auto',
  },
  cardCls: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: 10,
    padding: 12,
  },
  calendarParentContainer: {
    // maxWidth: '556px',
    // margin: '0 auto',
  },
}));

const MeetingCalendar = () => {
  const classes = useStyles();

  const [selectedDate, setSelectedDate] = React.useState(
    new Date('2021-03-18T21:11:54'),
  );

  const [investmentdata, setInvestmentData] = useInvestNowSection();

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setInvestmentData({ ...investmentdata, date: selectedDate });
  };
  console.log('🐨');
  console.log(investmentdata);

  return (
    <div className={classes.calendarParentContainer}>
      <div className={classes.boxCls}>
        <Typography align="center" variant="h5">
          Select date of call
        </Typography>

        <Grid container spacing={4} className="text-center mt-4 mb-2">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk
              disablePast={true}
              variant="static"
              openTo="date"
              value={selectedDate}
              onChange={handleDateChange}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </div>
    </div>
  );
};

export default MeetingCalendar;
