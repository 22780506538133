import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Paper,
  Box,
  Button,
} from '@material-ui/core';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// !Todos Omit the css import below.
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// !Todos the document imported to be updated
import designResource from '../../../../assets/files/design.pdf';

import './../../dashboard.css';

import 'react-circular-progressbar/dist/styles.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import ModalDialog from './../Dialog/index';
import Verification from './../Verification/index';
import moment from 'moment';
import { useSelector } from 'react-redux';

// Hellosign integration
import HelloSign from 'hellosign-embedded';
import { request } from '../../../../utils/request';

const headCells = [
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: true,
    label: 'Date',
  },
  {
    id: 'title',
    numeric: true,
    disablePadding: false,
    label: 'Property Name',
  },
  {
    id: 'name',
    numeric: true,
    disablePadding: false,
    label: 'Document Title',
  },
  {
    id: 'assetClass',
    numeric: false,
    disablePadding: false,
    label: 'Document Type',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'assetClass',
    numeric: false,
    disablePadding: false,
    label: 'Sign/View',
  },
];

// filters table sorting methods

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStylesTableHead = makeStyles((theme) => ({
  tableHeaderCls: {
    background: '#FFF3E7 0% 0% no-repeat padding-box',
    borderRadius: 10,
    padding: '6px 10px',
  },
  tableHeader: {
    padding: '32px 20px 10px 20px',
    position: 'relative',
  },
}));
function EnhancedTableHead(props) {
  const classes = useStylesTableHead();
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHeaderCls}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // className={classes.tableHeader}
            align="center"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
function createData(data1, data2, data3, data4, data5, data6, data7, data8) {
  return { data1, data2, data3, data4, data5, data6, data7, data8 };
}

const rows = [
  createData(
    'Jan 13, 2020',
    'Prestige Sky High',
    'Tax Distribution Prestige Sky high 2020 March',
    'Tax',
    'Pending',
  ),
  createData(
    'Jan 13, 2020',
    'Prestige Sky High',
    'Tax Distribution Prestige Sky high 2020 March',
    'Tax',
    'Pending',
  ),
  createData(
    'Jan 13, 2020',
    'Prestige Sky High',
    'Tax Distribution Prestige Sky high 2020 March',
    'Tax',
    'Signed',
  ),
  createData(
    'Jan 13, 2020',
    'Prestige Sky High',
    'Tax Distribution Prestige Sky high 2020 March',
    'Tax',
    'Signed',
  ),
];

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
  },
  margin: {
    height: theme.spacing(2),
  },
  marginTop: {
    marginTop: theme.spacing(4),
  },
  tilePaperCls: {
    width: '100%',
    padding: 20,
    marginBottom: 20,
  },
  gridBorder: {
    borderRight: '5px solid #F8F9FC',
  },
  table: {
    minWidth: 650,
  },
  tableHeaderCls: {
    background: '#FFF3E7 0% 0% no-repeat padding-box',
    borderRadius: 10,
  },
  tableHeader: {
    padding: '16px',
    position: 'relative',
  },
  tableData: {
    padding: 15,
  },
  tableTotalCls: {
    background: '#FFF3E7 0% 0% no-repeat padding-box',
    borderRadius: 10,
    padding: '6px 10px',
  },
  chartPaper: {
    padding: 10,
  },
  statusCls: {
    background: '#f5f5f5 0% 0% no-repeat padding-box',
    borderRadius: 10,
    padding: 10,
    width: 100,
  },
  downloadBtn: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #FDC424',
    borderRadius: 10,
    padding: 10,
  },
  pages: {
    margin: theme.spacing(1),
    boxShadow: '0 2px 2px #ccc',
    padding: theme.spacing(2),
    borderRadius: '5px',
    textAlign: 'center',
  },
  pdfNavigators: {
    background: '#FDC424',
    textTransform: 'none',
    border: '1px solid #fdc424',
    margin: '0 1rem',
    color: 'white',
    '&:hover': {
      background: '#FDC424',
      border: '1px solid #fdc424',
    },
  },
}));

const MyDocuments = (props) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  // console.log(userInfo);
  const classes = useStyles();
  const [verifyModal, setVerifyModal] = useState(false);
  const [signModal, setSignModal] = useState(false);

  const toggleModal = () => setVerifyModal(true);
  // const signDocModal = (row) => {
  // console.log(row.url);
  //   setSignModal(true)
  // };
  async function signDocument(file) {
    const client = new HelloSign({
      clientId: 'cf1ee74037701f333cd320b5d41a8360'
    });
    const { data } = await request({ url: `contract/sign/${file._id}`, method: 'GET', auth: true });
    let signUrl = data.sign_url;
    client.open(signUrl, {
      requestingEmail: userInfo.email,
      testMode: true
    }).then((res) => {
      console.log(res);
    }).catch((err) => {
      // handle error
      console.log(res);
    });
    client.on('sign', () => {
      alert('The document has been signed!');
    });
  }
  const closeSignModal = () => setSignModal(false);
  const closeModalDownload = () => setVerifyModal(false);

  const [tableData, setTableData] = useState([]);
  const [defaultTableData, setDefaultTableData] = useState([]);

  const [getSearch, setSearch] = useState('');

  const searchDocument = (event) => {
    if (event.target.value !== '') {
      setTableData([]);
      let result = tableData.map((data) => {
        if (
          data.title.toLowerCase().includes(event.target.value.toLowerCase())
        ) {
          return data;
        }
      });
      let finalData = result.filter(function (element) {
        return element !== undefined;
      });
      console.log('finalData', finalData);
      setTableData(finalData);
    } else {
      setTableData(defaultTableData);
    }
  };

  //  Todos  pdf viewer helper functions
  const [selectedDocument, setSelectedDocument] = useState(designResource);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  // Todos pdfview

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  useEffect(() => {
    setTableData(props.tableDetails);
    setDefaultTableData(props.tableDetails);
  }, [props.tableDetails]);

  // Investment table
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('propertyName');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, tableData.length - page * rowsPerPage);

  return (
    <div>
      <Grid container className="mb-3 mt-4">
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <input
            type="text"
            placeholder="Search documents…"
            onChange={searchDocument}
            className="searchDocCls"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          className="d-flex justify-content-end"
        ></Grid>
      </Grid>

      <p className="dashboardTileCls pt-3">Total return table</p>
      <TableContainer
        component={Paper}
        className="mt-4"
        style={{ boxShadow: 'none' }}
      >
        <Table className={classes.table} aria-label="simple table">
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={tableData.length}
          />
          {stableSort(tableData, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              const isItemSelected = isSelected(row.propertyName);
              const labelId = `enhanced-table-checkbox-${index}`;
              console.log('Dynamic pdf');
              console.log(row);
              console.log('🔥');

              return (
                <TableRow key={row.name}>
                  <TableCell
                    component="th"
                    scope="row"
                    align="center"
                    className={classes.tableData}
                  >
                    {moment(row.createdAt).format('ll')}
                  </TableCell>
                  <TableCell align="center" className={classes.tableData}>
                    {row.title}
                  </TableCell>
                  <TableCell align="center" className={classes.tableData}>
                    {row.title}
                  </TableCell>
                  <TableCell align="center" className={classes.tableData}>
                    {row.type}
                  </TableCell>
                  <TableCell align="center" className={classes.tableData}>
                    <label
                      className={classes.statusCls}
                      style={{
                        color: row.status == 'pending' ? '#FE860E' : '#29292A',
                      }}
                    >
                      {row.status}
                    </label>
                  </TableCell>
                  <TableCell align="center" className={classes.tableData}>
                    <div className={classes.downloadBtn} onClick={toggleModal}>
                      <img
                        src="/assets/icons/download.svg"
                        alt="Download"
                        width="18px"
                      />
                      <label className="downloadText">View</label>
                    </div>
                    <div className={classes.downloadBtn} onClick={() => signDocument(row)}>
                      <img
                        src="/assets/icons/verifying.svg"
                        alt="Download"
                        width="18px"
                      />
                      <label className="downloadText">Sign</label>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={tableData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <ModalDialog openDialog={verifyModal} closeModal={closeModalDownload}>
        {/* <Verification /> */}
        <Document
          file="https://res.cloudinary.com/realto-t/image/upload/v1578856439/afc0c6b0-356f-11ea-b7ec-f7b3b684c911.pdf"
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} />
        </Document>
        <Box m={1}>
          <p className={classes.pages}>
            Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
          </p>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Button
              variant="outlined"
              className={classes.pdfNavigators}
              disableElevation
              disableRipple
              disableFocusRipple
              type="button"
              disabled={pageNumber <= 1}
              onClick={previousPage}
            >
              Previous
            </Button>
            <Button
              variant="outlined"
              disableElevation
              disableRipple
              disableFocusRipple
              className={classes.pdfNavigators}
              type="button"
              disabled={pageNumber >= numPages}
              onClick={nextPage}
            >
              Next
            </Button>
          </Box>
        </Box>
      </ModalDialog>
      <ModalDialog openDialog={signModal} closeModal={closeSignModal}>
        {/* <Verification /> */}
        <Document
          file="https://res.cloudinary.com/realto-t/image/upload/v1578856439/afc0c6b0-356f-11ea-b7ec-f7b3b684c911.pdf"
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} />
        </Document>
        <Box m={1}>
          {/* <p className={classes.pages}>
            Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
          </p> */}
          <Box display="flex" alignItems="right" justifyContent="center">
            <Button
              variant="outlined"
              className={classes.pdfNavigators}
              disableElevation
              disableRipple
              disableFocusRipple
              type="button"
            // onClick={() => signDocument(url)}
            // disabled={row.status === 'pending'}
            >
              Sign Document
            </Button>
          </Box>
        </Box>
      </ModalDialog>
    </div>
  );
};

export default MyDocuments;
