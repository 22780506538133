import React from "react";

const Cards = props => {
    const content = props.payload.subtitle;
    const limit = 100;
    const toShow = content.substring(0, limit) + "...";
    return (
        <div style={{ width: 270, paddingRight: 30, float: "left" }}>
            <div className="botCard">
                <div className="card-title">
                    <p style={{
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        fontFamily: 'Montserrat',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        fontWeight: 900,
                        color: "black"
                    }}>{props.payload.title}</p>
                </div>
                <div className="card-image" style={{ width: 240, height: 240 }}>
                    <img
                        src={props.payload.imageUri}
                        alt={props.payload.imageUri} style={{ width: 140, height: 240 }}
                    />
                </div>
                <div className="card-content">
                    <p style={{
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        fontFamily: 'Montserrat',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        fontWeight: 700,
                        width: '140px'
                    }}
                    >{toShow}</p>
                </div>
                <div className="card-action">
                    <a className="btn btn-primary" target="_blank" href={props.payload.buttons[0].postback}>
                        Know more
                        {/* {props.payload.buttons[0].text} */}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Cards;