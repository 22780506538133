import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

function PublicRoutes({ component: Component, ...rest }) {
  const userInfo = useSelector((state) => state.userLogin);

  const { accessToken } = userInfo;
  return (
    <Route
      {...rest}
      render={(props) =>
        !accessToken ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/signin', state: { from: props.location } }}
          />
        )
      }
    />
  );
}

export default PublicRoutes;
