import React, {useState} from 'react';
import {
    Container,
    Grid,
    makeStyles,
    Paper
} from '@material-ui/core';
import NewsLetter from './NewsLetter';
import AlertNotificationMethods from './AlertNotificationMethods';
import AlertNotificationTypes from './AlertNotificationTypes';
import OtherDetails from './OtherDetails';

const useStyles = makeStyles((theme) => ({

}));

const Other = () => {
    const classes = useStyles();
    const [step, setStep] = useState(0);
    const [showDetails, setShowDetails] = useState(false);

    const completeInvestment = () => {
        setShowDetails(true)
    }

    const newsLetterComplete = () => setStep(1);

    const alertMethodComplete = () => setStep(2);

    const goToNewsLetter = () => setStep(0);

    const goToAlertMethods = () => setStep(1);

    return(
        <div>
            {(showDetails === false && step === 0) && <NewsLetter completeStep1={newsLetterComplete} />}
            {(showDetails === false && step === 1) && <AlertNotificationMethods previousStep1={goToNewsLetter} completeStep2={alertMethodComplete} />}
            {(showDetails === false && step === 2) && <AlertNotificationTypes previousStep2={goToAlertMethods}  finishInvest={completeInvestment} />}
            
            {(showDetails) && <OtherDetails />}
            <div className="mt-4">
                <progress id="progressBar" value="50" max="100"></progress>
            </div>
        </div>
    )
}

export default Other;

