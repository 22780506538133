import axios from 'axios';
import { getBaseUrl } from '../../utils/request';
import {
  FETCH_PROPERTIES_FAIL,
  FETCH_PROPERTIES_REQUEST,
  FETCH_PROPERTIES_SUCCESS,
  FETCH_PROPERTY_DETAILS_REQUEST,
  FETCH_PROPERTY_DETAILS_SUCCESS,
  FETCH_PROPERTY_DETAILS_FAIL,
  ORDER_PRODUCTS_BY_TYPE,
  FETCH_CURATED_PROPERTIES_FAIL,
  FETCH_CURATED_PROPERTIES_REQUEST,
  FETCH_CURATED_PROPERTIES_SUCCESS,
} from './properties.types';

// curl -X POST "https://server.realto.estate/api/v2/users/login" -H "accept: application/json" -H "Content-Type: application/json" -d "{ \"email\": \"string\", \"password\": \"string\"}"
export const fetchProperties = (page = 1, filters = {}, keyword = '') => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({
      type: FETCH_PROPERTIES_REQUEST,
    });
    const token = getState().userLogin.userInfo.accessToken;

    let params = {};

    params = {
      per_page: 20,
      filters: filters,
      keyword: keyword,
    };

    // if (keyword.length !== 0) {
    //   params = {
    //     per_page: 8,
    //     keyword: keyword,
    //   };
    // } else if (keyword.length !== 0 && filters !== undefined) {
    //   params = {
    //     keyword: keyword,
    //     per_page: 8,
    //     page: page,
    //     filters: filters,
    //   };
    // } else if (filters !== undefined) {
    //   params = {
    //     per_page: 8,
    //     page: page,
    //     filters: filters,
    //   };
    // } else {
    //   params = {
    //     per_page: 8,
    //     page: page,
    //   };
    // }
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      params: params,
    };
    const { data } = await axios.get(
      getBaseUrl('property'),

      config,
    );
    dispatch({
      type: FETCH_PROPERTIES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_PROPERTIES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const fetchPropertyDetails = (property) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({
      type: FETCH_PROPERTY_DETAILS_REQUEST,
    });
    const token = getState().userLogin.userInfo.accessToken;

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios.get(
      getBaseUrl('property/'+property),
     
      config,
    );
    dispatch({
      type: FETCH_PROPERTY_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_PROPERTY_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// sorting products

export const sortProducts = (items, sort) => (dispatch) => {
  // const products = items.slice();
  const products = [...items];

  if (sort !== 'recent') {
    if (sort === 'minimuminvestment') {
      console.log('🐨🐨');
      console.log('MIN INVESTMENT');
      products.sort(function(a,b){
        console.log(a.token.minimumInvestment - b.token.minimumInvestment) 
      })
      // products.map((product) =>
      //   console.log(` ${product.name} ${product.token.minimumInvestment}`),
      // );
      // products.sort(
      //   (a, b) => a.token.minimumInvestment > b.token.minimumInvestment,
      // );
      // console.log('⚡');
      
      // products.map((product) =>
      //   console.log(` ${product.name} ${product.token.minimumInvestment}`),
      // );
      console.log('🔥🔥');
    } else if (sort === 'irr') {
      console.log(products)
      products.sort((a,b)=>{
       return ( a.roi.IRR - b.roi.IRR)
      })
      // console.log('sorted'+products)
      // console.log('🐨🐨');
      // console.log('IRR');
      // products.map((product) =>
      //   console.log(` ${product.name} ${product.roi.IRR}`),
      // );
      // products.sort((a, b) => a.roi.IRR < b.roi.IRR);
      // console.log('⚡');
      // products.map((product) =>
      //   console.log(` ${product.name} ${product.roi.IRR}`),
      // );
      // console.log('🔥🔥');
    } else if (sort === 'averagecash') {
      console.log('🐨🐨');
      console.log('AVG CASH');
      products.map((product) =>
        console.log(` ${product.name} ${product.roi.averageCashYield}`),
      );
      products.sort((a, b) => a.roi.averageCashYield < b.roi.averageCashYield);
      console.log('⚡');
      products.map((product) =>
        console.log(` ${product.name} ${product.roi.averageCashYield}`),
      );
      console.log('🔥🔥');

      console.log(products);
    } else if (sort === 'minimumholdperiod') {
      console.log('🐨🐨');
      console.log('MIN HOLD');
      products.map((product) =>
        console.log(` ${product.name} ${product.roi.holdPeriod}`),
      );
      products.sort((a, b) => a.roi.holdPeriod > b.roi.holdPeriod);
      console.log('⚡');
      products.map((product) =>
        console.log(` ${product.name} ${product.roi.holdPeriod}`),
      );
      console.log('🔥🔥');
    }
  }
  dispatch({
    type: ORDER_PRODUCTS_BY_TYPE,
    payload: {
      sort: sort,
      items: products,
    },
  });
};

export const fetchCuratedProperties = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: FETCH_CURATED_PROPERTIES_REQUEST,
    });
    const token = getState().userLogin.userInfo.accessToken;

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios.get(
      getBaseUrl('properties/suggestions'),
    
      config,
    );

    dispatch({
      type: FETCH_CURATED_PROPERTIES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_CURATED_PROPERTIES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
