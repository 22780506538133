import {
  FETCH_PROPERTIES_FAIL,
  FETCH_PROPERTIES_REQUEST,
  FETCH_PROPERTIES_SUCCESS,
  FETCH_PROPERTY_DETAILS_REQUEST,
  FETCH_PROPERTY_DETAILS_SUCCESS,
  FETCH_PROPERTY_DETAILS_FAIL,
  ORDER_PRODUCTS_BY_TYPE,
  FETCH_CURATED_PROPERTIES_FAIL,
  FETCH_CURATED_PROPERTIES_REQUEST,
  FETCH_CURATED_PROPERTIES_SUCCESS,
} from './properties.types';
const initialState = {
  properties: [],
  loading: false,
  error: '',
  hasMore: true,
};
export const fetchPropertiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROPERTIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PROPERTIES_SUCCESS:
      return {
        ...state,
        loading: false,
        properties: [...action.payload.properties],
        hasMore: action.payload.properties.length === 8,
      };
    case FETCH_PROPERTIES_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const fetchPropertyDetailsReducer = (
  state = { property: {}, loading: false, error: '' },
  action,
) => {
  switch (action.type) {
    case FETCH_PROPERTY_DETAILS_REQUEST:
      return { ...state, loading: true };
    case FETCH_PROPERTY_DETAILS_SUCCESS:
      return { loading: false, property: action.payload };
    case FETCH_PROPERTY_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

const initState = { items: [], filteredItems: [], sort: '' };
export const sortPropertyReducer = (state = initState, action) => {
  switch (action.type) {
    case ORDER_PRODUCTS_BY_TYPE:
      return {
        ...state,
        filteredItems: action.payload.items,
        sort: action.payload.sort,
      };

    default:
      return state;
  }
};

export const fetchCuratedPropertiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CURATED_PROPERTIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CURATED_PROPERTIES_SUCCESS:
      return {
        ...state,
        loading: false,
        properties: [...action.payload],
      };
    case FETCH_CURATED_PROPERTIES_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
