import React, { useState, useEffect } from 'react';
import { Container, Grid, makeStyles, Paper } from '@material-ui/core';
import './../Dashboard/dashboard.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import SelectDropDown from './../Dashboard/components/SelectDropDown';
import AllTransactions from './Wallet/AllTransactions';
import RentReturn from './Wallet/RentReturn';
import Investments from './Wallet/Investments';
import Deposits from './Wallet/Deposits';
import Withdrawals from './Wallet/Withdrawals';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import {getBaseUrl} from '../../utils/request';

const Tabs = (props) => {
  const [selected, setSelected] = useState(props.selected || 0);

  const handleChange = (index) => {
    setSelected(index);
  };

  return (
    <div>
      <div className="line"></div>
      <ul className="inline">
        {props.children.map((elem, index) => {
          let style = index == selected ? 'selected' : '';
          return (
            <li
              className={style}
              key={index}
              onClick={() => handleChange(index)}
            >
              {elem.props.title}
            </li>
          );
        })}
      </ul>
      <div className="tab p-0">{props.children[selected]}</div>
    </div>
  );
};

const Panel = (props) => {
  return <div>{props.children}</div>;
};

const propertyData = [
  {
    value: 'Prestige Sky High',
    label: 'Prestige Sky High',
  },
  {
    value: 'Shoba Sea View',
    label: 'Shoba Sea View',
  },
];

const yearData = [
  { value: '3 months', label: '3 months' },
  { value: '6 months', label: '6 months' },
  { value: '3 years', label: '3 years' },
  { value: '6 years', label: '6 years' },
];

const earningList = [
  { value: 'fiat', label: 'fiat' },
  { value: 'BTC', label: 'BTC' },
];

function createData(data1, data2, data3, data4, data5, data6, data7, data8) {
  return { data1, data2, data3, data4, data5, data6, data7, data8 };
}

const rows = [
  createData(
    '09 JAN 2020',
    'Prestige Sky High',
    '100',
    '$20,000',
    '$20,000',
    '$20,000',
    '$20,000',
    '$20,000',
  ),
  createData(
    '09 JAN 2020',
    'Shoba Sea View',
    '100',
    '$20,000',
    '$20,000',
    '$20,000',
    '$20,000',
    '$20,000',
  ),
];

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
  },
  margin: {
    height: theme.spacing(2),
  },
  marginTop: {
    marginTop: theme.spacing(4),
  },
  tilePaperCls: {
    width: '100%',
    padding: 20,
    marginBottom: 20,
  },
  gridBorder: {
    borderRight: '5px solid #F8F9FC',
  },
  table: {
    minWidth: 650,
  },
  tableHeaderCls: {
    background: '#FFF3E7 0% 0% no-repeat padding-box',
    borderRadius: 10,
  },
  tableHeader: {
    padding: '32px 20px 10px 20px',
    position: 'relative',
  },
  tableData: {
    padding: 25,
  },
  tableTotalCls: {
    background: '#FFF3E7 0% 0% no-repeat padding-box',
    borderRadius: 10,
    padding: '6px 10px',
  },
  chartPaper: {
    padding: 10,
  },
}));

const MyWallet = () => {
  const classes = useStyles();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [walletData, setWalletData] = useState(null);

  const getWalletData = (data) => {
    console.log('data', data);
    const config = {
      headers: {
        Authorization: 'Bearer ' + userInfo.accessToken,
        'Content-Type': 'application/json',
      },
    };
    axios
      .get(getBaseUrl('wallet/')+ data.label, config)
      .then((res) => {
        console.log('res', res);
        setWalletData(res.data[0]);
      });
  };

  const getWalletHistory = () => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + userInfo.accessToken,
        'Content-Type': 'application/json',
      },
    };
    axios
      .get(getBaseUrl('wallet/history'), config)
      .then((res) => {
        console.log('res', res);
      });
  };

  useEffect(() => {
    getWalletHistory();
  }, []);

  return (
    <div>
      <Paper className={classes.tilePaperCls}>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={10}>
            <label className="dashboardTileCls pl-3">Wallet Summary</label>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={2}
            className="d-flex justify-content-end"
          >
            <SelectDropDown
              dataDetails={earningList}
              sendWallet={getWalletData}
              getIncome={getWalletData}
            />
          </Grid>
        </Grid>
        <Grid
          container
          style={{ textAlign: 'center', background: '#FFF9E8' }}
          className="mt-4 p-4"
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            className={classes.gridBorder}
          >
            <label className="dashboardTileCls">My Balance </label>
            <br />
            <label className="dashboardTileValueCls" style={{ color: '#000' }}>
              $ {walletData ? walletData.balance : 0}
            </label>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            className={classes.gridBorder}
          >
            <label className="dashboardTileCls">Total Earned</label>
            <br />
            <label className="dashboardTileValueCls" style={{ color: '#000' }}>
              ${walletData ? walletData.earned : 0}
            </label>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            className={classes.gridBorder}
          >
            <label className="dashboardTileCls">Total Withdrawn </label>
            <br />
            <label className="dashboardTileValueCls" style={{ color: '#000' }}>
              ${walletData ? walletData.withdrawn : 0}
            </label>
          </Grid>
        </Grid>
      </Paper>
      <Paper className={classes.tilePaperCls}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <button className="btnYellowBorder" style={{ width: '100%' }}>
              Deposit Money
            </button>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <button className="btnYellowBorder" style={{ width: '100%' }}>
              Withdraw Money
            </button>
          </Grid>
        </Grid>
      </Paper>

      <Paper className={classes.tilePaperCls}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <h3>Payment Methods</h3>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <p className="text-right socialMediaCls">
              {' '}
              + Add New Payment Method
            </p>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <h6 className="mb-0">PayPal</h6>
            <p>sarahabraham@gmail.com</p>
            <h6 className="mb-0">PayPal</h6>
            <p>sarahabraham@gmail.com</p>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={2}>
            <button className="btnYellowBorder" style={{ width: '100%' }}>
              Delete
            </button>
            <button className="btnYellowBorder mt-3" style={{ width: '100%' }}>
              Delete
            </button>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={2}>
            <button
              className="btnYellowBorder"
              style={{ width: '100%', color: '#2C5BC8', background: '#FFF9E8' }}
            >
              Prefered
            </button>
            <button className="btnYellowBorder mt-3" style={{ width: '100%' }}>
              Set as preferred
            </button>
          </Grid>
        </Grid>
      </Paper>

      <p className="dashboardTileCls pt-3">Total return table</p>
      <Tabs selected={0}>
        <Panel title="All Transactions">
          <AllTransactions />
        </Panel>
        <Panel title="RentReturn">
          <RentReturn />
        </Panel>
        <Panel title="Investments">
          <Investments />
        </Panel>
        <Panel title="Deposits">
          <Deposits />
        </Panel>
        <Panel title="Withdrawals">
          <Withdrawals />
        </Panel>
      </Tabs>
    </div>
  );
};

export default MyWallet;
