// First filter property type in here
export const propertyType = [
  // {
  //   id: 0,
  //   name: 'All',
  // },

  {
    id: 3,
    name: 'Land',
  },
  {
    id: 20,
    name: 'Commercial',
  },
  {
    id: 4,
    name: 'MedicalOffice',
  },

 
];
// Second filter IRR , four or five , it's within range
export const IRRType = [
  // {
  //   id: 100,
  //   name: 'All',
  //   IRR: { minimum: 0, maximum: 100 },
  // },
  {
    id: 0,
    name: '< 12.0%',
    IRR: { minimum: 0, maximum: 12 },
  },
  {
    id: 1,
    name: '12.0%-16.00%',
    IRR: { minimum: 12, maximum: 16 },
  },
  {
    id: 2,
    name: '16.00%-22.0%',
    IRR: { minimum: 16, maximum: 22 },
  },
  {
    id: 3,
    name: '>22.0%',
    IRR: { minimum: 22, maximum: 100 },
  },
];
// Third filter type cash yield
export const AverageCashYieldType = [
  // {
  //   id: 200,
  //   name: 'All',
  //   AverageCashYield: { minimum: 0, maximum: 100 },
  // },
  {
    id: 201,
    name: '< 5.0%',
    AverageCashYield: { minimum: 0, maximum: 5 },
  },
  {
    id: 202,
    name: '5.0%-7.00%',
    AverageCashYield: { minimum: 5, maximum: 7 },
  },
  {
    id: 203,
    name: '7.00%-10.0%',
    AverageCashYield: { minimum: 7, maximum: 10 },
  },
  {
    id: 204,
    name: '>10.0%',
    AverageCashYield: { minimum: 10, maximum: 100 },
  },
];
// fourth filter type minimum investment
export const MinInvestmentType = [
  // {
  //   id: 300,
  //   name: 'All',
  //   investment: { minimum: 0, maximum: 100000000 },
  // },
  {
    id: 301,
    name: '<=$10,000',
    investment: { minimum: 0, maximum: 10000 },
  },
  {
    id: 302,
    name: '<=$25,000',
    investment: { minimum: 0, maximum: 25000 },
  },
  {
    id: 303,
    name: '<=$50000',
    investment: { minimum: 0, maximum: 50000 },
  },
  {
    id: 304,
    name: '>=$50000',
    investment: { minimum: 50000, maximum: 1000000000 },
  },
];
// fifth filter type minimumhold period
export const MinHoldType = [
  // {
  //   id: 0,
  //   name: 'All',
  //   years: { minimum: 0, maximum: 100 },
  // },
  {
    id: 1,
    name: '0-2 years',
    years: { minimum: 0, maximum: 2 },
  },
  {
    id: 2,
    name: '2-5 years',
    years: { minimum: 2, maximum: 5 },
  },
  {
    id: 3,
    name: '5-10 years',
    years: { minimum: 5, maximum: 10 },
  },
  {
    id: 4,
    name: '> 10 years',
    years: { minimum: 10, maximum: 100 },
  },
];
