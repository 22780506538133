import React, {useState} from 'react';
import {
    Container,
    Grid,
    makeStyles,
    Paper
  } from '@material-ui/core';
import SideBar from './../../components/SideBar';
import './dashboard.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import MyDocuments from './components/RealDocs/MyDocuments';
import Agreements from './components/RealDocs/Agreements';
import Tax from './components/RealDocs/Tax';
import Rental from './components/RealDocs/Rental';
import MyUploads from './components/RealDocs/MyUploads';
import ModalDialog from './components/Dialog/index';
import Providers from './components/AuthorizedUsers/Providers';
import Received from './components/AuthorizedUsers/Received';


  const Tabs = (props) => {

    const [selected, setSelected] = useState(props.selected || 0)
  
    const handleChange = (index) => {
      setSelected(index);
    }
  
      return (
      <div>
      <div className="line" style={{width: 'calc(100% - 352px)', marginLeft: "373px"}}></div>
        <ul className="inline">
          {props.children.map((elem,index)=>{
            let style = index == selected ? 'selected': '';
           return <li className={style} key={index} onClick={() => handleChange(index)}>{elem.props.title}</li>
          })}
        </ul>
        <div className="tab p-0">{props.children[selected]}</div>
      </div>
      )
  }
  
  const Panel = (props) => {
      return <div>{props.children}</div>
  }


  function createData(data1, data2, data3, data4, data5, data6, data7, data8) {
    return { data1, data2, data3, data4, data5, data6, data7, data8 };
  }
  
  const rows = [
    createData('09 JAN 2020', 'Prestige Sky High', '100', '$20,000', '$20,000', '$20,000', '$20,000', '$20,000'),
    createData('09 JAN 2020', 'Shoba Sea View', '100', '$20,000', '$20,000', '$20,000', '$20,000', '$20,000')
  ];

  const useStyles = makeStyles((theme) => ({
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      marginTop: theme.spacing(7),
      marginBottom: theme.spacing(7),
    },
    margin: {
      height: theme.spacing(2),
    },
    marginTop: {
      marginTop: theme.spacing(4),
    },
    tilePaperCls: {
        width: '100%',
        padding: 20,
        marginBottom: 20
    },
    gridBorder: {
        borderRight: '5px solid #F8F9FC'
    },
    chartPaper: {
        padding: 10
    },
    docTitle: {
        border: '2px solid #C5CACE',
        padding: '10px 20px',
        borderRadius: 4,
        marginBottom: 10
    }
  }));
  



const DashboardAuthUsers = () => {
    const classes = useStyles();


    return(
        <div>
        <Paper className={classes.tilePaperCls}>
        <Grid container className="mb-3">
            <Grid item xs={12} sm={12} md={6} lg={10}>
            <label className="dashboardTileCls pl-3">Authorised Users</label>
            </Grid>
        </Grid>


        <Tabs selected={0}>
            <Panel title="Authorisations Provided"><Providers /></Panel>
            <Panel title="Authorisations received"><Received /></Panel>
        </Tabs>
        </Paper>
    </div>
    )
}


export default DashboardAuthUsers;