import React, {useState} from 'react';
import {
    Container,
    Grid,
    makeStyles,
    Paper
} from '@material-ui/core';
import Select from 'react-select';

const options = [{"value":"s","label":"Small"},{"value":"m","label":"Medium"},{"value":"l","label":"Large"}];


const useStyles = makeStyles((theme) => ({

}));

const newsLetterData = ["None", "Mail", "Whatsapp", "SMS"]

const NewsLetter = (props) => {
    const classes = useStyles();
    return(
        <div>
            <h3 className="mt-4">Newsletter</h3>
            <p>How would you like to receive newsletters from fractional shop? Please select all preferred methods. If you don’t want to receive any newsletter please select “None”.</p>
            
            <div style={{position: 'relative'}}>
            {(newsLetterData.map((data, id) => {
                if(id === 0){
                    return(
                        <label key={id} className="locationBorderSelectedBox">{data}</label>
                    )
                }else{
                    return(
                        <label key={id} className="locationBorderBox">{data}</label>
                    )
                }
            }))}
            </div>
            <Grid container spacing={4} className="mt-5">
                <Grid item xs={12} sm={12} md={2} lg={2}>
                    <button className="btnYellowBorder" style={{width: "100%", background: "#F5F5F5", border: "none"}}>Back</button> 
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                    <button className="btnYellowBorder" style={{width: "100%"}} onClick={() => props.completeStep1()}>Next</button>
                </Grid>
            </Grid>
            
        </div>
    )
}

export default NewsLetter;

