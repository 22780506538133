import React, { useState, useEffect } from 'react';
import {
    Container,
    Grid,
    makeStyles,
    Paper
  } from '@material-ui/core';
import './../../pages/Dashboard/dashboard.css';
import ReactCodeInput from 'react-code-input';
import ChangePassword from './ChangePassword'

const useStyles = makeStyles((theme) => ({
    detailsText: {
        width: '100%',
        border: '1px solid #C5CACE',
        borderRadius: 10,
        padding: 16
    }
}));

const Verification = (props) => {
    const classes = useStyles();
    const [toggleStart, setToggleStart] = useState(true);
    const [toggleCode, setToggleCode] = useState(false);
    const [toggleDoc, setToggleDoc] = useState(false);
    const [togglePassword, setTogglePassword] = useState(false);
    const [togglePasswordCongrats, setTogglePasswordCongrats] = useState(false);

    const verificationCode = (data) => {
        setToggleCode(true);
        setToggleStart(false);
        setToggleDoc(false);
    }

    const goBackStepOne = () => {
        setToggleCode(false);
        setToggleStart(true);
        setToggleDoc(false);
    }

    const propsCls = {
        inputStyle: {
          fontFamily: 'monospace',
          margin:  '4px',
          MozAppearance: 'textfield',
          width: '90px',
          height: '120px',
          borderRadius: '3px',
          fontSize: '40px',
          paddingLeft: '7px',
          color: '#000',
          border: '1px solid #C5CACE',
          background: '#FFFFFF',
          textAlign: 'center'
        }
    }

    const verifyCode = () => {
        setToggleCode(false);
        setToggleStart(false);
        setToggleDoc(false);
        setTogglePassword(true);
    }

    const toggleCheckPassword = () => {
        setToggleCode(false);
        setToggleStart(false);
        setToggleDoc(false);
        setTogglePassword(false);
        setTogglePasswordCongrats(true);
    }

    const closeAll = () => {
        props.closeAllModal();
    }

    // useEffect(() => {
    //     setToggleCode(props.openDialog)
    // }, [props])

    return(
        <div>
           
        {(toggleCode) &&
        <div className="p-5">
            <img src="/assets/icons/arrow-left.svg" onClick={goBackStepOne} alt="arrow" width="20px" />
            <h3 className="mt-3">Verify Code Sent</h3>
            <p>Verification code has sent to your Phone: +91-99XXXXX91</p>
            <p className="mb-2">Enter Code</p>
            <div className="custom-styles">
                <ReactCodeInput fields={4} type="password" {...propsCls} />
            </div>
            <Grid container spacing={4} className="mt-3 mb-1">
                <Grid item xs={12} sm={12} md={6} lg={12}>
                    <button className="btnYellowBorder p-3" style={{width: "100%"}} onClick={verifyCode}>Submit</button>
                </Grid>
            </Grid>
            <p>Didn’t get a code?</p>
        </div>}

        {(toggleStart) &&
         <div className="p-5">
         <img src="/assets/icons/arrow-left.svg" alt="arrow" width="20px" />
         <h3 className="mt-3">Let’s Verify It’s You</h3>
         <p>Choose a verification method so we can make sure it’s you.</p>
         <Grid container spacing={4} className="mt-3 mb-4">
             <Grid item xs={12} sm={12} md={6} lg={6}>
                 <button className="btnYellowBorder" style={{width: "100%"}} onClick={() => verificationCode("sms")} >SMS Verification</button>
             </Grid>
             <Grid item xs={12} sm={12} md={6} lg={6}>
                 <button className="btnYellowBorder" style={{width: "100%"}} onClick={() => verificationCode("email")}>Email Verification</button>
             </Grid>
         </Grid>
     </div>}
     {(toggleDoc) && 
     <div className="p-5">
     <img src="/assets/icons/arrow-left.svg" alt="arrow" width="20px" />
     <h3 className="mt-3">Sign Document</h3>
     <p>You are about to sign the document “Investment Agreement”</p>
     <Grid container spacing={4} className="mt-3 mb-4">
         <Grid item xs={12} sm={12} md={6} lg={3}>
             <button className="btnYellowBorder" style={{width: "100%"}}>SignIn</button>
         </Grid>
         <Grid item xs={12} sm={12} md={6} lg={3}>
             <button className="btnYellowBorder" style={{width: "100%", border: 'none', background: '#F5F5F5'}}>Cancel</button>
         </Grid>
     </Grid>
    </div>}
    {(togglePassword) && 
     <ChangePassword passwordChanged={toggleCheckPassword} />}
    {(togglePasswordCongrats) && 
     <div className="p-5">
     <h3 className="mt-3">Password Changed!</h3>
     <p>You have successfully changed your password</p>                
     <Grid container spacing={4} className="mt-3 mb-4">
         <Grid item xs={12} sm={12} md={12} lg={3}>
             <button className="btnYellowBorder" style={{width: "100%"}} onClick={closeAll}>Close</button>
         </Grid>
     </Grid>
    </div>}
</div>    
    )
}

export default Verification;



