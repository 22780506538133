import React, { useState, useEffect } from 'react';
import { Container, Grid, makeStyles, Paper } from '@material-ui/core';
import './dashboard.css';
import InvestmentTable from './components/InvestmentTable';
import InvestmentDetails from './components/InvestmentDetails';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {currConv} from '../../services/currency';
import {getBaseUrl} from '../../utils/request'
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const data = [
  {
    name: 'Jan',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Feb',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Mar',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Apr',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'May',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Jun',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Jul',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
];

const propertyData = [
  {
    value: 'Prestige Sky High',
    label: 'Prestige Sky High',
  },
  {
    value: 'Shoba Sea View',
    label: 'Shoba Sea View',
  },
];

const Tabs = (props) => {
  const [selected, setSelected] = useState(props.selected || 0);

  const handleChange = (index) => {
    setSelected(index);
  };

  return (
    <div>
      <ul className="inline">
        {props.children.map((elem, index) => {
          let style = index == selected ? 'selected' : '';
          return (
            <li
              className={style}
              key={index}
              onClick={() => handleChange(index)}
            >
              {elem.props.title}
            </li>
          );
        })}
      </ul>
      <div className="tab p-0">{props.children[selected]}</div>
    </div>
  );
};

const Panel = (props) => {
  return <div>{props.children}</div>;
};

const yearData = [
  { value: '3 months', label: '3 months' },
  { value: '6 months', label: '6 months' },
  { value: '3 years', label: '3 years' },
  { value: '6 years', label: '6 years' },
];

const earningList = [
  { value: 'current', label: 'current' },
  { value: 'sold', label: 'sold' },
];

function createData(data1, data2, data3, data4, data5, data6, data7) {
  return { data1, data2, data3, data4, data5, data6, data7 };
}

const rows = [
  createData(
    '09 JAN 2020',
    'Prestige Sky High',
    '100',
    '$20,000',
    'Joseph Tom',
    'In Process',
    'Download',
  ),
  createData(
    '09 JAN 2020',
    'Shoba Sea View',
    '100',
    '$20,000',
    'Joseph Tom',
    'Active',
    'Download',
  ),
];

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
  },
  margin: {
    height: theme.spacing(2),
  },
  marginTop: {
    marginTop: theme.spacing(4),
  },
  tilePaperCls: {
    width: '100%',
    padding: 20,
    marginBottom: 20,
  },
  gridBorder: {
    borderRight: '5px solid #F8F9FC',
  },
  cardCls: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 0px 30px #0000001A',
    borderRadius: 10,
    padding: 20,
  },
}));

const DashboardInvestment = () => {
  const classes = useStyles();
  const [toggleInvestment, setToggleInvestment] = useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [investmentData, setInvestmentData] = useState([]);
  const [propertyId, setPropertyId] = useState('');
  const [userId, setUserId] = useState('');

  const getEachInvestment = (propertyId) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + userInfo.accessToken,
        'Content-Type': 'application/json',
      },
      query: {
        page: 1,
        per_page: 10,
        type: 'active',
      },
    };
    axios
      .get(
      getBaseUrl(  'investment/summary/' + propertyId),
        config,
      )
      .then((res) => {
        console.log('res', res);
      });
  };

  const showDetails = (propertyId, userId) => {
    setToggleInvestment(true);
    setPropertyId(propertyId);
    setUserId(userId);
    getEachInvestment(propertyId);
  };

  const toggleDetails = () => setToggleInvestment(false);

  useEffect(() => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + userInfo.accessToken,
        'Content-Type': 'application/json',
      },
      query: {
        page: 1,
        per_page: 10,
        type: 'active',
      },
    };
    console.log('config', config);
    axios
      .get(getBaseUrl('investment'), config)
      .then((res) => {
        console.log('res', res.data.data);
        setInvestmentData(res.data.data);
      });
  }, []);

  function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
    
}

const [userCountry, setuserCountry] = useState('');
  
useEffect(()=>{
fetch(getBaseUrl('users'),{
  headers:{
    Authorization: 'Bearer ' + userInfo.accessToken,
    'Content-Type': 'application/json',
  }
}).then((res)=>res.json()).then((data)=>setuserCountry(data.address.country)).catch((err)=>console.log(err));
},[])

  return (
    <div>
      {toggleInvestment ? (
        <InvestmentDetails
          propertyId={propertyId}
          userId={userId}
          goBack={toggleDetails}
        />
      ) : (
        <div>
          <Paper className={classes.tilePaperCls}>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={10}>
                <label className="dashboardTileCls pl-3">
                  Active Investments
                </label>
              </Grid>
            </Grid>
            <div className="activeInvestment">
              <Carousel responsive={responsive}>
                {investmentData.map((data, id) => {
                  return (
                    <div
                      className={classes.cardCls}
                      onClick={() => showDetails(data._id, data.userId)}
                      key={id}
                    >
                      <img src="/assets/icons/prestiege.svg" />
                      <label className="mb-0 pt-3">
                        {' '}
                      <span style={{fontWeight:"bold", fontSize:17, color:"black"}}>{data.propertyName}</span> <br /> Thane, Maharashtra, India
                      </label>
                      <hr />
                      <div>
                        <label>
                          Current Value <br /> <span style={{fontWeight:"bold", fontSize:17, color:"black"}}>{numberWithCommas(currConv(data.currentValue,userCountry))}</span>
                        </label>
                        <label className="float-right text-right">
                          Investment <br /> <span style={{fontWeight:"bold", fontSize:17, color:"black"}}>{numberWithCommas(currConv(data.payment.total,userCountry))}</span>
                        </label>
                      </div>
                      <div>
                        <label>
                          ROI <br /><span style={{fontWeight:"bold", fontSize:17, color:"black"}}>{numberWithCommas(currConv(data.roi,userCountry))}</span>
                          <span style={{ color: 'red', marginLeft:5 }}>-1.60%</span>
                        </label>
                        <label className="float-right text-right">
                          Number Of Fractions <br /><span style={{fontWeight:"bold", fontSize:17, color:"black"}}>{data.noOfTokens}</span>
                        </label>
                      </div>
                      <img
                        src="/assets/icons/red-graph.svg"
                        alt="graph"
                        width="100%"
                      />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </Paper>

          <Paper className={classes.tilePaperCls}>
            <p className="dashboardTileCls pt-3">Investment Details</p>

            <span style={{color:"#fbaf62", fontSize:18 }}>Active Investments</span>    
            <InvestmentTable />
            {/* <Tabs selected={0}>
              <Panel title="Active Investments">
                
              </Panel>
              <Panel title=""></Panel>
            </Tabs> */}
          </Paper>
        </div>
      )}
    </div>
  );
};

export default DashboardInvestment;
