import React, { useState } from 'react';
import { Container, Grid, makeStyles, Paper } from '@material-ui/core';
import 'react-circular-progressbar/dist/styles.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

function createData(data1, data2, data3, data4, data5, data6, data7, data8) {
  return { data1, data2, data3, data4, data5, data6, data7, data8 };
}

const rows = [
  createData(
    'Jan 13, 2020',
    'Prestige Sky High',
    'Tax Distribution Prestige Sky high 2020 March',
    'Tax',
    'Pending',
  ),
  createData(
    'Jan 13, 2020',
    'Prestige Sky High',
    'Tax Distribution Prestige Sky high 2020 March',
    'Tax',
    'Pending',
  ),
  createData(
    'Jan 13, 2020',
    'Prestige Sky High',
    'Tax Distribution Prestige Sky high 2020 March',
    'Tax',
    'Signed',
  ),
  createData(
    'Jan 13, 2020',
    'Prestige Sky High',
    'Tax Distribution Prestige Sky high 2020 March',
    'Tax',
    'Signed',
  ),
];

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
  },
  margin: {
    height: theme.spacing(2),
  },
  marginTop: {
    marginTop: theme.spacing(4),
  },
  tilePaperCls: {
    width: '100%',
    padding: 20,
    marginBottom: 20,
  },
  gridBorder: {
    borderRight: '5px solid #F8F9FC',
  },
  table: {
    minWidth: 650,
  },
  tableHeaderCls: {
    background: '#FFF3E7 0% 0% no-repeat padding-box',
    borderRadius: 10,
  },
  tableHeader: {
    padding: '16px',
    position: 'relative',
  },
  tableData: {
    padding: 15,
  },
  tableTotalCls: {
    background: '#FFF3E7 0% 0% no-repeat padding-box',
    borderRadius: 10,
    padding: '6px 10px',
  },
  chartPaper: {
    padding: 10,
  },
  statusCls: {
    background: '#f5f5f5 0% 0% no-repeat padding-box',
    borderRadius: 10,
    padding: 10,
    width: 100,
  },
  downloadBtn: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #FDC424',
    borderRadius: 10,
    padding: 10,
  },
}));

const AllTransactions = () => {
  const classes = useStyles();
  const [verifyModal, setVerifyModal] = useState(false);

  const toggleModal = () => setVerifyModal(true);

  const closeModalDownload = () => setVerifyModal(false);

  return (
    <div>
      <p className="dashboardTileCls pt-3">Transaction History</p>
      <TableContainer
        component={Paper}
        className="mt-4"
        style={{ boxShadow: 'none' }}
      >
        <Table className={classes.table} aria-label="simple table">
          <TableHead className={classes.tableHeaderCls}>
            <TableRow>
              <TableCell align="center" className={classes.tableHeader}>
                Date
              </TableCell>
              <TableCell align="center" className={classes.tableHeader}>
                Property Name
              </TableCell>
              <TableCell align="center" className={classes.tableHeader}>
                Document Tilte
              </TableCell>
              <TableCell align="center" className={classes.tableHeader}>
                Document Type
              </TableCell>
              <TableCell align="center" className={classes.tableHeader}>
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name}>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  className={classes.tableData}
                >
                  {row.data1}
                </TableCell>
                <TableCell align="center" className={classes.tableData}>
                  {row.data2}
                </TableCell>
                <TableCell align="center" className={classes.tableData}>
                  {row.data3}
                </TableCell>
                <TableCell align="center" className={classes.tableData}>
                  {row.data4}
                </TableCell>
                <TableCell align="center" className={classes.tableData}>
                  <label
                    className={classes.statusCls}
                    style={{
                      color: row.data6 == 'Pending' ? '#FE860E' : '#29292A',
                    }}
                  >
                    {row.data5}
                  </label>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AllTransactions;
