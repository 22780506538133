import React, { useState } from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Paper,
  Box,
  CardHeader,
  Avatar,
  Typography,
  Button,
  Hidden,
} from '@material-ui/core';
import SideBar from './../../components/SideBar';
import './account.css';
import AccountDetails from './AccountDetails';
import MyWallet from './MyWallet';
import MyPreferences from './MyPreferences';
import KYCVerification from './KYCVerification';
import ChangePassword from './ChangePassword';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    marginBottom: theme.spacing(7),
  },
}));

const Account = () => {
  const classes = useStyles();
  const [getMenu, setMenu] = useState('account');

  const getOptions = (data) => {
    console.log('data', data);
    setMenu(data);
  };
  return (
    <div style={{ background: '#F8F9FC' }}>
      <Container className={classes.container}>
        <Grid style={{ display: 'flex' }} container spacing={1}>
          <Hidden mdDown>
            <Grid item xs={12} sm={12} md={6} lg={2}>
              <SideBar sendOption={getOptions} />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={12} md={6} lg={10}>
            {getMenu === 'account' && <AccountDetails />}
            {getMenu === 'wallet' && <MyWallet />}
            {getMenu === 'preferences' && <MyPreferences />}
            {getMenu === 'verify' && <KYCVerification />}
            {getMenu === 'password' && <ChangePassword />}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Account;
