import React, { useState } from 'react';
import GoogleMap from 'google-map-react';

const Marker = ({ text }) => <div>{text}</div>;

const SimpleMap = ({ latitude, longitude, center }) => {
  console.log('🐨');
  console.log(latitude, longitude);
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <GoogleMap
        bootstrapURLKeys={{ key: 'AIzaSyB09gnOSgIPLJ3VzkWyarSrRv0H2GKbeeI' }}
        defaultCenter={{ lat: latitude, lng: longitude }}
        defaultZoom={16}
      >
        <Marker text="Marker" lat={latitude} lng={longitude} />
      </GoogleMap>
    </div>
  );
};

export default SimpleMap;
