import React, { useState } from 'react';
import { makeStyles, Grid, Box } from '@material-ui/core';

import ScheduleMeetingSlider from './ScheduleMeetingSlider';

const useStyles = makeStyles((theme) => ({
  boxCls: {
    background: '#FFF9E8 0% 0% no-repeat padding-box',
    borderRadius: 10,
    padding: '20px 50px',
  },
  cardCls: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: 10,
    padding: 12,
  },
}));

const ScheduleMeeting = () => {
  const classes = useStyles();

  return (
    <>
      <h3 className="text-center mt-5 mb-4">
        Schedule call with investment advisor
      </h3>
      <ScheduleMeetingSlider />
    </>
  );
};

export default ScheduleMeeting;
