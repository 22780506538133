import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Grid, Button, IconButton, Paper, Box } from '@material-ui/core';
// replace this icon from here
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { PropertyTypeFilter } from '../MarketPlaceFilters/PropertyTypeFilter';
import { IRRTypeFilter } from '../MarketPlaceFilters/IRRTypeFilter';
import { AverageCashYieldTypeFilter } from '../MarketPlaceFilters/AverageCashYieldTypeFilter';
import { MinimumInvestMentFilter } from '../MarketPlaceFilters/MinInvestMentFilter';
import { MinHoldTypeFilter } from '../MarketPlaceFilters/MinHoldTypeFilter';
import { LocationsFilter } from '../MarketPlaceFilters/LocationsFilter';
import SearchFilter from '../MarketPlaceFilters/SearchFilter';

const useStyles = makeStyles((theme) => ({
  marketPlaceContainer: {
    margin: theme.spacing(12, 'auto', 12),
  },

  filterContainer: {
    margin: theme.spacing(4, 0),
  },
  filtersParentContainer: {
    height: theme.spacing(9),
    overflow: 'hidden',
    '& > div > ul > li': {
      width: '210px !important',
    },
    '& > div > ul:first-child li': {
      // paddingLeft: '0 !important',
    },
  },
  propertyInfoIconText: {
    display: 'flex',
  },

  // card styles
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  tagsFilterContent: {
    padding: theme.spacing(1),
  },

  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },

  dropdownButton: {
    background: '#ffffff',
    margin: theme.spacing(1, 0.4),
    width: '180px',
    borderRadius: '10px !important',
    textTransform: 'capitalize',
    padding: theme.spacing(1, 2),
    border: '2px solid #C5CACE',
    color: 'black',
    '&:hover': {
      border: '2px solid #C5CACE',
      background: '#ffffff',
    },
  },
  dropdownButtonChecked: {
    border: '2px solid #FDC424',
    background: '#FDC424',
    '&:hover': {
      border: '2px solid #FDC424',
      background: '#FDC424',
    },
  },
  dropdownButtonUnchecked: {},
  arrowUp: {
    fill: 'white',
  },
  arrowDown: {
    fill: 'grey',
  },
  // replace this icon from here
  paper: {
    padding: theme.spacing(0.5, 2),
    background: 'transparent',
    border: '2px solid #C5CACE',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: theme.spacing(7),
  },
  input: {
    width: '100%',
    padding: '.2rem 0',
    marginLeft: theme.spacing(1),
  },
  searchIcon: {
    color: 'white',
  },
  displayFilters: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    // flexWrap: 'wrap',
    overflow: 'auto',
   
    flexGrow: '1',
  
  },
  hideFilters: {
    display: 'none',
  },
}));

const Index = ({ allFilters, handleAllFilters, setKeyword,onSearch }) => {
  const classes = useStyles();

  // useEffect(() => {
  //   const { type, roi, investment, holdPeriod } = allFilters;
  //   let updatedFilters = { type };
  // }, [allFilters]);

  const [countriesButton, setCountriesButton] = useState(false);
  const [showFilters, setShowFilters] = useState(true);

  const handleShowFilters = () => {
    setShowFilters((prev) => !prev);
    console.log('🐨');
  };

  const handleFilters = (filters, category) => {
    const newFilters = { ...allFilters };
    newFilters[category] = filters;
    console.log(newFilters);
    handleAllFilters(newFilters);
  };

  const [activeFilterType, setActiveFilterType] = useState('');
  const handleActiveFilterButton = (value) => {
    if (value !== activeFilterType) {
      setActiveFilterType(value);
    } else {
      setActiveFilterType('');
    }
  };

  // property Button is here

  const handleCountriesType = () => {
    setCountriesButton(!countriesButton);
  };
  return (
    <Grid
      container
      spacing={1}
      justify="space-evenly"
      className={classes.filterContainer}
    >
      <Grid container item justify="space-between">
       

            <div style={{width:"100%", display:"flex",flexDirection:"row", alignItems:"center", justifyContent:"space-between"}}>
            <SearchFilter onSearch={onSearch} />
              {/* <div>
                <input placeholder="AAlo" onChange={onSearch}/>
              </div> */}
            <Paper className={classes.paper} elevation={0}>
              <IconButton
                className={classes.setShowFiltersButton}
                disableFocusRipple
                disableRipple
                color="inherit"
                onClick={handleShowFilters}
              >
                <img src="/assets/icons/Icon feather-filter.svg" />
              </IconButton>
              {/* <CheckCircleIcon className={classes.searchIcon} color="inherit" /> */}
            </Paper>

            </div>
           
         
       
        <Grid
          item
          className={showFilters ? classes.displayFilters : classes.hideFilters}
        >
          <Button
            onClick={() => handleActiveFilterButton('property')}
            disableRipple
            style={{
              minWidth:180,
              borderWidth: 0.8 ,
            }}
            className={clsx(
              classes.dropdownButton,
              activeFilterType === 'property'
                ? classes.dropdownButtonChecked
                : classes.dropdownButtonUnchecked,
            )}
            variant="outlined"
            color="primary"
            endIcon={
              activeFilterType === 'property' ? (
                <ArrowDropUpIcon className={classes.arrowUp} />
              ) : (
                <ArrowDropDownIcon className={classes.arrowDown} />
              )
            }
          >
            Property Type
          </Button>

          <Button
            onClick={() => handleActiveFilterButton('IRR')}
            disableRipple
            style={{
              minWidth:180,
              borderWidth: 0.8 ,
        
            }}
            className={clsx(
              classes.dropdownButton,
              activeFilterType === 'IRR'
                ? classes.dropdownButtonChecked
                : classes.dropdownButtonUnchecked,
            )}
            variant="outlined"
            color="primary"
            endIcon={
              activeFilterType === 'IRR' ? (
                <ArrowDropUpIcon className={classes.arrowUp} />
              ) : (
                <ArrowDropDownIcon className={classes.arrowDown} />
              )
            }
          >
            IRR
          </Button>

          <Button
            onClick={() => handleActiveFilterButton('averageCashYield')}
            disableRipple
            style={{
              minWidth:180,
              borderWidth: 0.8 ,
            }}
            className={clsx(
              classes.dropdownButton,
              activeFilterType === 'averageCashYield'
                ? classes.dropdownButtonChecked
                : classes.dropdownButtonUnchecked,
            )}
            variant="outlined"
            color="primary"
            endIcon={
              activeFilterType === 'averageCashYield' ? (
                <ArrowDropUpIcon className={classes.arrowUp} />
              ) : (
                <ArrowDropDownIcon className={classes.arrowDown} />
              )
            }
          >
            Avg. Cash Yield
          </Button>
          <Button
            onClick={() => handleActiveFilterButton('investment')}
            disableRipple
            style={{
              minWidth:180,
              borderWidth: 0.8 ,
            }}
            className={clsx(
              classes.dropdownButton,
              activeFilterType === 'investment'
                ? classes.dropdownButtonChecked
                : classes.dropdownButtonUnchecked,
            )}
            variant="outlined"
            color="primary"
            endIcon={
              activeFilterType === 'investment' ? (
                <ArrowDropUpIcon className={classes.arrowUp} />
              ) : (
                <ArrowDropDownIcon className={classes.arrowDown} />
              )
            }
          >
            Min. Investment
          </Button>
          <Button
            onClick={() => handleActiveFilterButton('minHold')}
            disableRipple
            style={{
              minWidth:180,
              borderWidth: 0.8 ,
            }}
            className={clsx(
              classes.dropdownButton,
              activeFilterType === 'minHold'
                ? classes.dropdownButtonChecked
                : classes.dropdownButtonUnchecked,
            )}
            variant="outlined"
            color="primary"
            endIcon={
              activeFilterType === 'minHold' ? (
                <ArrowDropUpIcon className={classes.arrowUp} />
              ) : (
                <ArrowDropDownIcon className={classes.arrowDown} />
              )
            }
          >
            Min. Hold Period
          </Button>
          <Button
            onClick={() => handleActiveFilterButton('location')}
            disableRipple
            style={{
              minWidth:180,
              borderWidth: 0.8 ,
            }}
            className={clsx(
              classes.dropdownButton,
              activeFilterType === 'location'
                ? classes.dropdownButtonChecked
                : classes.dropdownButtonUnchecked,
            )}
            variant="outlined"
            color="primary"
            endIcon={
              activeFilterType === 'location' ? (
                <ArrowDropUpIcon className={classes.arrowUp} />
              ) : (
                <ArrowDropDownIcon className={classes.arrowDown} />
              )
            }
          >
            Location
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <FormGroup
          row
          className={classes.tagsFilterContent}
          style={{
            display: `${activeFilterType === 'property' ? 'block' : 'none'}`,
          }}
        >
          <PropertyTypeFilter
            handleFilters={(filters) => handleFilters(filters, 'type')}
          />
        </FormGroup>

        <FormGroup
          row
          className={classes.tagsFilterContent}
          style={{
            display: `${activeFilterType === 'IRR' ? 'block' : 'none'}`,
          }}
        >
          <IRRTypeFilter
            handleFilters={(filters) => handleFilters(filters, 'roi')}
          />
        </FormGroup>

        <FormGroup
          className={classes.tagsFilterContent}
          row
          style={{
            display: `${
              activeFilterType === 'averageCashYield' ? 'block' : 'none'
            }`,
          }}
        >
          <AverageCashYieldTypeFilter
            handleFilters={(filters) => handleFilters(filters, 'yield')}
          />
        </FormGroup>

        <FormGroup
          className={classes.tagsFilterContent}
          row
          style={{
            display: `${activeFilterType === 'investment' ? 'block' : 'none'}`,
          }}
        >
          <MinimumInvestMentFilter
            handleFilters={(filters) => handleFilters(filters, 'investment')}
          />
        </FormGroup>

        <FormGroup
          row
          className={classes.tagsFilterContent}
          style={{
            display: `${activeFilterType === 'minHold' ? 'block' : 'none'}`,
          }}
        >
          <MinHoldTypeFilter
            handleFilters={(filters) => handleFilters(filters, 'holdPeriod')}
          />
        </FormGroup>

        <FormGroup
          row
          className={classes.tagsFilterContent}
          style={{
            display: `${activeFilterType === 'location' ? 'block' : 'none'}`,
          }}
        >
          <LocationsFilter
            handleFilters={(filters) => handleFilters(filters, 'location')}
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
};

export default Index;
