import React, { Component } from 'react';
import { Typography, Box, Grid } from '@material-ui/core/';
import Icon from '@material-ui/core/Icon';
import Avatar from '@material-ui/core/Avatar';

import withStyles from '@material-ui/core/styles/withStyles';
import customImageInputStyle from './CustomImageInputStyle';
import classnames from 'classnames';

class UploadImage extends Component {
  constructor(props) {
    super(props);
    this.fileUpload = React.createRef();
    this.showFileUpload = this.showFileUpload.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
  }

  state = {
    file: undefined,
    imagePreviewUrl: undefined,
  };

  showFileUpload() {
    if (this.fileUpload) {
      this.fileUpload.current.click();
    }
  }
  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result,
        });
      };
      reader.readAsDataURL(file);
      this.props.setFieldValue(this.props.field.name, file);
    }
  }
  showPreloadImage() {
    const { errorMessage, classes } = this.props;
    const { name } = this.props.field;
    const { file, imagePreviewUrl } = this.state;

    let comp = null;

    if (errorMessage) {
      comp = (
        <Grid
          container
          alignItems="center"
          justify="space-between"
          spacing={2}
          onClick={this.showFileUpload}
          style={{ margin: '0 auto' }}
        >
          <Grid item xs={12} md={5}>
            <Box
              component="img"
              alt="select file"
              src="/assets/icons/reupload.svg"
              sx={{ height: 160 }}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <Box>
              <Typography gutterBottom variant="h5">
                Drop or Select {this.props.title} here
              </Typography>

              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ color: 'primary.main' }}
                >
                  File type must be *.jpeg, *.jpg, *.png, *.gif and of max size
                  15 MB
                </Typography>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      );
    } else if (file) {
      comp = (
        <img
          // className={classes.avatarThumb}
          style={{
            margin: '0 auto',
            width: '556px',
            height: 'calc(400px - 16px)',
          }}
          src={imagePreviewUrl}
          alt="..."
        />
      );
    } else {
      comp = (
        <Grid
          container
          alignItems="center"
          justify="space-between"
          spacing={2}
          onClick={this.showFileUpload}
          style={{ margin: '0 auto' }}
        >
          <Grid item xs={12} md={5}>
            <Box
              component="img"
              alt="select file"
              src="/assets/icons/account/document_upload.svg"
              sx={{ height: 160 }}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <Box>
              <Typography gutterBottom variant="h5">
                Drop or Select {this.props.title} here
              </Typography>

              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ color: 'primary.main' }}
                >
                  File type must be *.jpeg, *.jpg, *.png, *.gif and of max size
                  15 MB
                </Typography>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      );
    }
    return comp;
  }

  componentDidMount() {
    console.log(this.fileUpload.current);
  }

  render() {
    const { errorMessage, title, classes } = this.props;
    const { name, onBlur } = this.props.field;

    const avatarStyle = classnames(
      classes.bigAvatar,
      this.state.file ? [classes.whiteBack] : [classes.primaryBack],
      { [classes.errorBack]: errorMessage },
    );
    console.log(':koala:');
    console.log(this.props);
    console.log(':fire:');

    return (
      <div style={{ margin: '0 auto' }}>
        <input
          className={classes.hidden}
          id={name}
          name={name}
          type="file"
          onChange={this.handleImageChange}
          ref={this.fileUpload}
          // onBlur={onBlur}
          //className="form-control"
        />
        <Grid
          container
          alignItems="center"
          justify="space-between"
          spacing={2}
          onClick={this.showFileUpload}
          style={{ margin: '0 auto' }}
        >
          {this.showPreloadImage()}
          {/* <Grid item xs={12} md={5}>
            <Box
              component="img"
              alt="select file"
              src="/assets/icons/account/document_upload.svg"
              sx={{ height: 160 }}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <Box>
              <Typography gutterBottom variant="h5">
                Drop or Select file
              </Typography>

              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ color: 'primary.main' }}
                >
                  File type must be *.jpeg, *.jpg, *.png, *.gif and of max size
                  15 MB
                </Typography>
              </Typography>
            </Box>
          </Grid> */}
        </Grid>

        {/* <Avatar className={avatarStyle} onClick={this.showFileUpload}>
          {this.showPreloadImage()}
        </Avatar> */}
        <Box p={4} mx="auto">
          {errorMessage ? (
            <Typography variant="h5" color="error" align="center" gutterBottom>
              {errorMessage}
            </Typography>
          ) : null}
        </Box>
        {/* <Typography className={classes.title} variant="h5">
          {title}
        </Typography>
        <Avatar className={avatarStyle} onClick={this.showFileUpload}>
          {this.showPreloadImage()}
        </Avatar>

        {errorMessage ? (
          <Typography variant="caption" color="error">
            {errorMessage}
          </Typography>
        ) : null} */}
      </div>
    );
  }
}

export default withStyles(customImageInputStyle)(UploadImage);
