import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ChatBot from 'react-simple-chatbot';
import { request } from '../../utils/request';
import Message from "./Message";
import Card from "./Cards";
import {connect} from 'react-redux'
import QuickReplies from "./QuickReplies";



 export class ProcessMessage extends Component {
    messagesEnd;
    constructor(props) {
        super(props);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleQuickReplyPayload = this.handleQuickReplyPayload.bind(this);
        this.state = {
            messages: [],
            showBot: true,
            shopWelcomeSent: false
        };
    }

    componentWillMount() {
        // const input = {
        //     type: 'user',
        //     sessionId: this.props.sessionId,
        //     msg: this.props.inputMessage,
        // };
        let newText = this.props.inpmsg;
        let text = localStorage.getItem('term');
        const checker = localStorage.getItem('term')
     
        console.log(newText)
        console.log("text");
        console.log(text);
        let says = {
            speaks: "me",
            msg: {
                text: {
                    text:  text
                },
                payload: {}
            }
        };
        // this.setState({
        //     messages: [...this.state.messages, says]
        // });
        // try {
        // let res = await 
        request({ url: 'chatbot', method: 'POST', data: { message: text }, auth: true }).then(res => {
            console.log(res);
            for (let msg of res.data[0].queryResult.fulfillmentMessages) {
                if (msg.platform === "PLATFORM_UNSPECIFIED") {
                    says = {
                        speaks: "bot",
                        msg: msg
                    };
                    this.setState({
                        messages: [...this.state.messages, says]
                    });
                }
            }
        });
        console.log(this.state.messages);
       

        // } catch (e) {
        //     says = {
        //         speaks: "bot",
        //         msg: {
        //             text: {
        //                 text:
        //                     " I am having troubles. I need to terminate, will be back later"
        //             }
        //         }
        //     };
        //     this.setState({ messages: [...this.state.messages, says] });
        //     let that = this;
        //     setTimeout(function () {
        //         that.setState({
        //             showBot: false
        //         });
        //     }, 2000);
        // }
    }
    async df_text_query(text) {
        let says = {
            speaks: "me",
            msg: {
                text: {
                    text: text
                },
                payload: {
                    fields: {
                        quick_replies: {
                            listValue: { values: [] }
                        }
                    }
                }
            }
        };
        this.setState({
            messages: [...this.state.messages, says]
        });
        try {
            let res = await request({ url: 'chatbot', method: 'POST', data: { message: text }, auth: true });
            for (let msg of res.data[0].queryResult.fulfillmentMessages) {
                if (msg.platform === "PLATFORM_UNSPECIFIED") {
                    says = {
                        speaks: "bot",
                        msg: msg
                    };
                    this.setState({
                        messages: [...this.state.messages, says]
                    });
                }
            }
        } catch (e) {
            says = {
                speaks: "bot",
                msg: {
                    text: {
                        text:
                            " I am having troubles. I need to terminate, will be back later"
                    }
                }
            };
            this.setState({ messages: [...this.state.messages, says] });
            let that = this;
            setTimeout(function () {
                that.setState({
                    showBot: false
                });
            }, 2000);
        }
    }

    async df_event_query(event) {
        try {
            let res = await request({ url: 'chatbot', method: 'POST', data: { message: event }, auth: true });

            // const res = await axios.post("https://server.realto.estate/api/v2/chatbot", {
            //     event,
            //     userID: cookies.get("userID")
            // });
            for (let msg of res.data[0].queryResult.fulfillmentMessages) {
                if (msg.platform === "PLATFORM_UNSPECIFIED") {
                    let says = {
                        speaks: "bot",
                        msg: msg
                    };
                    this.setState({
                        messages: [...this.state.messages, says]
                    });
                }
            }
        } catch {
            let says = {
                speaks: "bot",
                msg: {
                    text: {
                        text:
                            "I am having troubles. I need to terminate, will be back later"
                    }
                }
            };
            this.setState({ messages: [...this.state.messages, says] });
            let that = this;
            setTimeout(function () {
                that.setState({
                    showBot: false
                });
            }, 2000);
        }
    }
    handleQuickReplyPayload(event, payload, text) {
        event.preventDefault();
        event.stopPropagation();
        console.log("andle quick reply");
        console.log(payload);

        console.log(text);
        this.df_text_query(text);
        // switch (payload) {
        //     case "recommend_yes":
        //         this.df_event_query("SHOW_RECOMMENDATIONS");
        //         break;
        //     case "training_masterclass":
        //         this.df_event_query("MASTERCLASS");
        //         break;
        //     default:
        //         this.df_text_query(text);
        //         break;
        // }
    }

    renderCards(cards) {
        return cards.map((card, i) => <Card key={i} payload={card.structValue} />);
    }
    renderCard(card, i) {
        return (<Card key={i} payload={card} />);
    }
    renderOneMessage(message, i) {
        console.log('message');
        console.log(message.msg);
        if (message.msg && message.msg.text && message.msg.text.text) {
            return (
                <Message key={i} speaks={message.speaks} text={message.msg.text.text} />
            );
        } else if (
            message.msg && message.msg.card
            // message.msg.payload &&
            // message.msg.payload.fields &&
        ) {
            return (<Card key={i} payload={message.msg.card} />);
            // return (
            //     <div key={i}>
            //         <div className="card-panel grey lighten-5 z-depth-1">
            //             <div style={{ overflow: "hidden" }}>
            //                 {/* <div className="col s2">
            //                     <a
            //                         className="btn-floating btn-large waves-effect waves-light"
            //                         style={{ background: "darkblue" }}
            //                         href="#"
            //                     >
            //                         {message.speaks}
            //                     </a>
            //                 </div> */}
            //                 <div style={{ overflow: "auto", overflowY: "scroll" }}>
            //                     <div
            //                         style={{
            //                             height: 300,
            //                             width: 270
            //                         }}
            //                     >
            //                         {this.renderCard(
            //                             message.msg, i
            //                         )}
            //                     </div>
            //                 </div>
            //             </div>
            //         </div>
            //     </div>
            // );
        } else if (
            message.msg &&
            message.msg.payload &&
            message.msg.payload.fields &&
            message.msg.payload.fields.quickReplies
        ) {
            return (
                <QuickReplies
                    text={
                        message.msg.payload.fields.text
                            ? message.msg.payload.fields.text
                            : null
                    }
                    key={i}
                    replyClick={this.handleQuickReplyPayload}
                    speaks={message.speaks}
                    payload={message.msg.payload.fields.quickReplies.listValue.values}
                />
            );
        }
    }

    renderMessages(stateMessages) {
        // console.log('stateMessages');
        // console.log(stateMessages);
        // let array = [];
        // array.map(())
        if (stateMessages) {
            let messages = [];
            for (let index = 0; index < stateMessages.length; index++) {
                const message = stateMessages[index];
                messages.push(this.renderOneMessage(message, index));
            }
            return messages;
            // return stateMessages.map((message, i) => {
            //     return this.renderOneMessage(message, i);
            // });
        } else {
            return null;
        }
    }

    handleKeyPress(e) {
        if (e.key === "Enter") {
            this.df_text_query(e.target.value);
            e.target.value = "";
        }
    }

    render() {
        return <div>{this.renderMessages(this.state.messages)}</div>;
    }
}

