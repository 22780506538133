import {
  FETCH_COUNTRIES_FAIL,
  FETCH_COUNTRIES_REQUEST,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_ALL_COUNTRIES_REQUEST,
  FETCH_ALL_COUNTRIES_SUCCESS,
  FETCH_ALL_COUNTRIES_FAIL
} from './countries.types';
const initialState = {
  countries: [],
  loading: false,
  error: '',
  countriesAll: []
};
export const fetchCountriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COUNTRIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_COUNTRIES_SUCCESS:
      return {
        ...state,
        loading: false,
        countries: action.payload,
      };
    case FETCH_COUNTRIES_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
      case FETCH_ALL_COUNTRIES_SUCCESS:
        return {
          ...state,
          loading: false,
          countriesAll: action.payload,
        };

    default:
      return state;
  }
};
