import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';

// Import root app

import { HelmetProvider } from 'react-helmet-async';

// Initialize languages
import { ThemeProvider } from '@material-ui/core';
import RouterPage from './routes/index';
import store from './store/configureStore';
import theme from './theme/muiTheme';

// const ConnectedApp = ({ Component }) => (
//   <HelmetProvider>
//     <ThemeProvider theme={theme}>
//       <Component />
//     </ThemeProvider>
//   </HelmetProvider>
// );

const rootElement = document.getElementById('root');
ReactDOM.render(
  <Provider store={store}>
    <HelmetProvider>
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <RouterPage />
        </ThemeProvider>
      </React.StrictMode>
    </HelmetProvider>
  </Provider>,
  rootElement,
);
