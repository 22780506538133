import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem, ListItemText } from '@material-ui/core';

import { useSelector, useDispatch } from 'react-redux';
import { sortProducts } from '../../../../../../../../../store/properties/properties.actions';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: '0px 3px 20px #00000029',
    borderRadius: '10px',
  },
  listItem: {
    width: '90%',
    borderTop: 'none',
    margin: theme.spacing(0, 'auto'),
    '&:hover': {
      backgroundColor: 'none',
    },
  },
  textBlack: {
    color: 'black',
  },
}));

const NotificationList = (props) => {
  const classes = useStyles();
  const { onClose, notifications, className, ...rest } = props;
  const [selectedOption, setSelectedOption] = React.useState(null);
  const propertyListings = useSelector((state) => state.propertyListings);
  const { loading, hasMore, properties, error } = propertyListings;
  const sortedProperties = useSelector((state) => state.sortedProperties);
  const { filteredItems } = sortedProperties;

  const dispatch = useDispatch();
  const handleSort = (value) => {
    setSelectedOption(value);
    onClose();
  };
  React.useEffect(() => {
    dispatch(sortProducts(properties, selectedOption));
  }, [selectedOption]);

  console.log('🐨');
  console.log(filteredItems);
  console.log('🔥');

  return (
    <List {...rest} className={clsx(classes.root, className)} disablePadding>
      {notifications.map((notification, i) => (
        <ListItem
          className={classes.listItem}
          divider={i < notifications.length - 1}
          key={notification.id}
          onClick={() => handleSort(notification.type)}
        >
          <ListItemText
            className={classes.textBlack}
            primary={notification.title}
            primaryTypographyProps={{ variant: 'subtitle2' }}
          />
        </ListItem>
      ))}
    </List>
  );
};

NotificationList.propTypes = {
  className: PropTypes.string,
  notifications: PropTypes.array.isRequired,
};

export default NotificationList;
