import React, { useState } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { useInvestNowSection } from '../../../../context/investnow-context';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Box, Typography } from '@material-ui/core';
import MeetingSlotsTimezones from './MeetingSlotsTimezones';
import MeetingTimes from './MeetingTimes';

const useStyles = makeStyles((theme) => ({
  boxCls: {
    background: '#FFF9E8 0% 0% no-repeat padding-box',
    borderRadius: 10,
    width: '100%',
    padding: '20px 50px',
  },
  flexOne: {
    flex: 1,
  },
  cardCls: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: 10,
    padding: 12,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  centerCallMode: {
    margin: '0 auto',
  },
}));

const MeetingPlatform = () => {
  const classes = useStyles();
  const [platform, setPlatform] = useState('zoho');

  const [investmentdata, setInvestmentData] = useInvestNowSection();

  const handlePlatform = (value) => {
    setPlatform(value);
    setInvestmentData({ ...investmentdata, mode: platform });
   
  };

  console.log('🐨');
  console.log(investmentdata);

  

  const [selectedDate, setSelectedDate] = React.useState(
    new Date('2021-03-18T21:11:54'),
  );


  const handleDateChange = (date) => {
    setSelectedDate(date);
    setInvestmentData({ ...investmentdata, date: selectedDate });
  };
  console.log('🐨');
  console.log(investmentdata);


  return (
    <div className={classes.boxCls}>
      <h5 className="text-center">Select preferred mode of call</h5>

      <Grid container spacing={4} className="text-center mt-4">
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          onClick={() => handlePlatform('phone')}
        >
          <img src="/assets/icons/phone.png" alt="phone" />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          onClick={() => handlePlatform('googlemeet')}
        >
          <img src="/assets/icons/g-meet.png" alt="google Meet" />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          onClick={() => handlePlatform('zoom')}
        >
          <img src="/assets/icons/zoom.png" alt="zoom" />
        </Grid>
      </Grid>

      <p className="text-left mb-0 mt-5">
        *Link for Google Meet or Zoom will be sent to your registers email
        address after the confirmation of call schedule
      </p>
      <p className="text-left">
        *If you select Phone you will be contacted through your registered phone
        number
      </p>
      <Grid container spacing={4} className="text-center mt-4 mb-2">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk
              disablePast={true}
              variant="static"
              openTo="date"
              value={selectedDate}
              onChange={handleDateChange}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <section className="timingsContainer">
      <Box
        m={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5" align="center" className={classes.flexOne}>
          Select Time Slot of Call
        </Typography>
        <MeetingSlotsTimezones />
      </Box>
      <div className="bglightcream">
        <MeetingTimes />
      </div>
    </section>
    </div>
  );
};

export default MeetingPlatform;
