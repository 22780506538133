import React, { Component } from 'react';
import Slider from 'react-slick';
import MeetingPlatform from './MeetingPlatform';
import MeetingCalendar from './MeetingCalendar';
import MeetingSlots from './MeetingSlots';

export default class ScheduleMeetingSlider extends Component {


  render() {
    const settings = {
      dots: true,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <div>
        <Slider {...settings}>
          <MeetingPlatform   />
          <MeetingCalendar />
          <MeetingSlots />
        </Slider>
      </div>
    );
  }
}
