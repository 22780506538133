import React, { useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import ChatBot from 'react-simple-chatbot';
// import {cu} from 'react-simple-chatbot';

import { ThemeProvider } from 'styled-components';
import Mia from './../../assets/icons/Assistant.png';
import {
  withStyles,
  Avatar,
  makeStyles
} from '@material-ui/core';
import clsx from 'clsx';
import { ProcessMessage } from "./ProcessMessage";
import { connect, useSelector } from 'react-redux';
import {settext} from '../../store/chatbot/chatbot.actions'


const CssAvatar = withStyles({
  root: {
    width: '80px',
    height: '80px',
    '&  .MuiAvatar-img': {
      width: '100%',
      height: '100%',
    },
  },
})(Avatar);

const theme = {
  background: '#f5f8fb',
  fontFamily: 'Helvetica Neue',
  headerBgColor: '#F8CA9F',
  headerFontColor: '#fff',
  headerFontSize: '15px',
  botBubbleColor: '#F8CA9F',
  botFontColor: '#fff',
  userBubbleColor: '#fff',
  userFontColor: '#4a4a4a',
};

const useStyles = makeStyles((theme) => ({
  botContainer: {
    maxWidth: 330,
    maxHeight: 420,
    marginLeft: 'auto',
    marginRight: '4rem',
    marginBottom: '4rem',
    borderRadius: '22px',
    overflow: 'hidden',
  },
  botHeader: {
    background: '#FFF9E8',
    padding: '.5rem',
  },
  chatContainer: {
    overflow: 'auto',
    borderRadius: '8px',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '240px',
  },
  botText: {
    alignSelf: 'flex-end',
    background: '#EAEAEA',
    padding: theme.spacing(1),
    margin: theme.spacing(0.4),
    borderRadius: '10px',
    maxWidth: '90%',
    color: theme.common.arcBlack,
  },
  userText: {
    color: theme.common.arcBlack,
    alignSelf: 'flex-start',
    background: 'rgba(253,196,36,.25)',
    padding: theme.spacing(1),
    margin: theme.spacing(0.4),
    borderRadius: '10px',
    maxWidth: '90%',
  },
  bottomContent: {
    height: '50px',
    alignItems: 'center',
  },
  botHead: {
    marginLeft: 'auto',
  },
}));





// class Review extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       name: '',
//       gender: '',
//       age: '',
//     };
//   }

//   componentWillMount() {
//     const { steps } = this.props;
//     const { name, gender, age } = steps;

//     this.setState({ name, gender, age });
//   }

  

//   render() {


//     const { name, gender, age } = this.state;
    
//     return (
//       <div style={{ width: '100%' }}>
//         <h3>Summary</h3>
//         <table>
//           <tbody>
//             <tr>
//               <td>Name</td>
//               <td>{name.value}</td>
//             </tr>
//             <tr>
//               <td>Gender</td>
//               <td>{gender.value}</td>
//             </tr>
//             <tr>
//               <td>Age</td>
//               <td>{age.value}</td>
//             </tr>
//           </tbody>
//         </table>
//       </div>
//     );
//   }
// }

// Review.propTypes = {
//   steps: PropTypes.object,
// };

// Review.defaultProps = {
//   steps: undefined,
// };
// class Chat extends Component {

//   constructor(props){
//     super(props);
//     this.state = {
//       opened: false,
//       isLoggedIn: 'sample',
//     }
//   }
 


 
//   componentWillReceiveProps(nextProps='') {
//     var checker = localStorage.getItem('term') ;
//     function getterm(checker){
      
//       console.log('HUHU',checker)
//       return checker
      
//     }
//     if (checker !== nextProps) {
//       nextProps=checker;
      
//       this.setState({
//         isLoggedIn:checker 
//     },() => {
//         this.setState({isLoggedIn:checker});
//     });
//         console.log('count changed', nextProps);
//         getterm(nextProps)
        
  
        
//     }

    

  
// } // Listen to storage event
// componentDidUpdate(prevProps) {
  
//   console.log('Just updated..');
  
//   console.log('UPDATED', this.state.isLoggedIn)

//   // this.getLabel()
 
   
// }
  
// // tick(){
// //   this.setState({isLoggedIn: 'Hellojsjsjsj'})

// // }

// // async getLabel(){
  
// // }

 





//   toggleFloating = ({ opened }) => {
//     this.setState({ opened });
//     console.log(this.state.isLoggedIn)
   
//   }

 


  

  






//   render() {
//     const { classes } = this.props;
//     const { label } = this.props

  

    
//     return (
//       <ThemeProvider theme={theme}>
//         <ChatBot
//           floating={true}
//           opened={this.props.openChat  ? true: !this.state.opened ? false:this.toggleFloating}
//           // opened={this.state.opened}
          
//           toggleFloating={this.toggleFloating}
         
//           steps={[
//             {
//               id: '0',
//               message:'Hello this is Mia. How can I help you today ',
//               trigger: 'gender',
           
//             },
//             {
//               id: 'gender',
//               options: [
//                 { value: 'male', label:localStorage.getItem('term'), trigger: 'result' },
//                 // { value: 'female', label: 'Female', trigger: 'result' },
//               ],
//             },
//             {
//               id: 'input',
//               user: true,
//               trigger: '0',
//             },
        
           
            
//             {
//               id: 'result',
//               component: <ProcessMessage  />,
//               asMessage: true,
//               trigger: 'input',
//             },
//           ]}
//           // steps={[
//           //   {
//           //     id: '0',
//           //     message: 'Hi, welcome to Mia How may I help you today?',
//           //     trigger: 'input',
//           //   },
//           //   {
//           //     id: 'input',
//           //     user: true,
//           //     trigger: 'result',
//           //   },
//           //   {
//           //     id: 'result',
//           //     component: <ProcessMessage />,
//           //     asMessage: true,
//           //     trigger: 'input',
//           //   },
//           // ]}
//         />
//       </ThemeProvider>
//     );
//   }
// }

const Chat =({openChat, toggleChat, labelTerm})=>{

  const [opened, setopened] = useState(false);
   const  toggleFloating = () => {
    setopened(!opened)
    
   
  }

  const botterm = useSelector((state) => state.chatReducer.botterm);
  console.log(botterm);

  const [exact, setexact] = useState('def')



  return(

    <ThemeProvider theme={theme}>


   
            <ChatBot
          floating={true}
          opened={openChat}
          // opened={this.state.opened}
          
          toggleFloating={toggleChat}

          steps={[
            {
              id: '0',
              message:'Hello this is Mia. How can I help you today ',
              trigger:'gender',
           
            },
            {
              id: 'gender',
              options: [
                { value: 'male', label:labelTerm? labelTerm:'Show the best investment opportunity for me', trigger: 'result' },
                // { value: 'female', label: 'Female', trigger: 'result' },
              ],
            },
            {
              id: 'input',
              user: true,
              trigger: '0',
            },
            {
              id: 'selfinput',
              user: true,
              trigger: 'result',
            },
        
           
            
            {
              id: 'result',
              component: <ProcessMessage  />,
              asMessage: true,
              trigger: 'input',
            },
          ]}
        
        />
         </ThemeProvider>
  )
}

export default withStyles(useStyles)(Chat);