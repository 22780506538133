import React, { useState } from 'react';
import { Container, Grid, makeStyles, Paper } from '@material-ui/core';
import Verification from './../../components/Verification';
import ModalDialog from './../../components/Dialog';
import PasswordReset from './PasswordReset';

const useStyles = makeStyles((theme) => ({}));

const ChangePassword = () => {
  const classes = useStyles();
  const [verifyModal, setVerifyModal] = useState(false);

  const toggleModal = () => setVerifyModal(true);

  const closeModalDownload = () => setVerifyModal(false);

  return (
    <div>
      <Paper className="p-5">
        <h1 style={{ textAlign: 'center' }}>Change Password</h1>

        <PasswordReset />

        {/* <p>A verification code will be sent to your registered email or phone before you can reset your password.</p>
                <button className="btnYellowBorder" onClick={toggleModal}>Change Password</button>
                <ModalDialog openDialog={verifyModal} closeModal={closeModalDownload}>
                    <Verification closeAllModal={closeModalDownload} />
                </ModalDialog> */}
      </Paper>
    </div>
  );
};

export default ChangePassword;
