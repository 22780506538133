import React from 'react';
import {
  Typography,
  makeStyles,
  Box,
  Grid,
  Card,
  CardContent,
} from '@material-ui/core';
import ReactPlayer from 'react-player';
import WebinarPlayer from './WebinarPlayer';

const videos = [
  'https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4',
  ,
  'https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4',
];
const useStyles = makeStyles((theme) => ({
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  reactPlayerWrapper: {
    position: 'relative',
    paddingTop: '56.25%' /* 720 / 1280 = 0.5625 */,
    background: 'lightpink',
  },
  webinarContainer: {
    boxShadow: '0px 5px 9px 2px rgba(241, 243, 255, 1)',
  },
}));

const PropertyWebinar = ({ videos: webinars }) => {
  const classes = useStyles();
  return (
    <Card elevation={0}>
      <CardContent>
        <Typography variant="h4" gutterBottom>
          Webinars
        </Typography>
        <Box m={2} />
        <Grid container justify="space-between">
          <Grid item xs={12} sm={4}>
            <WebinarPlayer webinar={webinars} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PropertyWebinar;
