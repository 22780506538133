import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Box,
  useTheme,
  Paper,
  Button,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
// pdfs
// !Todos Omit the css import below.
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// !Todos the document imported to be updated
import designResource from '../../../assets/files/design.pdf';

import ModalDialog from './Dialog';

import 'react-perfect-scrollbar/dist/css/styles.css';
import PropTypes from 'prop-types';
import './../dashboard.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import fileDownload from 'js-file-download';
import {currConv} from '../../../services/currency'

import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { getBaseUrl } from '../../../utils/request';

// table head

// filters table sotring methods

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'tableData',
    numeric: false,
    disablePadding: true,
    label: 'Date of Investment',
  },
  {
    id: 'propertyName',
    numeric: false,
    disablePadding: false,
    label: 'Property Name',
  },
  {
    id: 'noOfTokens',
    numeric: true,
    disablePadding: false,
    label: 'No. Of Fractions',
  },
  {
    id: 'payment.total',
    numeric: true,
    disablePadding: false,
    label: 'Investment Amount',
  },
  {
    id: 'type',
    numeric: true,
    disablePadding: false,
    label: 'Investment Advisor',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'certificate',
    numeric: false,
    disablePadding: false,
    label: 'Certificate',
  },
];

const useStylesTableHead = makeStyles((theme) => ({
  tableHeaderCls: {
    background: '#FFF3E7 0% 0% no-repeat padding-box',
    borderRadius: 10,
    padding: '6px 10px',
  },
}));
function EnhancedTableHead(props) {
  const classes = useStylesTableHead();
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHeaderCls}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
    width: '100%',
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function createData(data1, data2, data3, data4, data5, data6, data7) {
  return { data1, data2, data3, data4, data5, data6, data7 };
}

const rows = [
  createData(
    '09 JAN 2020',
    'Prestige Sky High',
    '100',
    '$20,000',
    'Joseph Tom',
    'In Process',
    'Download',
  ),
  createData(
    '09 JAN 2020',
    'Shoba Sea View',
    '100',
    '$20,000',
    'Joseph Tom',
    'Active',
    'Download',
  ),
];

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
  },
  margin: {
    height: theme.spacing(2),
  },
  marginTop: {
    marginTop: theme.spacing(4),
  },
  tilePaperCls: {
    width: '100%',
    padding: 20,
    marginBottom: 20,
  },
  gridBorder: {
    borderRight: '5px solid #F8F9FC',
  },
  table: {
    minWidth: 650,
  },
  tableHeaderCls: {
    background: '#FFF3E7 0% 0% no-repeat padding-box',
    borderRadius: 10,
  },
  tableData: {
    padding: 25,
  },
  tableTotalCls: {
    background: '#FFF3E7 0% 0% no-repeat padding-box',
    borderRadius: 10,
    padding: '6px 10px',
  },
  chartPaper: {
    padding: 10,
  },
  statusCls: {
    background: '#f5f5f5 0% 0% no-repeat padding-box',
    borderRadius: 10,
    padding: 10,
    width: 100,
  },
  downloadBtn: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #FDC424',
    borderRadius: 10,
    width: 110,
    padding: 10,
  },
}));

const InvestmentTable = () => {
  const classes = useStyles();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [investmentData, setInvestmentData] = useState([]);
  const [verifyModal, setVerifyModal] = useState(false);
  const toggleModal = () => setVerifyModal(true);
  const closeModalDownload = () => setVerifyModal(false);

  const [pdfUrl, setPdfUrl] = useState('');
  const [showPdf, setShowPdf] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getCertificateId = (id) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + userInfo.accessToken,
        'Content-Type': 'application/json',
      },
      query: {
        page: 1,
        per_page: 10,
        type: 'active',
      },
    };
    axios
      .get(
     getBaseUrl(  'transactionId=' + id),
        config,
      )
      .then((res) => {
        console.log('res', res);
        investmentGetData();
      });
  };

  const downloadCertificate = (data) => {
    console.log('data', data);
    setPdfUrl(data.url.pdf);
    setShowPdf(true);
    // if(data.url){
    //     axios.get(data.url.pdf, {
    //         responseType: 'blob',
    //       })
    //       .then((res) => {
    //         fileDownload(res.data, 'Download_certificate')
    //       })
    // }
    // const config = {
    //     headers: {
    //     'Authorization': 'Bearer ' + userInfo.accessToken,
    //     'Content-Type': 'application/json',
    //     },
    // }
    // axios.get(`https://server.realto.estate/api/v2/certificate/transactionId=` + data.id,  config)
    // .then(res => {
    //     console.log("res", res);
    // })
  };

  const investmentGetData = () => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + userInfo.accessToken,
        'Content-Type': 'application/json',
      },
      query: {
        page: 1,
        per_page: 10,
        type: 'active',
      },
    };
    axios
      .get(getBaseUrl('investment'), config)
      .then((res) => {
        console.log('res', res.data.data);
        setInvestmentData(res.data.data);
      });
  };

  useEffect(() => {
    investmentGetData();
  }, []);
  // table header
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('goals');
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  //  Todos  pdf viewer helper functions
  const [selectedDocument, setSelectedDocument] = useState(designResource);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  // Todos pdfview

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;
  console.log('🐨');
  console.log(investmentData);
  console.log('🔥');

  function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
    
}


  const [userCountry, setuserCountry] = useState('');
  
  useEffect(()=>{
  fetch(getBaseUrl('users'),{
    headers:{
      Authorization: 'Bearer ' + userInfo.accessToken,
      'Content-Type': 'application/json',
    }
  }).then((res)=>res.json()).then((data)=>setuserCountry(data.address.country)).catch((err)=>console.log(err));
  },[])

  return (
    <div className="mt-4">
      <PerfectScrollbar>
        <Table className={classes.table} aria-label="simple table">
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={investmentData.length}
          />

          {investmentData && (
            <TableBody>
              {/* {(rowsPerPage > 0
                ? investmentData.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage,
                  )
                : investmentData
              ).map((row) => (
                <TableRow key={row._id}>
                  <TableCell
                    component="th"
                    scope="row"
                    align="center"
                    className={classes.tableData}
                  >
                    {moment(row.createdAt).format('ll')}
                  </TableCell>
                  <TableCell align="center" className={classes.tableData}>
                    {row.propertyName}
                  </TableCell>
                  <TableCell align="center" className={classes.tableData}>
                    {row.noOfTokens}
                  </TableCell>
                  <TableCell align="center" className={classes.tableData}>
                    {row.payment.total}
                  </TableCell>
                  <TableCell align="center" className={classes.tableData}>
                    {row.type}
                  </TableCell>
                  <TableCell align="center" className={classes.tableData}>
                    <label
                      className={classes.statusCls}
                      style={{
                        color:
                          row.status === 'Accepted' ? '#54B98A' : '#FDC424',
                      }}
                    >
                      {row.status}
                    </label>
                  </TableCell>
                  <TableCell align="center" className={classes.tableData}>
                    {row.certificate && row.certificate.url ? (
                      <div
                        className={classes.downloadBtn}
                        onClick={() => downloadCertificate(row.certificate)}
                      >
                        <img
                          src="/assets/icons/download.svg"
                          alt="Download"
                          width="18px"
                        />
                        <label className="downloadText">Download</label>
                      </div>
                    ) : (
                      <div
                        className={classes.downloadBtn}
                        onClick={() => getCertificateId(row._id)}
                      >
                        <label className="downloadText">Get Certificate</label>
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              ))} */}

              {stableSort(investmentData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.propertyName);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow key={row._id}>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        className={classes.tableData}
                      >
                        {moment(row.createdAt).format('ll')}
                      </TableCell>
                      <TableCell align="center" className={classes.tableData}>
                        {row.propertyName}
                      </TableCell>
                      <TableCell align="center" className={classes.tableData}>
                        {row.noOfTokens}
                      </TableCell>
                      <TableCell align="center" className={classes.tableData}>
                      {numberWithCommas(currConv(row.payment.total,userCountry))}
                      
                      </TableCell>
                      <TableCell align="center" className={classes.tableData}>
                        {row.type}
                      </TableCell>
                      <TableCell align="center" className={classes.tableData}>
                        <label
                          className={classes.statusCls}
                          style={{
                            color:
                              row.status === 'Accepted' ? '#54B98A' : '#FDC424',
                          }}
                        >
                          {row.status}
                        </label>
                      </TableCell>
                      <TableCell align="center" className={classes.tableData}>
                        {row.certificate && row.certificate.url ? (
                          <div
                            className={classes.downloadBtn}
                            onClick={() => downloadCertificate(row.certificate)}
                          >
                            <img
                              src="/assets/icons/download.svg"
                              alt="Download"
                              width="18px"
                            />
                            <label className="downloadText">Download</label>
                          </div>
                        ) : (
                          <div
                            className={classes.downloadBtn}
                            // onClick={() => getCertificateId(row._id)}
                            onClick={toggleModal}
                          >
                            <label className="downloadText">
                              Get Certificate
                            </label>
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          )}

          <TableFooter>
            <TableRow style={{ float: 'right' }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={6}
                count={investmentData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </PerfectScrollbar>
      <ModalDialog openDialog={verifyModal} closeModal={closeModalDownload}>
        {/* <Verification /> */}
        <Document
          file="https://res.cloudinary.com/realto-t/image/upload/v1578856439/afc0c6b0-356f-11ea-b7ec-f7b3b684c911.pdf"
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} />
        </Document>
        <Box m={1}>
          <p className={classes.pages}>
            Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
          </p>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Button
              variant="outlined"
              className={classes.pdfNavigators}
              disableElevation
              disableRipple
              disableFocusRipple
              type="button"
              disabled={pageNumber <= 1}
              onClick={previousPage}
            >
              Previous
            </Button>
            <Button
              variant="outlined"
              disableElevation
              disableRipple
              disableFocusRipple
              className={classes.pdfNavigators}
              type="button"
              disabled={pageNumber >= numPages}
              onClick={nextPage}
            >
              Next
            </Button>
          </Box>
        </Box>
      </ModalDialog>

      {showPdf && (
        <object
          data={{ pdfUrl } + '.pdf#view=Fit'}
          type="application/pdf"
          width="100%"
          height="850"
        >
          <p>
            It appears your Web browser is not configured to display PDF files.
            No worries, just{' '}
            <a href="your_file.pdf">click here to download the PDF file.</a>
          </p>
        </object>
      )}
    </div>
  );
};

export default InvestmentTable;
