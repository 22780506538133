import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  flexOne: {
    flex: 1,
  },
}));

import { Box, Typography } from '@material-ui/core';
import MeetingSlotsTimezones from './MeetingSlotsTimezones';
import MeetingTimes from './MeetingTimes';

export default function MeetingSlots() {
  const classes = useStyles();

  return (
    <section className="timingsContainer">
      <Box
        m={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5" align="center" className={classes.flexOne}>
          Select Time Slot of Call
        </Typography>
        <MeetingSlotsTimezones />
      </Box>
      <div className="bglightcream">
        <MeetingTimes />
      </div>
    </section>
  );
}
