import React, { useState, useEffect } from 'react';
import { Container, Grid, makeStyles, Paper } from '@material-ui/core';
import './../dashboard.css';
import 'react-circular-progressbar/dist/styles.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { LineChart, Line, XAxis, YAxis, CartesianGrid } from 'recharts';
import SelectDropDown from './SelectDropDown';
import CustomSelectDropDown from './CustomSelectDropDown';
import InvestNow from './../components/InvestNow';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {getBaseUrl} from '../../../utils/request'
const data = [
  {
    name: 'Jan',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Feb',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Mar',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Apr',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'May',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Jun',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Jul',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
];

const propertyData = [
  {
    value: 'Prestige Sky High',
    label: 'Prestige Sky High',
  },
  {
    value: 'Shoba Sea View',
    label: 'Shoba Sea View',
  },
];

const yearData = [
  { value: '3 months', label: '3 months' },
  { value: '6 months', label: '6 months' },
  { value: '3 years', label: '3 years' },
  { value: '6 years', label: '6 years' },
];

const yearDataCustom = [
  { value: '3 months', label: '3 months' },
  { value: '6 months', label: '6 months' },
  { value: '3 years', label: '3 years' },
  { value: '6 years', label: '6 years' },
  { value: 'Custom Range', label: 'Custom Range' },
];

const earningList = [
  { value: 'current', label: 'current' },
  { value: 'sold', label: 'sold' },
];

function createData(data1, data2, data3, data4, data5, data6, data7, data8) {
  return { data1, data2, data3, data4, data5, data6, data7, data8 };
}

const rows = [
  createData('Month'),
  createData('Total Rent'),
  createData('Property Tax'),
  createData('Other Tax'),
  createData('Tax'),
  createData('Net Income'),
  createData('Investor Share'),
  createData('Management Fee'),
  createData('Net Distribution'),
];

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
  },
  margin: {
    height: theme.spacing(2),
  },
  marginTop: {
    marginTop: theme.spacing(4),
  },
  tilePaperCls: {
    width: '100%',
    padding: 20,
    marginBottom: 20,
  },
  gridBorder: {
    borderRight: '5px solid #F8F9FC',
    padding: 20,
  },
  table: {
    minWidth: 650,
  },
  tableHeaderCls: {
    background: '#FFF3E7 0% 0% no-repeat padding-box',
    borderRadius: 10,
  },
  tableHeader: {
    padding: '32px 20px 10px 20px',
    position: 'relative',
  },
  tableData: {
    padding: 15,
    border: 'none',
  },
  tableTotalCls: {
    background: '#FFF3E7 0% 0% no-repeat padding-box',
    borderRadius: 10,
    padding: '6px 10px',
  },
  chartPaper: {
    padding: 10,
  },
}));

const InvestmentDetails = (props) => {
  const classes = useStyles();
  const [width, setWidth] = useState(1000);
  const [toggleInvest, setToggleInvest] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [financeData, setFinanceData] = useState([]);
  const [tableData, setTableData] = useState(rows);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [investmentData, setInvestmentData] = useState([]);

  useEffect(() => {
    if (window.outerWidth < 576) {
      setWidth(window.outerWidth - 70);
    }
  }, [window.outerWidth]);

  useEffect(() => {
    if (window.outerWidth < 576) {
      setWidth(window.outerWidth - 70);
    }
  }, [window.outerWidth]);

  useEffect(() => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + userInfo.accessToken,
        page: 1,
        per_page: 10,
        'Content-Type': 'application/json',
      },
    };
    axios
      .get(
      getBaseUrl( 'investment/summary/' +
          props.propertyId),
        config,
      )
      .then((res) => {
        console.log('res', res);
        setInvestmentData(res.data);

        console.log('tableData', tableData);
        let finalTableData = tableData.map(function (element) {
          return element.data1;
        });
        let setData = res.data[0].financials.map((data, id) => {
          if (id < 10) {
            return {
              data1: moment(data.createdAt).format('MMMM'),
              data2: data.expense,
              data3: 0,
              data4: 0,
              data5: data.income,
              data6: 0,
              data7: 0,
              data8: 0,
              data9: 0,
            };
          }
        });

        console.log('setData', setData);

        let finalSetData = setData.filter(function (element) {
          return element !== undefined;
        });
        // let newSetData = finalSetData.map((item, id) => {
        //     return item.data1 = finalTableData[id];
        // })

        // console.log("newSetData", newSetData)
        console.log('finalSetData', finalSetData);
        setFinanceData(finalSetData);

        // finalSetData.map((itm, id) => {
        //     itm['data1'] = finalTableData[id];
        // });

        let getChartData = res.data[0].income.map((data, id) => {
          if (id < 10) {
            return {
              distribution: data.distribution,
              name: moment(data.time.fromDate).format('MMMM'),
            };
          }
        });
        let finalData = getChartData.filter(function (element) {
          return element !== undefined;
        });
        setChartData(finalData);
      });
  }, [props]);

  return (
    <div>
      <Paper className={classes.tilePaperCls}>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={10}>
            <img
              src="/assets/icons/arrow-left.svg"
              onClick={() => props.goBack()}
              width="20px"
              className="mr-2 mb-2"
              alt="arrow"
            />
            <label
              className="dashboardTileCls mb-0"
              onClick={() => props.goBack()}
            >
              Back
            </label>
          </Grid>
        </Grid>
      </Paper>
      <img src="/assets/icons/prestiege.svg" />
      <label className="mb-0 pt-3">
        Prestige Sky High <br /> Thane, Maharashtra, India
      </label>

      <Paper className={classes.tilePaperCls}>
        {investmentData.map((data, id) => {
          return (
            <Grid
              container
              key={id}
              style={{ textAlign: 'left', background: '#FFF9E8' }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={3}
                className={classes.gridBorder}
              >
                <h6 className="mb-0">Investment Date</h6>
                <br />
                <h5>{moment(data.createdAt).format('ll')}</h5>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={3}
                className={classes.gridBorder}
              >
                <h6 className="mb-0">Number Of Fractions</h6>
                <br />
                <h5>{data.noOfTokens}</h5>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={3}
                className={classes.gridBorder}
              >
                <h6 className="mb-0">Investment </h6>
                <br />
                <h5>${data.investments}</h5>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={3}
                className={classes.gridBorder}
              >
                <h6 className="mb-0">Current Value </h6>
                <br />
                <h5>${data.currentValue}</h5>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={3}
                className={classes.gridBorder}
              >
                <h6 className="mb-0">ROI </h6>
                <br />
                <h5>$984</h5>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={3}
                className={classes.gridBorder}
              >
                <h6 className="mb-0">Investment Status </h6>
                <br />
                <h5 style={{ color: 'green' }}>{data.status}</h5>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={3}
                className={classes.gridBorder}
              >
                <h6 className="mb-0">Property Status </h6>
                <br />
                <h5 style={{ color: 'green' }}></h5>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={3}
                className={classes.gridBorder + ' text-center'}
              >
                <button className="btnYellowBorder mb-3">
                  View Property In marketplace
                </button>
                <button
                  className="btnYellowIcon"
                  onClick={() => setToggleInvest(true)}
                >
                  <img
                    src="/assets/icons/download.svg"
                    alt="Download"
                    className="mr-2"
                    width="12px"
                  />
                  Schedule call with advisor
                </button>
              </Grid>
            </Grid>
          );
        })}

        <InvestNow
          openDialog={toggleInvest}
          setDefault={() => setToggleInvest(false)}
        />
      </Paper>

      <Paper className={classes.tilePaperCls}>
        <Grid container className="mt-3" spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Paper className={classes.chartPaper}>
              <Grid container className="mb-4">
                <Grid item xs={6} sm={6} md={6} lg={10}>
                  <label className="dashboardTileCls pl-3">
                    Rent distribution
                  </label>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={2}
                  className="d-flex justify-content-end"
                >
                  <SelectDropDown dataDetails={yearData} />
                </Grid>
              </Grid>
              <LineChart width={width} height={400} data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" axisLine={false} />
                <YAxis axisLine={false} />
                <Line
                  type="monotone"
                  dataKey="distribution"
                  stroke="#E5B23E"
                  strokeWidth={2}
                />
              </LineChart>
            </Paper>
          </Grid>
        </Grid>
      </Paper>

      <Paper className={classes.tilePaperCls}>
        <Grid container className="mt-4 mb-4" style={{ position: 'relative' }}>
          <Grid item xs={12} sm={12} md={6} lg={10}>
            <label className="dashboardTileCls pl-3">
              Financial Statements
            </label>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={2}
            className="d-flex justify-content-end"
          >
            <CustomSelectDropDown dataDetails={yearDataCustom} />
          </Grid>
        </Grid>

        <TableContainer
          component={Paper}
          className="mt-4"
          style={{ boxShadow: 'none' }}
        >
          <Table className={classes.table} aria-label="simple table">
            <TableHead className={classes.tableHeaderCls}>
              <TableRow>
                <TableCell align="center">Month</TableCell>
                <TableCell align="center">Total Rent</TableCell>
                <TableCell align="center">Property Tax</TableCell>
                <TableCell align="center">Other Tax</TableCell>
                <TableCell align="center">Tax</TableCell>
                <TableCell align="center">Net Income</TableCell>
                <TableCell align="center">Investor Share</TableCell>
                <TableCell align="center">Management Fee</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {financeData.map((row) => (
                <TableRow>
                  <TableCell
                    align="center"
                    className={classes.tableData + ' highlightCls'}
                  >
                    {row.data1}
                  </TableCell>
                  <TableCell align="center" className={classes.tableData}>
                    {row.data2}
                  </TableCell>
                  <TableCell align="center" className={classes.tableData}>
                    {row.data3}
                  </TableCell>
                  <TableCell align="center" className={classes.tableData}>
                    {row.data4}
                  </TableCell>
                  <TableCell align="center" className={classes.tableData}>
                    {row.data5}
                  </TableCell>
                  <TableCell align="center" className={classes.tableData}>
                    {row.data6}
                  </TableCell>
                  <TableCell align="center" className={classes.tableData}>
                    {row.data7}
                  </TableCell>
                  <TableCell align="center" className={classes.tableData}>
                    {row.data8}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default InvestmentDetails;

// filters table sorting methods

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// table header

const headCells = [
  {
    id: 'data1',
    numeric: false,
    disablePadding: true,
    label: 'Month',
  },
  {
    id: 'data2',
    numeric: false,
    disablePadding: true,
    label: 'Total Rent',
  },
  {
    id: 'data3',
    numeric: true,
    disablePadding: false,
    label: 'Property Tax',
  },

  {
    id: 'data4',
    numeric: true,
    disablePadding: false,
    label: 'Other Tax',
  },
  {
    id: 'data5',
    numeric: true,
    disablePadding: false,
    label: 'Tax',
  },
  {
    id: 'data6',
    numeric: false,
    disablePadding: false,
    label: 'Net Income',
  },
  {
    id: 'data7',
    numeric: true,
    disablePadding: false,
    label: 'Investor Share',
  },
  {
    id: 'data8',
    numeric: true,
    disablePadding: false,
    label: 'Management Fee',
  },
];
const useStylesTableHead = makeStyles((theme) => ({
  // tableHeaderCls: {
  //   background: '#FFF3E7 0% 0% no-repeat padding-box',
  //   borderRadius: 10,
  //   padding: '6px 10px',
  // },
  tableHeader: {
    padding: '32px 20px 10px 20px',
    position: 'relative',
  },
}));
function EnhancedTableHead(props) {
  const classes = useStylesTableHead();
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHeaderCls}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className={classes.tableHeader}
            align="center"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
{
  /* <TableBody>
  {stableSort(financeData, getComparator(order, orderBy))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((row, index) => {
      const isItemSelected = isSelected(row.propertyName);
      const labelId = `enhanced-table-checkbox-${index}`;

      return (
        <TableRow
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={row.name}
          selected={isItemSelected}
        >
          <TableCell component="th" align="center" id={labelId} scope="row">
            {row.data1}
          </TableCell>
          <TableCell align="center">{row.data2}</TableCell>
          <TableCell align="center">{row.data2}</TableCell>
          <TableCell align="center">{row.data3}</TableCell>
          <TableCell align="center">{row.data4}</TableCell>
          <TableCell align="center">{row.data5}</TableCell>
          <TableCell align="center">{row.data6}</TableCell>
          <TableCell align="center">{row.data7}</TableCell>
        </TableRow>
      );
    })}
  {emptyRows > 0 && (
    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
      <TableCell colSpan={6} />
    </TableRow>
  )}
</TableBody>; */
}
{
  /* <TablePagination
rowsPerPageOptions={[5, 10, 25]}
component="div"
count={dashboardData.length}
rowsPerPage={rowsPerPage}
page={page}
onChangePage={handleChangePage}
onChangeRowsPerPage={handleChangeRowsPerPage}
/> */
}
