import React, {useState} from 'react';
import {
    Container,
    Grid,
    makeStyles,
    Paper
} from '@material-ui/core';
import profileDetails from './../../data/profile.json';

const useStyles = makeStyles((theme) => ({
    cardCls: {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 0px 20px #0000001A',
        borderRadius: 10,
        padding: 10,
        textAlign: 'center',
        height: '100%'
    }
}));

const Received = () => {
    const classes = useStyles();
    return(
        <div className="mt-4">
            <Grid container spacing={4}>
                {(profileDetails.map((data, id) => {
                    if(data.name){
                        return(
                            <Grid item xs={12} sm={12} md={4} lg={4} key={id}>
                                <div className={classes.cardCls}>
                                    <img src={data.img} alt={data.name} width="186px" />
                                    <h5 className="mt-3">{data.name}</h5>
                                    <p>{data.email}</p>
                                    <p>{data.details}</p>
                                </div>
                            </Grid>
                        )
                    }
                }))}
                
            </Grid>
        </div>
    )
}

export default Received;
