import React, { useEffect, useState, useRef } from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useDispatch, useSelector } from 'react-redux';
import { fetchCuratedProperties } from '../../../../store/properties/properties.actions';
import { CuratedProperty } from './components';
import PropertyListing from '../../../../components/MarketPlace/PropertyListing';

const useStyles = makeStyles((theme) => ({
  marketPlaceContainer: {
    margin: theme.spacing(2, 'auto', 2),
    
  },

  filterContainer: {
    margin: theme.spacing(4, 0),
  },
  propertyInfoIconText: {
    display: 'flex',
  },
  loaderCard: {
    boxShadow: '2px 4px 4px #ccc',
    borderRadius: '4px',
    maxWidth: '100%',
  },
  filterButtonChecked: {
    background: '#DFE9F6ff',
    border: '1px solid #DFE9F6',
    paddingRight: '2rem',
    borderRadius: '2px',
  },
  filterButtonUnChecked: {
    border: '1px solid #DFE9F6',
    paddingRight: '2rem',
    borderRadius: '2px',
  },
  // card styles
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  tagsFilterContent: {
    padding: theme.spacing(1),
  },

  // arrows color to be updated as per the design
  arrows: {
    fill: 'darkgrey',
  },
  // margin topbottom for all properties section
  marginTopBottom: {
    margin: theme.spacing(2, 0),
  },
}));

const CuratedInvestment = (props) => {
  const classes = useStyles();

  const propertyListings = useSelector(
    (state) => state.curatedPropertyListings,
  );
  const { loading, properties, error } = propertyListings;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCuratedProperties());
  }, []);
  if (error && error.length !== 0 && !loading) {
    return <div>Please refresh the page again.</div>;
  }
  if (!properties) {
    return <div></div>;
  }
  return (
    <Container maxWidth="lg" className={classes.marketPlaceContainer}>
      <Grid container spacing={4}>
        {properties.map(({ _id, ...otherPropertyProps }) => (
          <>
            <Grid item xs={12} sm={6} md={3} key={_id}>
              {/* <CuratedProperty
                key={_id}
                id={_id}
                history={props.history}
                match={props.path}
                {...otherPropertyProps}
                // address={property.address}
                // marketing={property.marketing}
                // name={property.name}
                // roi={property.roi}
                // token={property.token}
              /> */}
               <PropertyListing
                  key={_id}
                  
                  id={_id}
                  history={props.history}
                  match={props.path}
                  {...otherPropertyProps}
                  // address={property.address}
                  // marketing={property.marketing}
                  // name={property.name}
                  // roi={property.roi}
                  // token={property.token}
                />
            </Grid>
          </>
        ))}
      </Grid>
    </Container>
  );
};

export default CuratedInvestment;
