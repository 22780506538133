import React, { useState, useEffect } from 'react';

import {
  AppBar,
  makeStyles,
  Toolbar,
  IconButton,
  Button,
  List,
  ListItem,
  ListItemText,
  Container,
} from '@material-ui/core';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

import { Link, NavLink } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import logo from './../../assets/icons/logo.png';
import './index.css';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from './../../store/user/user.actions';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    flexWrap: 'wrap',
   
  },
  toolbarTitle: {
    flexGrow: 1,
    textDecoration: 'none',
    marginLeft: '1rem',
  },
  market: {
    textDecoration: 'none',
    borderRadius: '2px',
    textTransform: 'none',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    background: 'white',
    fontSize: '1rem',
    border: '1px solid white',
    '&:hover': {
      border: '1px solid black',
      background: 'white',
    },
  },
  logoContainer: {
    flex: 1,
  },
  logo: {
    height: '3rem',
  },
  navItem: {
    margin: '0 1rem',
  },
  voyage: {
    color: 'white',
    background: theme.common.arcBlack,
    // fontWeight: 'bold',
    textTransform: 'none',
    marginLeft: '42px',
    paddingRight: '2rem',
    paddingLeft: '2rem',
    borderRadius: '4px',
    border: '1px solid black',
    '&:hover': {
      background: 'white',
      color: theme.common.arcBlack,
    },
  },
  login: {
    background: 'white',
    borderRadius: '4px',
    textTransform: 'none',
    marginLeft: '25px',
    border: '1px solid #FECF0E',
    '&:hover': {
      background: theme.common.yellowLight,
      color: 'white',
    },
  },
  menu: {
    backgroundColor: theme.common.errieBlack,
    color: 'white',
  },
  drawerIcon: {
    height: '50px',
    width: '50px',
  },
  drawerIconContainer: {
    marginLeft: 'auto',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  drawer: {
    backgroundColor: theme.common.arcSilver,
  },
  drawerItem: {
    ...theme.typography.tab,
    color: theme.common.orange,
  },
  nav: {
    marginLeft: 'auto',
  },
  notification: {
    color: theme.common.arcBlack,
    height: '100%',
  },
  loginBtn: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #FDC424',
    color: '#000',
    position: 'relative',
    bottom: 8,
  },
  gettingStarted: {
    background: '#1f1f1f 0% 0% no-repeat padding-box',
    color: '#fff',
    padding: '4px 10px',
    position: 'relative',
    bottom: 4,
  },
}));

const Header = () => {
  const classes = useStyles();
  const userLogin = useSelector((state) => state.userLogin);
  const [toggleMenu, SetToggleMenu] = useState(null);
  const [mobileView, setMobileView] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const { userInfo } = userLogin;
  const handleLogout = () => {
    console.log('check reducer');
    console.log('LOGGING OUT')
    dispatch(logout());
  };

  const closeMenu = () => {
    SetToggleMenu(false);
  };

  useEffect(() => {
    if (window.outerWidth < 576) {
      setMobileView(true);
    }
  }, [window.outerWidth]);

  return (
    <nav className="navbar navbar-expand-lg navbar-light navBarCustomCls">
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      {mobileView ? (
        <div className="wrapImg">
          <Link className="navbar-brand" to="/">
            <img
              src={logo}
              className={classes.logoMobileCls}
              width="80px"
              height="30px"
              alt="Realto"
            />
          </Link>
        </div>
      ) : null}
      {mobileView ? (
        <div className="mobileNotifyMenu">
          <NotificationsNoneOutlinedIcon
            className={classes.notification}
            style={{ position: 'relative', top: 11 }}
          />
          <img
            src="/assets/images/profile.svg"
            className="ml-3"
            alt="Profile"
            width="30px"
            height="30px"
          />
          <label className="profileName ml-0">
            <img
              src="/assets/icons/arrow-down.svg"
              onClick={() => SetToggleMenu(!toggleMenu)}
              className="ml-2"
              alt="down"
              width="14px"
            />
          </label>
          {toggleMenu && (
            <div className="profileMenu">
              <p>
                <Link
                  to="/wish-list"
                  onClick={closeMenu}
                  style={{ color: '#1f1f1f', textDecoration: 'none' }}
                >
                  My Wishlist
                </Link>
              </p>
              <p>
                <Link
                  to="/account"
                  onClick={closeMenu}
                  style={{ color: '#1f1f1f', textDecoration: 'none' }}
                >
                  Account Settings
                </Link>
              </p>
              <p onClick={handleLogout}>Sign Out</p>
            </div>
          )}
        </div>
      ) : null}
      {mobileView ? null : (
        <Link className="navbar-brand" to="/">
          <img src={logo} className={classes.logo} alt="Realto" />
        </Link>
      )}

      {userInfo ? (
        <div
          className={
            mobileView
              ? 'collapse navbar-collapse'
              : 'collapse navbar-collapse d-flex justify-content-end'
          }
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link" to="/" >
                Home <span className="sr-only">(current)</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/marketplaces">
                Market place
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/dashboard">
                Dashboard
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  mobileView ? 'nav-link' : 'nav-link seperatorLine pr-4'
                }
                to="/journal"
              >
                Journal
              </Link>
            </li>

            {mobileView ? null : (
              <>
                <li>
                  <NotificationsNoneOutlinedIcon
                    className={classes.notification}
                  />
                </li>
                <li>
                  <img
                    src="/assets/images/profile.svg"
                    alt="Profile"
                    width="30px"
                    height="30px"
                  />
                  <label
                    className="profileName"
                    onClick={() => SetToggleMenu(!toggleMenu)}
                  >
                    {userInfo.firstname} {userInfo.lastname}
                    <img
                      src="/assets/icons/arrow-down.svg"
                      className="ml-2"
                      alt="down"
                      width="14px"
                    />
                  </label>
                  {toggleMenu && (
                    <div className="profileMenu">
                      <p>
                        <Link
                          to="/wish-list"
                          onClick={closeMenu}
                          style={{ color: '#1f1f1f', textDecoration: 'none' }}
                        >
                          My Wishlist
                        </Link>
                      </p>
                      <p>
                        <Link
                          to="/account"
                          onClick={closeMenu}
                          style={{ color: '#1f1f1f', textDecoration: 'none' }}
                        >
                          Account Settings
                        </Link>
                      </p>
                      <p onClick={handleLogout}>Sign Out</p>
                    </div>
                  )}
                </li>
              </>
            )}
          </ul>
        </div>
      ) : (
        <div
          className="collapse navbar-collapse d-flex justify-content-end"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link" to="/marketplaces">
                Market place
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link seperatorLine pr-4" to="/journal">
                Journal
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/signin">
                <Button className={classes.loginBtn}>Login</Button>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/signup">
                <Button className={classes.gettingStarted}>Get Started</Button>
              </Link>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
};

export default Header;

// function ElevationScroll(props) {
//   const { children } = props;

//   const trigger = useScrollTrigger({
//     disableHysteresis: true,
//     threshold: 0,
//   });

//   return React.cloneElement(children, {
//     elevation: trigger ? 4 : 0,
//   });
// }

// function Header() {
// const classes = useStyles();
// const userLogin = useSelector((state) => state.userLogin);
// const dispatch = useDispatch();
// const theme = useTheme();
// const { userInfo } = userLogin;
// const handleLogout = () => {
//   console.log('check reducer');
//   dispatch(logout());
// };
//   const matches = useMediaQuery(theme.breakpoints.down('md'));
//   const [value, setValue] = useState(0);
//   const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
//   const [openDrawer, setOpenDrawer] = useState(false);

//   const handleChange = (e, value) => {
//     setValue(value);
//   };

//   useEffect(() => {
//     if (window.location.pathname === '/' && value !== 0) {
//       setValue(0);
//     } else if (window.location.pathname === '/about' && value !== 0) {
//       setValue(2);
//     } else if (window.location.pathname === '/contact' && value !== 0) {
//       setValue(3);
//     } else if (window.location.pathname === '/voyage' && value !== 0) {
//       setValue(4);
//     }
//   }, [value]);
//   const tabs = (
//     <React.Fragment>
//       {userInfo ? (
//         <>
//           <div onChange={handleChange} className={classes.tabContainer}>
//             <Button
//               className={clsx(classes.market, classes.navItem)}
//               to="/"
//               disableRipple
//               exact
//               component={NavLink}
//               activeClassName="selected"
//               activeStyle={{ color: '#FECF0E' }}
//             >
//               Home
//             </Button>
//             <Button
//               exact
//               className={clsx(classes.market, classes.navItem)}
//               disableRipple
//               to="/marketplaces"
//               component={NavLink}
//               activeClassName="selected"
//               activeStyle={{ color: '#FECF0E' }}
//             >
//               Market Place
//             </Button>
//             <Button
//               exact
//               className={clsx(classes.market, classes.navItem)}
//               disableRipple
//               to="/dashboard"
//               component={NavLink}
//               activeClassName="selected"
//               activeStyle={{ color: '#FECF0E' }}
//             >
//               Dashboard
//             </Button>
//             <Button
//               exact
//               className={clsx(classes.market, classes.navItem)}
//               disableRipple
//               to="/account"
//               component={NavLink}
//               activeClassName="selected"
//               activeStyle={{ color: '#FECF0E' }}
//             >
//               Account
//             </Button>
//             <Button
//               exact
//               className={clsx(classes.market, classes.navItem)}
//               disableRipple
//               to="/journal"
//               component={NavLink}
//               activeClassName="selected"
//               activeStyle={{ color: '#FECF0E' }}
//             >
//               Journal
//             </Button>
//             <NotificationsNoneOutlinedIcon className={classes.notification} />
//             <Button
//               component={Link}
//               to="#"
//               onClick={handleLogout}
//               className={clsx(classes.navItem, classes.market)}
//             >
//               Logout
//             </Button>
//           </div>
//         </>
//       ) : (
//         <>
//           <div value={value} onChange={handleChange}>
//             <Button
//               variant="contained"
//               disableRipple
//               to="/signin"
//               component={NavLink}
//               activeClassName="selected"
//               // activeStyle={{ background: 'white' }}
//               className={clsx(classes.login)}
//             >
//               Login
//             </Button>

//             <Button
//               disableRipple
//               variant="contained"
//               activeClassName="selected"
//               // activeStyle={{ background: 'white', color: '#1F1F1F' }}
//               className={clsx(classes.voyage)}
//               to="/signup"
//               component={NavLink}
//             >
//               Get Started
//             </Button>
//           </div>
//         </>
//       )}
//     </React.Fragment>
//   );
//   const drawer = (
//     <React.Fragment>
//       <SwipeableDrawer
//         open={openDrawer}
//         className={(classes.drawer, classes.drawerTemplate)}
//         onClose={() => setOpenDrawer(false)}
//         onOpen={() => setOpenDrawer(true)}
//         disableBackdropTransition={!iOS}
//         disableDiscovery={iOS}
//       >
//         {userInfo ? (
//           <>
//             <List disablePadding>
//               <ListItem
//                 onClick={() => {
//                   setOpenDrawer(false);
//                   setValue(0);
//                 }}
//                 divider
//                 button
//                 selected={value === 0}
//                 component={Link}
//                 to="/"
//               >
//                 <ListItemText className={classes.drawerItem} disableTypography>
//                   Home
//                 </ListItemText>
//               </ListItem>

//               <ListItem
//                 onClick={() => {
//                   setOpenDrawer(false);
//                   setValue(2);
//                 }}
//                 divider
//                 button
//                 component={Link}
//                 to="/marketplaces"
//               >
//                 <ListItemText className={classes.drawerItem} disableTypography>
//                   Market Places
//                 </ListItemText>
//               </ListItem>

//               <ListItem onClick={handleLogout} divider button component={Link}>
//                 <ListItemText className={classes.drawerItem} disableTypography>
//                   Logout
//                 </ListItemText>
//               </ListItem>
//             </List>
//           </>
//         ) : (
//           <>
//             <List disablePadding>
//               <ListItem
//                 onClick={() => {
//                   setOpenDrawer(false);
//                   setValue(0);
//                 }}
//                 divider
//                 button
//                 selected={value === 0}
//                 component={Link}
//                 to="/"
//               >
//                 <ListItemText className={classes.drawerItem} disableTypography>
//                   Realto
//                 </ListItemText>
//               </ListItem>

// <ListItem
//   onClick={() => {
//     setOpenDrawer(false);
//     setValue(3);
//   }}
//   divider
//   button
//   component={Link}
//   to="/signin"
// >
//   <ListItemText className={classes.drawerItem} disableTypography>
//     Login
//   </ListItemText>
// </ListItem>
// <ListItem
//   onClick={() => {
//     setOpenDrawer(false);
//     setValue(3);
//   }}
//   divider
//   button
//   component={Link}
//   to="/signup"
// >
//   <ListItemText className={classes.drawerItem} disableTypography>
//     Get Started
//   </ListItemText>
// </ListItem>
//             </List>
//           </>
//         )}
//       </SwipeableDrawer>
//       <IconButton
//         className={classes.drawerIconContainer}
//         onClick={() => setOpenDrawer(!openDrawer)}
//         disableRipple
//       >
//         <MenuIcon className={classes.drawerIcon} />
//       </IconButton>
//     </React.Fragment>
//   );

//   return (
//     <>
//       <ElevationScroll>
//         <AppBar position="fixed" style={{ background: 'white' }}>
//           <Container maxWidth="lg">
//             <Toolbar disableGutters className={classes.toolbar}>
//               <img src={logo} className={classes.logo} alt="Realto" />
//               <nav className={classes.nav}>{matches ? drawer : tabs}</nav>
//             </Toolbar>
//           </Container>
//         </AppBar>
//       </ElevationScroll>
//       <div className={classes.toolbarMargin} />
//     </>
//   );
// }

// export default Header;
