import React, { useEffect, useState, useRef } from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import InfiniteScroll from 'react-infinite-scroll-component';

import { useDispatch, useSelector } from 'react-redux';
import { fetchProperties } from './../../store/properties/properties.actions';

import PropertyListing from './PropertyListing';
import FiltersContainer from './FiltersContainer';
import PropertyListingsLoader from './PropertyListingsLoader';
import Select from 'react-select';
import CustomFilterDropdown from './MarketplaceSorting/components/CustomFilterDropdown';
import Chat from '../Chatbot/Chat'

const useStyles = makeStyles((theme) => ({
  marketPlaceContainer: {
    margin: theme.spacing(2, 'auto', 2),
  },
  root: {
    boxShadow: 'none',
  },
  flexGrow: {
    flexGrow: 1,
  },
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit',
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit',
    },
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100,
  },
  searchPopperContent: {
    marginTop: theme.spacing(1),
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.white,
    backgroundColor: '#43aa8b',
    '&:hover': {
      backgroundColor: '#43aa8b',
    },
  },
  trialIcon: {
    marginRight: theme.spacing(1),
  },
  notificationsButton: {
    marginLeft: theme.spacing(1),
  },
  notificationsBadge: {
    backgroundColor: '#43aa8b',
  },
  logoutButton: {
    marginLeft: theme.spacing(1),
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
  },

  filterContainer: {
    margin: theme.spacing(4, 0),
  },
  propertyInfoIconText: {
    display: 'flex',
  },
  loaderCard: {
    boxShadow: '2px 4px 4px #ccc',
    borderRadius: '4px',
    maxWidth: '100%',
  },
  filterButtonChecked: {
    background: '#DFE9F6ff',
    border: '1px solid #DFE9F6',
    paddingRight: '2rem',
    borderRadius: '2px',
  },
  filterButtonUnChecked: {
    border: '1px solid #DFE9F6',
    paddingRight: '2rem',
    borderRadius: '2px',
  },
  // card styles
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  tagsFilterContent: {
    padding: theme.spacing(1),
  },

  // arrows color to be updated as per the design
  arrows: {
    fill: 'darkgrey',
  },
  // margin topbottom for all properties section
  marginTopBottom: {
    margin: theme.spacing(2, 0),
  },
}));

const PropertyListings = (props) => {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(1);
  const firstRender = useRef(true);
  // filters properties
  const [allFilters, setAllFilters] = useState({
    type: [],
    roi: [],
    yields: [],
    investment: [],
    holdPeriod: [],
    location: [],
  });
  const [keyword, setKeyword] = useState('');

  const propertyListings = useSelector((state) => state.propertyListings);
  const { loading, hasMore, properties, error } = propertyListings;

  const dispatch = useDispatch();

  const fetchMoreData = () => {
    let filters = allFilters;
    // dispatch(fetchProperties(currentPage, filters));
    let { type, roi, yields, investment, holdPeriod, location } = filters;

    if (
      type.length === 0 &&
      roi.length === 0 &&
      yields.length === 0 &&
      investment.length === 0 &&
      holdPeriod.length === 0
    ) {
      dispatch(fetchProperties(currentPage));
    } else if (
      type.length !== 0 &&
      roi.length === 0 &&
      yields.length === 0 &&
      investment.length === 0 &&
      holdPeriod.length === 0
    ) {
      dispatch(fetchProperties(currentPage, { type }));
    } else if (
      type.length === 0 &&
      roi.length !== 0 &&
      yields.length === 0 &&
      investment.length === 0 &&
      holdPeriod.length === 0
    ) {
      dispatch(fetchProperties(currentPage, { roi }));
    } else if (
      type.length === 0 &&
      roi.length === 0 &&
      yields.length !== 0 &&
      investment.length === 0 &&
      holdPeriod.length === 0
    ) {
      dispatch(fetchProperties(currentPage, { yields }));
    } else if (
      type.length === 0 &&
      roi.length === 0 &&
      yields.length === 0 &&
      investment.length !== 0 &&
      holdPeriod.length === 0
    ) {
      dispatch(fetchProperties(currentPage, { investment }));
    } else if (
      type.length === 0 &&
      roi.length === 0 &&
      yields.length === 0 &&
      investment.length === 0 &&
      holdPeriod.length !== 0
    ) {
      dispatch(fetchProperties(currentPage, { holdPeriod }));
    }

    setCurrentPage((currentPage) => currentPage + 1);
  };

  useEffect(() => {
    console.log(allFilters);
    console.log(keyword);
    let filters = allFilters;
    let { type, roi, yields, investment, holdPeriod, location } = filters;
    if (
      type.length === 0 &&
      roi.length === 0 &&
      yields.length === 0 &&
      investment.length === 0 &&
      holdPeriod.length === 0
    ) {
      dispatch(fetchProperties(currentPage));
    } else if (
      type.length === 0 &&
      roi.length === 0 &&
      yields.length === 0 &&
      investment.length === 0 &&
      holdPeriod.length === 0 &&
      keyword.length !== 0
    ) {
      dispatch(fetchProperties(currentPage, keyword));
    } else {
      dispatch(
        fetchProperties(
          currentPage,
          {
            type,
            roi,
            yields,
            investment,
            holdPeriod,
          },
          keyword,
        ),
      );
    }

    setCurrentPage((currentPage) => currentPage + 1);
  }, [allFilters, keyword]);

  console.log('🔥');

  
  const [selectedOption, setSelectedOption] = useState('');

  
  if (error && error.length !== 0 && !loading) {
    return <div>Please refresh the page again.</div>;
  }
  if (!properties) {
    return <div></div>;
  }


  const [query, setQuery] = useState('');
  const [filteredData, setfilteredData] = useState([]);
  const [searchisOn, setSearchOn] = useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;



  // useEffect(() => {
  //   props.setKeyword(query);
  // }, [query]);

 
  const handleSearch=(e)=>{
    e.length>0 ?(

      setQuery(e),
      setSearchOn(true)
      

    ):(
      setSearchOn(false),
      setQuery('')

    )
  }

  const newData =properties.filter((i)=>{
    return i.name.toLowerCase().includes(query.toLowerCase())
  })


  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      width: 150,
    }),
    control: (provided, state) => ({
      ...provided,
      border: '1px solid #FBAF62',
      width: 150,
    }),
    menu: (provided, state) => ({
      ...provided,
      border: '1px solid #FBAF62',
      width: 150,
    }),
    
  };
  // useEffect(()=>{
  //   const config = {
  //     headers: {
  //       Authorization: 'Bearer ' + userInfo.accessToken,
  //       'Content-Type': 'application/json',
  //     },
   
  //   };
  //   // let res = await axios.get(
  //   //   `https://server.realto.estate/api/v2/watchlist`,
  //   //   config,
  //   // );
  //   query.length>0?
  //   fetch('https://server.realto.estate/api/v2/property/search?keyword='+query +'&per_page=10&page=1&fields=_id%20name%20marketing.images',config).then((res)=>console.log(res.json().then((data)=>{setfilteredData(data),console.log(data)}))).catch((err)=>console.log(err)):null
    
  // },[query])
  const disDropper =[{
    value:'Recently Listed',
    label:'Recently Listed'
  },{
    value:'IRR (High - Low)',
    label:'IRR (High - Low)'
  },{
    value:'Avg. Cash Yield (High - Low)',
    label:'Avg. Cash Yield (High - Low)'
  },{
    value:'Minimum Investment (Low - High)',
    label:'Minimum Investment (Low - High)'
  },
  {
    value:'Minimum Hold Period (Low - High)',
    label:'Minimum Hold Period (Low - High)'
  }
]


const handleSort =(e)=>{
  setSelectedOption(e);  
console.log(selectedOption);

  switch(e){
    case 'IRR (High - Low)':
      properties.sort(function(a,b){
        return b.roi.IRR - a.roi.IRR
      });
      
      break;
    
      case 'Avg. Cash Yield (High - Low)':
        properties.sort(function(a,b){

          return b.roi.averageCashYield - a.roi.averageCashYield
        });
        break;

        case 'Minimum Investment (Low - High)':
          properties.sort(function(a,b){
            return a.token.minimumInvestment - b.token.minimumInvestment
          });
          break;


          case 'Minimum Hold Period (Low - High)':
            properties.sort(function(a,b){
              return a.roi.holdPeriod - b.roi.holdPeriod
            });
            break;


    default:
    return properties
  }

  // if(e ==='IRR (High - Low)')
  // {
  //   properties.sort(function(a,b){
  //     return a.roi.IRR - b.roi.IRR
  //   })
  //   console.log(properties)
  // }
}




console.log(selectedOption)
  return (
    <Container maxWidth="lg" className={classes.marketPlaceContainer}>
      {window.location.pathname === '/' ? null : (
        <>
          <FiltersContainer
            handleAllFilters={setAllFilters}
            allFilters={allFilters}
            // setKeyword={()=>console.log(setKeyword)}
            onSearch={(e)=> handleSearch(e.target.value)}
          />
          <Grid
            container
            spacing={2}
            alignItems="center" 
            justify="space-between"
            className={classes.marginTopBottom}
          >
            <Grid item xs={6}>
              <Typography variant="h5" style={{fontWeight:600, fontSize:24}}>{query.length>0?newData.length+" results found": "All Properties"}</Typography>
            </Grid>
            <Grid item xs={6} container justify="flex-end">
            <Select
      styles={customStyles}
      value={selectedOption}
        onChange={(e)=>{handleSort(e.label)}}
      options={disDropper}
      isSearchable={false}
      placeholder={selectedOption !==''? selectedOption:'Sort By'}
    />
            </Grid>
          </Grid>
        </>
      )}
      {window.location.pathname !== '/wish-list' && (
        <InfiniteScroll
          dataLength={properties.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<PropertyListingsLoader />}
          endMessage={
            <p>
              <b></b>
            </p>
          }
        >
          <Grid container spacing={4}>
          
          {
            query.length>0 && newData.length>0?
            (
              newData.map(({ _id, ...otherPropertyProps }) => (
                <Grid item xs={12} sm={6} md={3} key={_id}>
                  <PropertyListing
                    key={_id}
                    id={_id}
                    history={props.history}
                    match={props.path}
                    {...otherPropertyProps}
                    // address={property.address}
                    // marketing={property.marketing}
                    // name={property.name}
                    // roi={property.roi}
                    // token={property.token}
                  />
                </Grid>
              ))

            ):
            (
              properties.map(({ _id, ...otherPropertyProps }) => (
                <Grid item xs={12} sm={6} md={3} key={_id}>
                  <PropertyListing
                    key={_id}
                    id={_id}
                    history={props.history}
                    match={props.path}
                    {...otherPropertyProps}
                    // address={property.address}
                    // marketing={property.marketing}
                    // name={property.name}
                    // roi={property.roi}
                    // token={property.token}
                  />
                </Grid>
              ))
            )
          }
            {}
          </Grid>
        </InfiniteScroll>
      )}

      {window.location.pathname === '/wish-list' && (
        <InfiniteScroll
          dataLength={properties.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<PropertyListingsLoader />}
        >
          <Grid container spacing={4}>

            
            {properties.map(({ _id, ...otherPropertyProps }) => (
              <>
                {props.wishListData[0] === _id && (
                  <Grid item xs={12} sm={6} md={3} key={_id}>
                    {console.log('_id', _id)}
                    {props.wishListData.map((data, id) => {
                      if (data === _id) {
                        return (
                          <PropertyListing
                            key={_id}
                            id={_id}
                            history={props.history}
                            match={props.path}
                            {...otherPropertyProps}
                            // address={property.address}
                            // marketing={property.marketing}
                            // name={property.name}
                            // roi={property.roi}
                            // token={property.token}
                          />
                        );
                      }
                    })}
                  </Grid>
                )}
              </>
            ))}
          </Grid>
        </InfiniteScroll>

      )}
       {/* <Chat openChat={false}   /> */}
    </Container>
  );
};

export default PropertyListings;
