import React, {useState, useEffect} from 'react';
import {
    Container,
    Grid,
    makeStyles,
    Paper
} from '@material-ui/core';
import Select from 'react-select';

const options = [{"value":"s","label":"Small"},{"value":"m","label":"Medium"},{"value":"l","label":"Large"}];


const useStyles = makeStyles((theme) => ({

}));

const propertyTypes = ["All", "0 - $5,000", "$5,000 - $10,000", "$11,000 - $30,000", "$31,000 - $50,000", "$51,000- $100,000", "More than $100,000"];

const Range = (props) => {
    const classes = useStyles();
    const [isSearchable] = useState(true);
    const [range, setRange] = useState("All");

    const selectRangeData = (data) => {
        setRange(data);
    }

    useEffect(() => {
        if(props.rangeData){
            setRange(props.rangeData);
        }
    }, [])

    return(
        <div>
            <h3 className="mt-4">Investment range</h3>
            <p>Select preferred investment amount range</p>
            
            <div style={{position: 'relative'}}>
            {(propertyTypes.map((data, id) => {
                if(range === data){
                    return(
                        <label key={id} className="locationBorderSelectedBox" onClick={() => selectRangeData(data)}>{data}</label>
                    )
                }else{
                    return(
                        <label key={id} className="locationBorderBox" onClick={() => selectRangeData(data)}>{data}</label>
                    )
                }
            }))}
            </div>
            <Grid container spacing={4} className="mt-5">
                <Grid item xs={12} sm={12} md={2} lg={2}>
                    <button className="btnYellowBorder" style={{width: "100%", background: "#F5F5F5", border: "none"}} onClick={() => props.previousStep3()}>Back</button> 
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                    <button className="btnYellowBorder" style={{width: "100%"}} onClick={() => props.finishInvest(range)}>Finish</button>
                </Grid>
            </Grid>
            
        </div>
    )
}

export default Range;

