import React, {useState, useEffect} from 'react';
import {
    Container,
    Grid,
    makeStyles,
    Paper
} from '@material-ui/core';
import newsBlogs from './../../static/home.json';
import notification from './../../static/notification.json';
import topicsDetails from './../../static/topics.json';
import PropertyListings from './../../components/MarketPlace/PropertyListings';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getBaseUrl } from '../../utils/request';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4),
        marginBottom: theme.spacing(7),
      }
}));

const Tabs = (props) => {

    const [selected, setSelected] = useState(props.selected || 0)
  
    const handleChange = (index) => {
      setSelected(index);
    }
  
      return (
      <div>
        <ul className="inline">
          {props.children.map((elem,index)=>{
            let style = index == selected ? 'selected': '';
           return (
           <li className={style} key={index} onClick={() => handleChange(index)}>{elem.props.title}</li>)
          })}
        </ul>
        <div className="tab p-0">{props.children[selected]}</div>
      </div>
      )
  }
  
  const Panel = (props) => {
      return <div>{props.children}</div>
  }
  
  

const WishList = () => {
    const classes = useStyles();
    const [wishListData , setWishListData] = useState([]);
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    useEffect(() => {
        const config = {
            headers: {
            'Authorization': 'Bearer ' + userInfo.accessToken,
            'page': 1,
            'per_page': 10,
            'Content-Type': 'application/json',
            },
        }
        axios.get(getBaseUrl('watchlist'),  config)
        .then(res => {
            console.log("res", res);
            setWishListData(res.data.propertyId);
        })
    }, [])
    return(
        <div>
            <PropertyListings wishListData={wishListData} />
            <Container className={classes.container}>
            <Grid container className="mt-4">
            <Tabs selected={0}>
                  <Panel title="Investment Updates">
                  <Grid container>
                  {(newsBlogs.map((news, id) => {
                    return(
                      <Grid sm={12} md={4} lg={4} key={id}>
                        <div style={{margin: "20px"}}>
                            <img src={news.img} alt={news.title} width="100%" />
                            <p className="pt-3"><b>{news.title}</b></p>
                            <p className="contentCls">{news.content}</p>
                        </div>
                      </Grid>
                    )
                  }))}
                  <button className="btnCls" style={{float: "right"}}>Show more Updates</button>
                  </Grid>
                  </Panel>
                  <Panel title="Property Type">
                  <Grid container>
                  {(newsBlogs.map((news, id) => {
                    return(
                      <Grid sm={12} md={4} lg={4} key={id}>
                        <div style={{margin: "20px"}}>
                            <img src={news.img} alt={news.title} width="100%" />
                            <p className="pt-3"><b>{news.title}</b></p>
                            <p className="contentCls">{news.content}</p>
                        </div>
                      </Grid>
                    )
                  }))}
                  <button className="btnCls" style={{float: "right"}}>Show more Webinars</button>
                  </Grid>
                  </Panel>
                  <Panel title="Webinars">
                  <Grid container>
                  {(newsBlogs.map((news, id) => {
                    return(
                      <Grid sm={12} md={4} lg={4} key={id}>
                        <div style={{margin: "20px"}}>
                            <img src={news.img} alt={news.title} width="100%" />
                            <p className="pt-3"><b>{news.title}</b></p>
                            <p className="contentCls">{news.content}</p>
                        </div>
                      </Grid>
                    )
                  }))}
                  <button className="btnCls" style={{float: "right"}}>Show more Webinars</button>
                  </Grid>
                  </Panel>
                  <Panel title="News & Blogs">
                  <Grid container>
                  {(newsBlogs.map((news, id) => {
                    return(
                      <Grid sm={12} md={4} lg={4} key={id}>
                        <div style={{margin: "20px"}}>
                            <img src={news.img} alt={news.title} width="100%" />
                            <p className="pt-3"><b>{news.title}</b></p>
                            <p className="contentCls">{news.content}</p>
                        </div>
                      </Grid>
                    )
                  }))}
                  <button className="btnCls" style={{float: "right"}}>Show more news</button>
                  </Grid>
                  </Panel>
                </Tabs>
                </Grid>
                </Container>
        </div>
    )
}

export default WishList;

