import React from 'react';
import ReactPlayer from 'react-player';

const videos = [
  'https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4',
  ,
  'https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4',
];
const WebinarPlayer = ({ webinar }) => (
  <div className="player-wrapper">
    <ReactPlayer
      url={webinar.length !== 0 ? webinar[0] : videos[0]}
      className="react-player"
      width="100%"
      height="100%"
      controls={true}
    />
  </div>
);

export default WebinarPlayer;
