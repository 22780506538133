import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepButton from '@material-ui/core/StepButton';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ConfirmInvestment from './ConfirmInvestment';
import CloseIcon from '@material-ui/icons/Close';
import {IconButton} from '@material-ui/core'
import ScheduleMeeting from './ScheduleMeeting';
import ConfirmMeetingDetails from './ConfirmMeetingDetails';
import { InvestNowSectionDataProvider } from '../../../../context/investnow-context';

import './investnow.css';
import FinalStepDetails from './FinalStepDetails';
import { getBaseUrl } from '../../../../utils/request';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    margin: '0px auto',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  btnCls: {
    background: '#FDC424 0% 0% no-repeat padding-box',
    borderRadius: 10,
    marginRight: 20,
  },
  stepButtonDefault: {
    width: 100,
    height: 30,
    background: '#fff',
    borderRadius: 6,
    zIndex: 11,
    border: '1px solid #C5CACE',
    '& .MuiStepIcon': {
      stroke: '#000',
      color: '#fff',
    },
    '& .MuiStepIcon-active': {
      opacity: 1,
      stroke: '#fff',
      color: '#FDC424',
      strokeWidth:3
    },
    '& .MuiStepIcon-completed': {
      color: '#fff',
      stroke: 'none',
      path: {
        stroke: 'none',
      },
    },
  },
  stepButton: {
    width: 100,
    height: 30,
    background: '#FDC424',
    zIndex: 11,
    borderRadius: 6,
    '& .MuiStepIcon': {
      stroke: '#000',
      color: '#fff',
    },
    '& .MuiStepIcon-active': {
      opacity: 1,
      stroke: '#fff',
      color: '#FDC424',
      marginLeft: 40,
    },
    '& .MuiStepIcon-completed': {
      color: '#fff',
      stroke: 'none',
      path: {
        stroke: 'none',
      },
    },
  },
  stepIcon: {
    color: '#fff',
    stroke: '#000',
    marginLeft: 40,
  },
  stepIconCircle: {
    color: '#fff',
    stroke: '#000',
    strokeWidth:2,
    border: 2,
    borderColor:"#fff"
  },
  stepIconText: {
    display: 'none',
  },
  stepTextCls: {
    position: 'absolute',
    top: 10,
    left: 16,
    fontSize: 17,
  },
}));

function getSteps() {
  return ['Amount', 'Schedule Call', 'Confirm'];
}

const InvestNow = (props) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState(new Set());
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();
  const classes = useStyles();

  const totalSteps = () => {
    return getSteps().length;
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const skippedSteps = () => {
    return skipped.size;
  };

  const completedSteps = () => {
    return completed.size;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps() - skippedSteps();
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed
          // find the first step that has been completed
          steps.findIndex((step, i) => !completed.has(i))
        : activeStep + 1;

    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = new Set(completed);
    newCompleted.add(activeStep);
    setCompleted(newCompleted);

    /**
     * Sigh... it would be much nicer to replace the following if conditional with
     * `if (!this.allStepsComplete())` however state is not set when we do this,
     * thus we have to resort to not being very DRY.
     */
    if (completed.size !== totalSteps() - skippedSteps()) {
      handleNext();
    }
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted(new Set());
    setSkipped(new Set());
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  function isStepComplete(step) {
    return completed.has(step);
  }
  const handleClose = () => {
    setOpenDialog(false);
    // props.setDefault();
    setActiveStep(0);
    setCompleted(new Set());
    setSkipped(new Set());
  };

  useEffect(() => {
 setOpenDialog(props.openDialog)
   
  }, [props.openDialog]);

  const scheduleMeeting = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios.post(
      getBaseUrl('meeting'),
      config,
    );
  };

  console.log('activeStep', activeStep);
  return (
    <div>
      <InvestNowSectionDataProvider>
        <Dialog
          fullScreen={fullScreen}
          open={openDialog}
          onClose={handleClose}
          aria-labelledby="Invest Here"
          maxWidth={'md'}
          fullWidth={true}
        >
          <DialogTitle>
            <div style={{width:"100%", display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-between"}}>
            <h2>Invest Now</h2>
            <IconButton onClick={handleClose}>
            <CloseIcon />
            </IconButton>
         
            </div>
           
          </DialogTitle>
          {/* <DialogTitle id="responsive-dialog-title">{"Invest Now"}</DialogTitle> */}
          <DialogContent>
            <Stepper
              alternativeLabel
              nonLinear
              activeStep={activeStep}
              className={classes.root}
            >
              {steps.map((label, index) => {
                const stepProps = {};
                const buttonProps = {};
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepButton
                      //onClick={handleStep(index)}
                      completed={isStepComplete(index)}
                      {...buttonProps}
                      classes={{
                        root:
                          activeStep === index || isStepComplete(index)
                            ? classes.stepButton
                            : classes.stepButtonDefault,
                      }}
                    >
                      <StepLabel
                        StepIconProps={{
                          classes: {
                            root: classes.stepIcon,
                            circle:
                              activeStep === index
                                ? classes.stepIconCircle
                                : classes.stepIcon,
                            text: classes.stepIconText,
                          },
                        }}
                      >
                        <label className={classes.stepTextCls}>
                          Step {index + 1}
                        </label>
                        {label}
                      </StepLabel>
                    </StepButton>
                  </Step>
                );
              })}
            </Stepper>
            <div>
              {allStepsCompleted() ? (
                <div>
                  <FinalStepDetails />
                  <Button onClick={handleReset}>Reset</Button>
                </div>
              ) : (
                <div>
                  <Typography className={classes.instructions}>
                    {activeStep === 0 && <ConfirmInvestment />}
                    {activeStep === 1 && <ScheduleMeeting />}
                    {activeStep === 2 && <ConfirmMeetingDetails />}
                  </Typography>
                  <div>
                    <button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className="btnYellowBorder pl-4 pr-4 float-left mb-5 mt-4"
                    >
                      Back
                    </button>
                    {/* <button
                onClick={handleNext}
                className="btnYellowBorder pl-4 pr-4 float-right mb-5 mt-4"
              >
                Next
              </button> */}
                    {/* {isStepOptional(activeStep) && !completed.has(activeStep) && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSkip}
                  className={classes.button}
                >
                  Skip
                </Button>
              )} */}

                    {activeStep !== steps.length &&
                      (completed.has(activeStep) ? (
                        <Typography
                          variant="caption"
                          className={classes.completed}
                        ></Typography>
                      ) : (
                        <button
                          className="btnYellowBorder pl-4 pr-4 float-right mb-5 mt-4"
                          onClick={handleComplete}
                          style={{fontSize:15}}
                        >
                          {completedSteps() === totalSteps() - 1
                            ? 'Finish'
                            : 'Continue'}
                        </button>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </DialogContent>
        
        </Dialog>
      </InvestNowSectionDataProvider>
    </div>
  );
};

export default InvestNow;
