import React, { useState, useEffect } from 'react';
import { Container, Grid, makeStyles, Paper } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SideBar from './../../components/SideBar';
import './dashboard.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {currConv} from '../../services/currency'
import Select from 'react-select'
import {getBaseUrl} from '../../utils/request'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import SelectDropDown from './components/SelectDropDown';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

// filters table sorting methods

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// table header

const headCells = [
  {
    id: 'investmentDate',
    numeric: false,
    disablePadding: true,
    label: 'Date of Investment',
  },
  {
    id: 'propertyName',
    numeric: false,
    disablePadding: true,
    label: 'Property Name',
  },
  {
    id: 'noOfTokens',
    numeric: true,
    disablePadding: false,
    label: 'No. of Fractions',
  },

  {
    id: 'investments',
    numeric: true,
    disablePadding: false,
    label: 'Investment Amount',
  },
  // {
  //   id: 'currentValue',
  //   numeric: true,
  //   disablePadding: false,
  //   label: 'Current Value',
  // },
  {
    id: 'rentalMonthly',
    numeric: false,
    disablePadding: false,
    label: 'Monthly',
  },
  {
    id: 'caReturn',
    numeric: true,
    disablePadding: false,
    label: 'Cumulative',
  },
  // {
  //   id: 'totalReturn',
  //   numeric: true,
  //   disablePadding: false,
  //   label: 'Return',
  // },
];
const useStylesTableHead = makeStyles((theme) => ({
  tableHeaderCls: {
    background: '#FFF3E7 0% 0% no-repeat padding-box',
    borderRadius: 10,
    padding: '6px 10px',
  },
  tableHeader: {
    padding: '32px 20px 10px 20px',
    position: 'relative',
  },
}));
function EnhancedTableHead(props) {
  const classes = useStylesTableHead();
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHeaderCls}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className={classes.tableHeader}
            align="center"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const data = [
  {
    name: 'Jan',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Feb',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Mar',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Apr',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'May',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Jun',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Jul',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
];

const propertyData = [
  {
    value: 'Prestige Sky High',
    label: 'Prestige Sky High',
  },
  {
    value: 'Shoba Sea View',
    label: 'Shoba Sea View',
  },
];

const COLORS = ['#FBAF62', '#E0706F', '#0088FE', '#00C49F'];

const yearData = [
  { value: '3', label: '3 months' },
  { value: '6', label: '6 months' },
  { value: '9', label: '9 months' },
  { value: '12', label: '12 months' },
  
];

const earningList = [
  { value: 'current', label: 'current' },
  { value: 'sold', label: 'sold' },
];

function createData(data1, data2, data3, data4, data5, data6, data7, data8) {
  return { data1, data2, data3, data4, data5, data6, data7, data8 };
}



const rows = [
  createData(
    '09 JAN 2020',
    'Prestige Sky High',
    '100',
    '$20,000',
    '$20,000',
    '$20,000',
    '$20,000',
    '$20,000',
  ),
  createData(
    '09 JAN 2020',
    'Shoba Sea View',
    '100',
    '$20,000',
    '$20,000',
    '$20,000',
    '$20,000',
    '$20,000',
  ),
];

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
  },
  margin: {
    height: theme.spacing(2),
  },
  marginTop: {
    marginTop: theme.spacing(4),
  },
  tilePaperCls: {
    width: '100%',
    padding: 20,
    marginBottom: 20,
    boxShadow: 'none',
  },
  gridBorder: {
    borderRight: '5px solid #F8F9FC',
  },
  table: {
    minWidth: 650,
  },
  tableHeaderCls: {
    background: '#FFF3E7 0% 0% no-repeat padding-box',
    borderRadius: 10,
  },
  tableHeader: {
    padding: '32px 20px 10px 20px',
    position: 'relative',
  },
  tableData: {
    padding: 25,
  },
  tableTotalCls: {
    background: '#FFF3E7 0% 0% no-repeat padding-box',
    borderRadius: 10,
    padding: '6px 10px',
  },
  chartPaper: {
    padding: 10,
    boxShadow: 'none',
  },
}));

const DashboardEarnings = () => {
  const classes = useStyles();
  const [width, setWidth] = useState(650);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [dashboardData, setDashboardData] = useState([]);
  const [rentTotal, setRentTotal] = useState(0);
  const [incomeTotal, setIncomeTotal] = useState(0);
  const [capitalTotal, setCapitalTotal] = useState(0);
  const [earningTotal, setEarningTotal] = useState(0);
  const [propertyNames, setPropertyNames] = useState([]);
  const [chartData, setChartData] = useState([]);

  const getEarningsChartData = (selectedOption, dashboardDetails) => {
    let getPropertyData = [];
    let getDashboardDetails = dashboardDetails
      ? dashboardDetails
      : dashboardData;
    if (getDashboardDetails.length > 0) {
      getPropertyData = getDashboardDetails.filter((data) => {
        if (data.propertyName[0] == selectedOption.label) {
          return data;
        }
      });
    }
    if (getPropertyData.length > 0) {
      let getChartData = getPropertyData[0].distributions.map((data, id) => {
        if (id < 10) {
          return {
            distribution: data.revenue,
            revenue: data.userRevenue,
            name: moment(data.createdAt).format('MMMM'),
          };
        }
      });
      let finalData = getChartData.filter(function (element) {
        return element !== undefined;
      });
      setChartData(finalData);

    }
  };
  const dummy = [
    {
      name: 'February',
      distribution: 400000,
      revenue: 2400,
      amt: 2400,
    },
    {
      name: 'February',
      distribution: 300000,
      revenue: 1398,
      amt: 2210,
    },
    {
      name: 'February',
      distribution: 200000,
      revenue: 9800,
      amt: 2290,
    },
    {
      name: 'February',
      distribution: 27080,
      revenue: 3908,
      amt: 2000,
    },
    {
      name: 'February',
      distribution: 189000,
      revenue: 4800,
      amt: 2181,
    },
    {
      name: 'February',
      distribution: 23900,
      revenue: 3800,
      amt: 2500,
    },
    {
      name: 'February',
      distribution: 34900,
      revenue: 4300,
      amt: 2100,
    },
  ];

  const dummy2 = [
    {
      name: 'June',
      distribution: 400000,
      revenue: 2400,
      amt: 2400,
    },
    {
      name: 'June',
      distribution: 300000,
      revenue: 1398,
      amt: 2210,
    },
    {
      name: 'June',
      distribution: 200000,
      revenue: 9800,
      amt: 2290,
    },
    {
      name: 'June',
      distribution: 27080,
      revenue: 3908,
      amt: 2000,
    },
    {
      name: 'June',
      distribution: 189000,
      revenue: 4800,
      amt: 2181,
    },
    {
      name: 'June',
      distribution: 23900,
      revenue: 3800,
      amt: 2500,
    },
    {
      name: 'June',
      distribution: 340900,
      revenue: 4300,
      amt: 2100,
    },
  ];

  const dummy3 = [
    {
      name: 'June',
      distribution: 200000,
      revenue: 2400,
      amt: 2400,
    },
    {
      name: 'June',
      distribution: 500000,
      revenue: 1398,
      amt: 2210,
    },
    {
      name: 'June',
      distribution: 3400000,
      revenue: 9800,
      amt: 2290,
    },
    {
      name: 'June',
      distribution: 270080,
      revenue: 3908,
      amt: 2000,
    },
    {
      name: 'June',
      distribution: 189000,
      revenue: 4800,
      amt: 2181,
    },
    {
      name: 'June',
      distribution: 239000,
      revenue: 3800,
      amt: 2500,
    },
    {
      name: 'June',
      distribution: 340900,
      revenue: 4300,
      amt: 2100,
    },
  ];

  const dummy4 = [
    {
      name: 'June',
      distribution: 600000,
      revenue: 2400,
      amt: 2400,
    },
    {
      name: 'June',
      distribution: 200000,
      revenue: 1398,
      amt: 2210,
    },
    {
      name: 'June',
      distribution: 530000,
      revenue: 9800,
      amt: 2290,
    },
    {
      name: 'June',
      distribution: 23080,
      revenue: 3908,
      amt: 2000,
    },
    {
      name: 'June',
      distribution: 439000,
      revenue: 4800,
      amt: 2181,
    },
    {
      name: 'June',
      distribution: 220900,
      revenue: 3800,
      amt: 2500,
    },
    {
      name: 'June',
      distribution: 340900,
      revenue: 4300,
      amt: 2100,
    },
  ];
  

  


  useEffect(() => {
    if (window.outerWidth < 576) {
      setWidth(window.outerWidth - 70);
    }
  }, [window.outerWidth]);

  const disDropper =[{
    value:'Celina Station',
    label:'Celina Station'
  },{
    value:'DLF Cybercity',
    label:'DLF Cybercity'
  },{
    value:'Celina Station Land',
    label:'Celina Station Land'
  },{
    value:'DLF Cybercity',
    label:'DLF Cybercity'
  }]

  useEffect(() => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + userInfo.accessToken,
        'Content-Type': 'application/json',
      },
      query: {
        page: 1,
        per_page: 10,
      },
    };
    axios
      .get(getBaseUrl('earnings/distribution'), config)
      .then((res) => {
        console.log('res', res);
        setDashboardData(res.data);

        let rentArr = res.data.map((data) => {
          return data.rentalCumulative;
        });
        setRentTotal(rentArr.reduce((partial_sum, a) => partial_sum + a, 0));

        let capitalArr = res.data.map((data) => {
          return data.rentalMonthly;
        });
        setCapitalTotal(
          capitalArr.reduce((partial_sum, a) => partial_sum + a, 0),
        );

        let earningArr = res.data.map((data) => {
          return data.totalReturn;
        });
        setEarningTotal(
          earningArr.reduce((partial_sum, a) => partial_sum + a, 0),
        );

        let getPropertyDetails = res.data.map(
          (property) => property.propertyName[0],
        );
        let getPropertyDropDownData = getPropertyDetails.map((data) => {
          return {
            value: data,
            label: data,
            colorsData: COLORS,
          };
        });
        getEarningsChartData(getPropertyDropDownData[0], res.data);
        setPropertyNames(getPropertyDropDownData);
      });
  }, []);

  // investment table
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('propertyName');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    let be=parts.join(".")
    console.log(parts[0])
    let tempArray= parts[0].toString().split(',')
    console.log(tempArray)

    return parts.join(".");

    
}
console.log(numberWithCommas(5000000))


const customStyles = {
  option: (provided, state) => ({
    ...provided,
    width: 150,
  }),
  control: (provided, state) => ({
    ...provided,
    border: '1px solid #FBAF62',
    width: 150,
  }),
  menu: (provided, state) => ({
    ...provided,
    border: '1px solid #FBAF62',
    width: 150,
  }),
  
};

const [quarter, setquarter] = useState('');
const [property, setproperty] = useState('')

useEffect(()=>{
  if(quarter=='3')
  {
    setChartData(dummy)
  }
  if(quarter=='6')
  {
    setChartData(dummy2)
  }
  if(quarter=='9')
  {
    setChartData(dummy3)
  }
  if(quarter=='12')
  {
    setChartData(dummy4)
  }
  
    },[property,quarter])
  


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, dashboardData.length - page * rowsPerPage);


    const [userCountry, setuserCountry] = useState('');
  
useEffect(()=>{
fetch(getBaseUrl('users'),{
  headers:{
    Authorization: 'Bearer ' + userInfo.accessToken,
    'Content-Type': 'application/json',
  }
}).then((res)=>res.json()).then((data)=>setuserCountry(data.address.country)).catch((err)=>console.log(err));
},[])

  return (
    <div>
      <Paper className={classes.tilePaperCls}>
        <Grid container>
          <Grid item xs={6} sm={6} md={6} lg={10}>
            <label className="dashboardTileCls pl-3">My Earnings</label>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            lg={2}
            className="d-flex justify-content-end"
          >
            <SelectDropDown dataDetails={earningList} />
          </Grid>
        </Grid>
      </Paper>
      <Paper className={classes.tilePaperCls}>
        <Grid container style={{ textAlign: 'center' }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={3}
            className={classes.gridBorder}
          >
            <label className="dashboardTileCls">Total Rent</label>
            <br />
            <label className="dashboardTileValueCls">
            {numberWithCommas(currConv(rentTotal.toFixed(2),userCountry))}
              {/* ${numberWithCommas(rentTotal.toFixed(2))} */}
            </label>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={3}
            className={classes.gridBorder}
          >
            <label className="dashboardTileCls">Other Income </label>
            <br />
            <label className="dashboardTileValueCls">  {numberWithCommas(currConv(0,userCountry))}</label>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={3}
            className={classes.gridBorder}
          >
            <label className="dashboardTileCls">Capital Appreciation </label>
            <br />
            <label className="dashboardTileValueCls">
            {numberWithCommas(currConv(capitalTotal.toFixed(2),userCountry))}
              {/* ${numberWithCommas(capitalTotal.toFixed(2))} */}
            </label>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <label className="dashboardTileCls">Total Earnings</label>
            <br />
            <label className="dashboardTileValueCls">
            {numberWithCommas(currConv(capitalTotal.toFixed(2),userCountry))}
              {/* ${numberWithCommas(capitalTotal.toFixed(2))} */}
            </label>
          </Grid>
        </Grid>
      </Paper>

      <Grid container className="mt-3" spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper className={classes.chartPaper}>
            <Grid container className="mb-4">
              <Grid item xs={6} sm={6} md={6} lg={8}>
                <label className="dashboardTileCls pl-3" style={{fontWeight:600}}>
                  Rent distribution
                </label>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={2}
                className="d-flex justify-content-end"
              >
              <Select
                         options={disDropper}
                         styles={customStyles}
                         value={property}
                         onChange={(e)=>{setproperty(e.label)}}
                         placeholder={property?property:'All Properties'}
                        />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={2}
                className="d-flex justify-content-end"
              >
                  <Select
                         options={yearData}
                         styles={customStyles}
                         value={quarter}
                         onChange={(e)=>{setquarter(e.value)}}
                         placeholder={quarter?quarter:'Quarters'}
                        />
                {/* <SelectDropDown
                  dataDetails={yearData}
                  getIncome={getEarningsChartData}
                /> */}
              </Grid>
            </Grid>
            <PerfectScrollbar>
              <div style={{display:"flex", flex:1}}>

              <BarChart width={1000} height={400} data={chartData}  margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}>
                <Tooltip />
                <CartesianGrid strokeDasharray="2 2" />
                <XAxis dataKey="name" axisLine={false} />
                <YAxis axisLine={false} />
                <Bar dataKey="distribution" barSize={20} fill="#FBAF62" />
                <Bar dataKey="revenue" barSize={20} fill="#E0706F" />
              </BarChart>
              </div>
       
            </PerfectScrollbar>
          </Paper>
        </Grid>
      </Grid>

      <Paper className={classes.chartPaper} style={{ marginTop: 20 }}>
        <p className="dashboardTileCls pt-3">Total return table</p>
        <PerfectScrollbar>
          <TableContainer
            component={Paper}
            className="mt-4"
            style={{ boxShadow: 'none' }}
          >
            <Table className={classes.table} aria-label="simple table">
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={dashboardData.length}
              />
              <TableBody>
                {stableSort(dashboardData, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.propertyName);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow key={row.name}>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          className={classes.tableData}
                        >
                          {moment(row.investmentDate).format('ll')}
                        </TableCell>
                        <TableCell align="center" className={classes.tableData}>
                          {row.propertyName[0]}
                        </TableCell>
                        <TableCell align="center" className={classes.tableData}>
                          {row.noOfTokens}
                        </TableCell>
                        <TableCell align="center" className={classes.tableData}>
                        {numberWithCommas(currConv(row.investments,userCountry))}
                          {/* {numberWithCommas(row.investments)} */}
                        </TableCell>
                        {/* <TableCell align="center" className={classes.tableData}>
                          {row.currentValue ? row.currentValue : '-'}
                        </TableCell> */}
                        <TableCell align="center" className={classes.tableData}>
                        {numberWithCommas(currConv(row.rentalMonthly,userCountry))}
                          {/* {numberWithCommas(row.rentalMonthly.toFixed(2))} */}
                        </TableCell>
                        <TableCell align="center" className={classes.tableData}>
                        {numberWithCommas(currConv(row.caReturn.toFixed(2),userCountry))}
                          {/* {numberWithCommas(row.caReturn.toFixed(2))} */}
                        </TableCell>
                        {/* <TableCell align="center" className={classes.tableData}>
                          {numberWithCommas(row.totalReturn.toFixed(2))}
                        </TableCell> */}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dashboardData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </PerfectScrollbar>
      </Paper>
    </div>
  );
};

export default DashboardEarnings;
