import React, {useState} from 'react';
import {
    Container,
    Grid,
    makeStyles,
    Paper
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    cardCls: {
        background: '#FFF9E8 0% 0% no-repeat padding-box',
        borderRadius: 10,
        padding: 14,
        height: '100%'
    }
}));


const OtherDetails = (props) => {
    const classes = useStyles();
    const [isSearchable] = useState(true);
    return(
        <div>
            <h3 className="mt-4">Other Preferences</h3>
            <p>These are the other preferences chosen by you. You can always edit these preferences by clicking the edit button below</p>
            <Grid container spacing={4} className="mt-5">
                <Grid item xs={12} sm={12} md={2} lg={3}>
                    <div className={classes.cardCls}>
                        <p>Newsletter</p>
                        <div>
                            <label className="locationBorderBoxDetails">All</label>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={3}>
                    <div className={classes.cardCls}>
                        <p>Alerts and Notifications- Types</p>
                        <div>
                            <label className="locationBorderBoxDetails">All</label>
                            <label className="locationBorderBoxDetails">All</label>
                            <label className="locationBorderBoxDetails">All</label>
                            <label className="locationBorderBoxDetails">All</label>
                            <label className="locationBorderBoxDetails">All</label>
                            <label className="locationBorderBoxDetails">All</label>
                            <label className="locationBorderBoxDetails">All</label>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={3}>
                <div className={classes.cardCls}>
                        <p>Alerts and Notifications- Methods</p>
                        <div>
                            <label className="locationBorderBoxDetails">All</label>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid container spacing={4} className="mt-5">
                <Grid item xs={12} sm={12} md={2} lg={2}>
                    <button className="btnYellowBorder" style={{width: "100%"}}>Edit preferences</button>
                </Grid>
            </Grid>
            
        </div>
    )
}

export default OtherDetails;

