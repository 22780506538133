import React, {useState} from 'react';
import {
    Container,
    Grid,
    makeStyles,
    Paper
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({

}));

const Investments = () => {
    const classes = useStyles();
    return(
        <div>
            Investments
        </div>
    )
}

export default Investments;

