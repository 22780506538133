import React, { useState } from 'react';
import { Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(4),
  },
  rootPropertyHighlightsList: {
    width: '100%',
  },
  icon: {
    fill: '#FDC424',
    fontSize: '.6rem',
  },
  listIcon: {
    minWidth: '20px',
  },
  listItem: {
    padding: '.1rem',
    borderTop: 'none',
  },
  highlightText: {
    color: 'black',
  },
}));

export default function PropertyHighlightsList({ description }) {
  const classes = useStyles();
  // const [highlights, setHighlights] = useState([]);
  // if (description) {
  //   setHighlights(
  //     description.split('\n').filter((string) => string.length !== 0),
  //   );
  // }
  let highlights = [];
  if (description) {
    highlights = description
      .split('\n')
      .filter((string) => string.length !== 0);
  }

  return (
    <Paper className={classes.wrapper} elevation={0}>
      <Typography variant="h4" gutterBottom style={{ paddingLeft: '.22rem' }}>
        Property Highlights
      </Typography>
      <List
        component="nav"
        className={classes.rootPropertyHighlightsList}
        aria-label="contacts"
      >
        {highlights.map((highlight) => (
          <ListItem className={classes.listItem}>
            <ListItemIcon className={classes.listIcon}>
              <FiberManualRecordIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="body1" className={classes.highlightText}>
                {highlight}
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Paper>
  );
}
