import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Popover, colors } from '@material-ui/core';
import NotificationList from './components/NotificationsList/NotificationsList';

const useStyles = makeStyles(() => ({
  root: {
    width: 280,
    maxWidth: '100%',
  },
  actions: {
    backgroundColor: colors.grey[50],
    justifyContent: 'center',
  },
}));

const NotificationsPopover = (props) => {
  const { notifications, onClose, anchorEl, ...rest } = props;

  const classes = useStyles();
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Popover
      {...rest}
      id={id}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      onClose={onClose}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      elevation={0}
    >
      <div className={classes.root}>
        <NotificationList onClose={onClose} notifications={notifications} />
      </div>
    </Popover>
  );
};

NotificationsPopover.propTypes = {
  anchorEl: PropTypes.any,
  className: PropTypes.string,
  notifications: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default NotificationsPopover;
