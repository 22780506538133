import React, { useEffect, useState, useRef } from 'react';
import { Paper, Input } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SearchIcon from '@material-ui/icons/Search';

import InputAdornment from '@material-ui/core/InputAdornment';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  paper: {
    flexGrow: 0.8,
    marginRight: theme.spacing(1),
    padding: theme.spacing(0.5, 2),
    borderRadius: '10px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '2px solid #C5CACE',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    width: window.innerWidth<768?"100%": 1000,
    padding: '.2rem 0',
    marginLeft: theme.spacing(1),
  },
  searchIcon: {
    color: theme.palette.text.secondary,
  },
}));

const Index = ({ onSearch}) => {
  const classes = useStyles();

  const [query, setQuery] = useState('');
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const handlePropertySearch = (e) => {
    setQuery(e.target.value);
    console.log(e.target.value)
  };

  // useEffect(() => {
  //   props.setKeyword(query);
  // }, [query]);

  // useEffect(()=>{
  //   const config = {
  //     headers: {
  //       Authorization: 'Bearer ' + userInfo.accessToken,
  //       'Content-Type': 'application/json',
  //     },
   
  //   };
  //   // let res = await axios.get(
  //   //   `https://server.realto.estate/api/v2/watchlist`,
  //   //   config,
  //   // );
  //   fetch('https://server.realto.estate/api/v2/property/search?keyword='+query +'&per_page=10&page=1&fields=_id%20name%20marketing.images',config).then((res)=>console.log(res.json().then((data)=>{console.log(data)}))).then((data)=>console.log(data)).catch((err)=>console.log(err))
  
  // },[query])
  return (
    // <TextField
    //   fullWidth
    //   required
    //   size="small"
    //   value={keyword}
    //   variant="outlined"
    //   placeholder="Search by property name,developer or location"
    //   onChange={handlePropertySearch}
    //   InputProps={{
    //     startAdornment: (
    //       <InputAdornment position="start">
    //         <SearchIcon />
    //       </InputAdornment>
    //     ),
    //   }}
    //   className={classes.rootSearch}
    // />
    <Paper className={classes.paper} elevation={0}>
      <SearchIcon className={classes.searchIcon} color="inherit" />
      <Input
        className={classes.input}
        disableUnderline
        
        onChange={onSearch}
        placeholder="Search by Property name, Developer or Location…"
      />
    </Paper>
  );
};

export default Index;
