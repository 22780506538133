import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Card, CardContent, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  marketPlaceContainer: {
    margin: theme.spacing(12, 'auto', 12),
  },

  filterContainer: {
    margin: theme.spacing(4, 0),
  },
  propertyInfoIconText: {
    display: 'flex',
  },
  loaderCard: {
    boxShadow: '2px 4px 4px #ccc',
    borderRadius: '4px',
    maxWidth: '100%',
  },
  filterButtonChecked: {
    background: '#DFE9F6ff',
    border: '1px solid #DFE9F6',
    paddingRight: '2rem',
    borderRadius: '2px',
  },
  filterButtonUnChecked: {
    border: '1px solid #DFE9F6',
    paddingRight: '2rem',
    borderRadius: '2px',
  },
  // card styles
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  tagsFilterContent: {
    padding: theme.spacing(1),
  },

  // arrows color to be updated as per the design
  arrows: {
    fill: 'darkgrey',
  },
}));

function PropertyListingsLoader() {
  const classes = useStyles();
  return (
    <Grid container spacing={4}>
      {[1, 2, 3, 4, 5, 6, 7, 8].map((i, loader) => (
        <Grid item xs={12} sm={6} md={3} key={i}>
          <Card>
            <Skeleton variant="rect" height={200} width="100%" />
            <CardContent
              className={classes.propertyInfoIconText}
              style={{ padding: '.8rem' }}
            >
              <Skeleton variant="rect" height={40} width={40} />
              <Grid
                item
                container
                direction="column"
                style={{ margin: '.2rem 0' }}
              >
                <Skeleton
                  variant="text"
                  width={40}
                  style={{ margin: '0 .4rem' }}
                />
                <Skeleton
                  variant="text"
                  width={100}
                  style={{ margin: '0 .4rem' }}
                />
              </Grid>
            </CardContent>
            <CardContent style={{ padding: '.8rem' }}>
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <Skeleton variant="text" width={100} height={20} />
                  <Skeleton variant="text" width={50} height={40} />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton variant="text" width={100} height={20} />
                  <Skeleton variant="text" width={50} height={45} />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton variant="text" width={100} height={20} />
                  <Skeleton variant="text" width={50} height={40} />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton variant="text" width={100} height={20} />
                  <Skeleton variant="text" width={50} height={40} />
                </Grid>
              </Grid>
              <Skeleton
                variant="text"
                width="100%"
                height={20}
                style={{ margin: '.5rem 0' }}
              />
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default PropertyListingsLoader;
