export const FETCH_PROPERTIES_REQUEST = 'FETCH_PROPERTIES_REQUEST';
export const FETCH_PROPERTIES_SUCCESS = 'FETCH_PROPERTIES_SUCCESS';
export const FETCH_PROPERTIES_FAIL = 'FETCH_PROPERTIES_FAIL';

export const FETCH_PROPERTY_DETAILS_REQUEST = 'FETCH_PROPERTY_DETAILS_REQUEST';
export const FETCH_PROPERTY_DETAILS_SUCCESS = 'FETCH_PROPERTY_DETAILS_SUCCESS';
export const FETCH_PROPERTY_DETAILS_FAIL = 'FETCH_PROPERTY_DETAILS_FAIL';

export const ORDER_PRODUCTS_BY_TYPE = 'ORDER_PRODUCTS_BY_TYPE';

export const FETCH_CURATED_PROPERTIES_REQUEST =
  'FETCH_CURATED_PROPERTIES_REQUEST';
export const FETCH_CURATED_PROPERTIES_SUCCESS =
  'FETCH_CURATED_PROPERTIES_SUCCESS';
export const FETCH_CURATED_PROPERTIES_FAIL = 'FETCH_CURATED_PROPERTIES_FAIL';
