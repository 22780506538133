import React, { useEffect ,useState,useRef} from 'react';

import { Container, Grid, Hidden, makeStyles, Paper } from '@material-ui/core';
import PropertyFaqs from './PropertyFaqs/PropertyFaqs';
import PropertyLocation from './PropertyLocation/PropertyLocation';
import PropertyHighlightsList from './PropertyHighlights/PropertyHighlightsList';
import PropertyHighlightsDocuments from './PropertyHighlights/PropertyHighlightsDocuments';
import lottie from 'lottie-web'

import FinancialOverview from './FinancialOverview/FinancialOverview';
import PropertyWebinar from './PropertyWebinar/PropertyWebinar';

import SideBarMarketPlace from './../../SideBar/SideBarMarketPlace';
import CircularProgress from '@material-ui/core/CircularProgress';
import './property.css';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPropertyDetails } from './../../../store/properties/properties.actions';
import InvestmentOverview from './InvestmentOverview/InvestmentOverview';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ApartmentIcon from '@material-ui/icons/Apartment';
import DescriptionIcon from '@material-ui/icons/Description';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import RoomIcon from '@material-ui/icons/Room';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import Lottie from 'lottie-web';
import Chat from '../../Chatbot/Chat'

const useStyles = makeStyles((theme) => ({
  container: {},
  margin: {
    height: theme.spacing(2),
  },
  marginTop: {
    marginTop: theme.spacing(4),
  },
}));

function PropertyDetails() {
  const classes = useStyles();

  const marketplaceId = useParams();

  const propertyDetail = useSelector((state) => state.propertyDetails);
  const dispatch = useDispatch();
  const { loading, property } = propertyDetail;

  useEffect(() => {
    dispatch(fetchPropertyDetails(marketplaceId.id));
  }, [marketplaceId.id]);


  const [lottie, setlottie] = useState(true);
  if (loading) {
    setTimeout(() => {

      setlottie(false)
      // return (
      //   <div
      //     style={{ minHeight: '100vh', display: 'grid', placeItems: 'center' }}
      //   >
      //     <CircularProgress />
      //   </div>
      // );
    }, 3000);
   
  }

  const {
    roi,
    token,
    address,
    name,
    description,
    location,
    marketing,
    legal,
  } = property;
  let coverImage = '';
  let titleDocs = [];
  let images = [];
  let videos = [];
  if (marketing) {
    coverImage = marketing.coverImage;
    images = [marketing.images];
    if (marketing.videos.length !== 0) {
      videos = [...videos, marketing.videos];
    }
  }
  if (legal) {
    console.log('data comes from here ✨🔮');
    titleDocs = legal.titleDocs[0];
  }

  let marketPlaceDetails = [];
  if (videos.length === 0) {
    marketPlaceDetails = [
      {
        menu: 'Investment overview',
        link: 'investment',
        icons: AssessmentIcon,
      },
      {
        menu: 'Property Highlights',
        link: 'property',
        icons: ApartmentIcon,
      },
      {
        menu: 'Legal Documents',
        link: 'documents',
        icons: DescriptionIcon,
      },
      {
        menu: 'Financial Overview',
        link: 'financial',
        icons: CreditCardIcon,
      },

      {
        menu: 'Location',
        link: 'location',
        icons: RoomIcon,
      },
      {
        menu: 'FAQs',
        link: 'faqs',
        icons: LiveHelpIcon,
      },
    ];
  } else {
    marketPlaceDetails = [
      {
        menu: 'Investment overview',
        link: 'investment',
        icons: AssessmentIcon,
      },
      {
        menu: 'Property Highlights',
        link: 'property',
        icons: ApartmentIcon,
      },
      {
        menu: 'Legal Documents',
        link: 'documents',
        icons: DescriptionIcon,
      },
      {
        menu: 'Financial Overview',
        link: 'financial',
        icons: CreditCardIcon,
      },
      {
        menu: 'Webinars',
        link: 'webinars',
        icons: OndemandVideoIcon,
      },
      {
        menu: 'Location',
        link: 'location',
        icons: RoomIcon,
      },
      {
        menu: 'FAQs',
        link: 'faqs',
        icons: LiveHelpIcon,
      },
    ];
  }
  const getSideBar = () => {};


const container = useRef(null)

useEffect(()=>{
  Lottie.loadAnimation({
    container:container.current,
    renderer:'svg',
  
    loop:true,
    autoplay:true,
    animationData:require('../../../assets/loader.json')
  })
},[])

 



  return (
    <Container className={classes.container}>
    {
      lottie?(
//         <div >
//  <CircularProgress />
//         </div>
<div style={{ display:"flex", alignItems:"center", justifyContent:"center"}}>
<div  style={{height:200, width:200}} className="container" ref={container}>
</div>


</div>
       
      ):(

        <Grid container spacing={2}>
        <Hidden mdDown>
          <Grid item sm={12} md={2} lg={2}>
            <div className="sideBarFix">
              <SideBarMarketPlace
                sendOption={getSideBar}
                getSidebarItems={marketPlaceDetails}
              />
            </div>
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={10} md={10} className="contentFix">
          <Grid container spacing={2} id="0">
            <InvestmentOverview
              roi={roi}
              token={token}
              address={address}
              name={name}
              coverImage={coverImage}
              images={images}
              id={marketplaceId}
              
            />
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} id="1" style={{ marginTop:10}}>
              <PropertyHighlightsList description={description} />
            </Grid>
            <Grid item xs={12} id="2">
              <PropertyHighlightsDocuments titleDocs={titleDocs} />
            </Grid>
            <Grid item xs={12} id="3">
              <FinancialOverview />
            </Grid>
            {videos.length !== 0 && (
              <Grid item xs={12} id="4">
                <PropertyWebinar videos={videos} />
              </Grid>
            )}
            <Grid item xs={12} id={videos.length !== 0 ? '5' : '4'}>
              <PropertyLocation location={location} />
            </Grid>
            <Grid item xs={12} id={videos.length !== 0 ? '6' : '5'}>
              <PropertyFaqs roi={roi} name={name} coverImage={coverImage} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      )
    }
      
     
    </Container>
  );
}

export default PropertyDetails;
