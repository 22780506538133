import React, {useEffect, useState} from 'react';
import {
    Container,
    Grid,
    makeStyles,
    Paper
} from '@material-ui/core';
import Select from 'react-select';

const options = [{"value":"s","label":"Small"},{"value":"m","label":"Medium"},{"value":"l","label":"Large"}];

const customStyles = {
    option: (provided, state) => ({
      ...provided,
      width: 200
    }),
    control: (provided, state) => ({
        ...provided,
      border: '1px solid #C5CACE',
      width: 200,
    }),
    menu: (provided, state) => ({
        ...provided,
      border: '1px solid #C5CACE',
      width: 200,
    })
}

const useStyles = makeStyles((theme) => ({

}));

const city = [
    {
        "name": "All",
        "status": 1
    },
    {
        "name": "USA",
        "status": 0
    },
    {
        "name": "India",
        "status": 0
    },
    {
        "name": "UAE",
        "status": 0
    },
    {
        "name": "Argentina",
        "status": 0
    },
    {
        "name": "Armania",
        "status": 0
    },
    {
        "name": "Canada",
        "status": 0
    }
]

const Location = (props) => {
    const classes = useStyles();
    const [isSearchable] = useState(true);
    const [cityData, setCityData] = useState(city);


    const selectedLocation = (data, id) => {
        var updatedTodos = cityData.map((todo, index) => index === id ? {
            ...todo,
            status: (todo.status === 0) ? 1 :0
          } : todo);
        console.log("updatedTodos", updatedTodos);
        setCityData(updatedTodos);
    }

    useEffect(() => {
        if(props.locationData.length > 0){
            var updatedTodos = props.locationData.map((todo, index) => todo.status === 1 ? {
                ...todo
              } : todo);
            setCityData(updatedTodos);
        }
    }, [])

    return(
        <div>
            <h3 className="mt-4">Investment locations</h3>
            <p>Search or select your preferred locations of properties to invest in.</p>
            
            <div style={{position: 'relative'}}>

            {(cityData.map((data, id) => {
                if(data.status === 1){
                    return(
                        <label key={id} onClick={() => selectedLocation(data, id)} className="locationBorderSelectedBox">{data.name}</label>
                    )
                }else{
                    return(
                        <label key={id} onClick={() => selectedLocation(data, id)} className="locationBorderBox">{data.name}</label>
                    )
                }
            }))}

            <label>OR</label>
            <Select
                className="basic-single"
                classNamePrefix="select"
                isSearchable={isSearchable}
                name="color"
                options={options}
                styles={customStyles}
                placeholder="Search Location..."
            />
            </div>
           


            <Grid container spacing={4} className="mt-5">
                <Grid item xs={12} sm={12} md={2} lg={2}>
                    <button className="btnYellowBorder" style={{width: "100%", background: "#F5F5F5", border: "none"}}>Back</button> 
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                    <button className="btnYellowBorder" style={{width: "100%"}} onClick={() => props.completeStep1(cityData)}>Next</button>
                </Grid>
            </Grid>
            
        </div>
    )
}

export default Location;

