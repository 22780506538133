import React, { useState } from 'react';
import TimezoneSelect from 'react-timezone-select';

const MeetingSlotsTimezones = () => {
  const [selectedTimezone, setSelectedTimezone] = useState('');

  return (
    <div className="select-timezones-wrapper">
      <TimezoneSelect value={selectedTimezone}  onChange={setSelectedTimezone} />
    </div>
  );
};

export default MeetingSlotsTimezones;
