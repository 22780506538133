import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import designResource from './../../../../assets/files/design.pdf';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

const useStyles = makeStyles((theme) => ({
  documentStat: {
    background: '#FFF9E8',
    margin: theme.spacing(1, 'auto'),
    padding: '.4rem',
  },
  documentFileIcon: {
    fontSize: '2.2rem',
    fill: 'grey',
  },
  pages: {
    margin: theme.spacing(1),
    boxShadow: '0 2px 2px #ccc',
    padding: theme.spacing(2),
    borderRadius: '5px',
    textAlign: 'center',
  },
  pdfNavigators: {
    background: '#FDC424',
    textTransform: 'none',
    border: '1px solid #fdc424',
    margin: '0 1rem',
    color: 'white',
    '&:hover': {
      background: '#FDC424',
      border: '1px solid #fdc424',
    },
  },
}));

function PropertyHighlightsDocuments({ titleDocs }) {
  const classes = useStyles();

  //   pdf viewer helper functions
  const [selectedDocument, setSelectedDocument] = useState(titleDocs);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const documents = [
    {
      title: 'Property Legal Document 1',
      description: 'Prestige Sky High,Mumbai',
      source: titleDocs,
    },
    {
      title: 'Form C pdf',
      description: 'Prestige Sky High,Mumbai',
      source: titleDocs,
    },
  ];

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  // dialog helper function here
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //   combined helpers
  const handleDocumentViewerOpen = (document) => {
    console.log('document view');
    console.log(document);
    console.log(document.source);
    setSelectedDocument(document.source);
    handleClickOpen();
  };
  console.log(selectedDocument, 'is our document to be viewed');
  console.log('🔥');
  console.log(titleDocs);
  console.log('⚡');

  return (
    <Card elevation={0}>
      <CardContent>
        <Grid container direction="column">
          <Typography variant="h4" gutterBottom>
            Documents
          </Typography>
          {documents.map((document) => (
            <Grid
              key={document.title}
              item
              style={{ cursor: 'pointer' }}
              onClick={() => handleDocumentViewerOpen(document)}
            >
              <Typography color="secondary" variant="subtitle2">
                {/* {document.title} */}
                Title
              </Typography>
              <Paper
                elevation={0}
                className={classes.documentStat}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <InsertDriveFileIcon className={classes.documentFileIcon} />
                <Typography>
                  {/* {document.description} */}
                  Description
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
        {open && (
          <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {'DocumentReader'}
            </DialogTitle>
            <DialogContent>
              <Document
                file={selectedDocument}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNumber} />
              </Document>
              <Box m={1}>
                <p className={classes.pages}>
                  Page {pageNumber || (numPages ? 1 : '--')} of{' '}
                  {numPages || '--'}
                </p>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Button
                    variant="outlined"
                    className={classes.pdfNavigators}
                    disableElevation
                    disableRipple
                    disableFocusRipple
                    type="button"
                    disabled={pageNumber <= 1}
                    onClick={previousPage}
                  >
                    Previous
                  </Button>
                  <Button
                    variant="outlined"
                    disableElevation
                    disableRipple
                    disableFocusRipple
                    className={classes.pdfNavigators}
                    type="button"
                    disabled={pageNumber >= numPages}
                    onClick={nextPage}
                  >
                    Next
                  </Button>
                </Box>
              </Box>
            </DialogContent>
          </Dialog>
        )}
      </CardContent>
    </Card>
  );
}

export default PropertyHighlightsDocuments;
