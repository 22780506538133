import React, {useState, useEffect} from 'react';
import {
    Container,
    Grid,
    makeStyles,
    Paper
} from '@material-ui/core';
import profileDetails from './../../data/profile.json';
import ModalDialog from './../Dialog/index';
import Select from 'react-select';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getBaseUrl } from '../../../../utils/request';

const options = [
    {"value": "View Only", "label": "View Only"},
    {"value": "Transact", "label": "Transact"}
]

const useStyles = makeStyles((theme) => ({
    cardCls: {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 0px 20px #0000001A',
        borderRadius: 10,
        padding: 10,
        textAlign: 'center',
        height: '100%'
    },
    docTitle: {
        border: '2px solid #C5CACE',
        padding: '10px 20px',
        borderRadius: 4,
        marginBottom: 10,
        width: '100%'
    }
}));

const Providers = () => {
    const classes = useStyles();
    const [toggleModal, setToggleModal] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    const [userData, setUserData] = useState([]);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");


    const authorizeUsers = () => {
        setToggleModal(true);
    }
    const closeModalDownload = () => setToggleModal(false);

    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption)
    }

    const handleChangeEmail = (e) => {
        console.log("e", e.target.value);
        let getData = e.target.value;
        setEmail(getData);
    }

    const handleChangePassword = (e) => {
        //setPassword(e.target.value)
        let getData = e.target.value;
        setPassword(getData);
    }

    const sendInvite = () => {
        const config = {
            headers: {
            'Authorization': 'Bearer ' + userInfo.accessToken,
            'Content-Type': 'application/json'
            },
            query : {
                'page': 1,
                'per_page': 10
            }
        }
        const data = {
            "email": email,
            "password": password,
            "type": "view"
        }
        console.log("data", data);
        axios.post(getBaseUrl('authorization'), data, config)
        .then(res => {
            console.log("res", res);
            closeModalDownload();
        }) 
    }



    useEffect(() => {
        console.log("userInfo", userInfo)
        const config = {
            headers: {
            'Authorization': 'Bearer ' + userInfo.accessToken,
            'Content-Type': 'application/json',
            },
            query : {
                'page': 1,
                'per_page': 10
            }
        }
        axios.get(getBaseUrl('authorization'),  config)
        .then(res => {
            console.log("res", res);
            setUserData(res.data);
            
        })
    }, [])


    return(
        <div className="mt-4">
            <Grid container spacing={4}>
                {(userData.map((data, id) => {
                    if(data.firstName){
                        return(
                            <Grid item xs={12} sm={12} md={4} lg={4} key={id}>
                                <div className={classes.cardCls}>
                                    <img src="/assets/icons/profile.jpg" alt={data.name} width="186px" />
                                    <h5 className="mt-3">{data.firstName[0]} {data.lastName[0]}</h5>
                                    <p>{data.email[0]}</p>
                                </div>
                            </Grid>
                        )
                    }
                }))}
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <div className={classes.cardCls + " pt-5"} onClick={authorizeUsers}>
                        <img src="/assets/icons/add.png" alt="Add" width="186px" />
                        <h5 className="mt-3">ADD NEW</h5>
                    </div>
                </Grid>
                
            </Grid>
            <ModalDialog openDialog={toggleModal} closeModal={closeModalDownload}>
                <div className="p-5">
                <img src="/assets/icons/close.svg" alt="close" className="closeModalIconCls" onClick={() => closeModalDownload()} />
                    <h2>Add User</h2>
                    <p>Email</p>
                    <input type="email" placeholder="" onChange={handleChangeEmail} className={classes.docTitle} />
                    <p>Password</p>
                    <input type="password" placeholder="" onChange={handleChangePassword} className={classes.docTitle} />
                    <p>Authorisation type</p>
                    <Select
                        value={selectedOption}
                        onChange={handleChange}
                        options={options}
                    />
                    <button className="btnYellowBorder mt-4" style={{width: "100%"}} onClick={() => sendInvite()}>Send Invite</button>
                </div>
            </ModalDialog>
        </div>
    )
}

export default Providers;
