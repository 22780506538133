import React, { useState, useEffect } from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import './MeetingTimes.css';
import { useInvestNowSection } from '../../../../context/investnow-context';

let list = [
  { name: '09:00am' },
  { name: '09:30am' },
  { name: '10:00am' },
  { name: '10:30am' },
  { name: '11:00am' },
  { name: '11:30am' },
  { name: '12:00pm' },
  { name: '12:30pm' },
  { name: '01:00pm' },
  { name: '01:30pm' },
  { name: '02:00pm' },
];

const MenuItem = ({ text, selected }) => {
  return <div className={`menu-item ${selected ? 'active' : ''}`}>{text}</div>;
};

export const Menu = (list, selected) =>
  list.map((el) => {
    const { name } = el;

    return <MenuItem text={name} key={name} selected={selected} />;
  });

const Arrow = ({ text, className }) => {
  return (
    <div className={className}>
      <img src="/assets/icons/left.png" alt={text} />
    </div>
  );
};

const RightArrow = ({ text, className }) => {
  return (
    <div className={className}>
      <img src="/assets/icons/right.png" alt={text} />
    </div>
  );
};

export const ArrowLeft = Arrow({ text: '<', className: 'arrow-prev' });
export const ArrowRight = RightArrow({ text: '>', className: 'arrow-next' });

function MeetingTimes() {
  const [selected, setSelected] = useState('09:00am');
  const menu = Menu(list, selected);

  const [investmentdata, setInvestmentData] = useInvestNowSection();

  const onSelect = (key) => {
    setSelected(key);
  };

  useEffect(() => {
    setInvestmentData({ ...investmentdata, time: selected });
    console.log('🐨');
    console.log(investmentdata);
  }, [selected]);

  return (
    <div className="App">
      <ScrollMenu
        data={menu}
        arrowLeft={ArrowLeft}
        arrowRight={ArrowRight}
        selected={selected}
        onSelect={onSelect}
      />
    </div>
  );
}

export default MeetingTimes;
