import React, {useState} from 'react';
import {
    Container,
    Grid,
    makeStyles,
    Paper
} from '@material-ui/core';
import Location from './Location';
import RiskProfile from './RiskProfile';
import PropertyType from './PropertyType';
import Range from './Range';
import InvestmentDetails from './InvestmentDetails';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getBaseUrl } from '../../../../utils/request';

const useStyles = makeStyles((theme) => ({

}));

const Investment = () => {
    const classes = useStyles();
    const [step, setStep] = useState(0);
    const [progressVal, setProgressVal] = useState(20);
    const [showDetails, setShowDetails] = useState(false);
    const [locationData, setLocationData] = useState([]);
    const [riskData, setRiskData] = useState([]);
    const [propertyData, setPropertyData] = useState([]);
    const [rangeData, setRangeData] = useState([]);
    const [preferenceDetails, setPreferenceDetails] = useState({
        "investment": {
          "location": [],
          "minimum": 0,
          "maximum": 0
        },
        "riskProfile": "all",
        "propertyTypes": []
    })
    const [rangeMoney, setRangeMoney] = useState("");
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const locationComplete = (data) => {
        setLocationData(data);
        setProgressVal(40);
        let arr = data.map((item) =>{
            if(item.status === 1){
                return item.name;
            }
        })
        var updated = arr.filter(function(val){ return val!==undefined; });        
        preferenceDetails.investment.location = updated;
        setPreferenceDetails(preferenceDetails);
        setStep(1);
    }

    const goBackToLocation = () => setStep(0);

    const riskDetailsComplete = (data) => {
        console.log("data", data)
        setProgressVal(60);
        setRiskData(data);
        preferenceDetails.riskProfile = data;
        setPreferenceDetails(preferenceDetails);
        setStep(2)
    } 
    const goBackToRiskDetails = () => setStep(1)

    const propertyComplete = (data) => {
        setPropertyData(data);
        setProgressVal(80);
        let arr = data.map((item) =>{
            if(item.statusData === 1){
                return item.name;
            }
        })
        var updated = arr.filter(function(val){ return val!==undefined; });        
        preferenceDetails.propertyTypes = updated;
        setPreferenceDetails(preferenceDetails);
        setStep(3);
    } 
    const goBackToProperty = () => setStep(2);

    const completeInvestment = (data) => {

        console.log('FINAL ->>>>',preferenceDetails.investment)
        setRangeData(data);
        setProgressVal(100);
        console.log("data", data)
        let str = data.split("-");
        let min = str[0].substring(1).trim().toString();
        let max = str[1].substring(2).trim().toString();
        console.log("str", str,min, max)
        preferenceDetails.investment.minimum = parseInt(min);
        preferenceDetails.investment.maximum = parseInt(max);
        
        setRangeMoney(data);
        console.log("userInfo", userInfo)
        const config = {
            headers: {
            'Authorization': userInfo.accessToken,
              'Content-Type': 'application/json',
            },
        };
        setShowDetails(true);
        fetch(getBaseUrl('preferences'),{
  headers:{
    Authorization: 'Bearer ' + userInfo.accessToken,
    'Content-Type': 'application/json',
    
  },
  method:'POST',

  body:JSON.stringify({
    "investment": {
      "location":   preferenceDetails.investment.location,
      "minimum": preferenceDetails.investment.minimum,
      "maximum": preferenceDetails.investment.maximum
    },
    "riskProfile": preferenceDetails.riskProfile,
    "propertyTypes":  preferenceDetails.propertyTypes
  }),
}).then((res)=>res.json()).then((data)=>console.log(data)).catch((err)=>console.log(err));
       
        // axios.post(getBaseUrl('preferences'), preferenceDetails, config)
        // .then(res => {
        //     console.log('COMPLETE')
        //     console.log("res", res);
        // })
    }

    const editedPreference = () => {
        setStep(0);
        setShowDetails(false);
        setProgressVal(20);
    }

    console.log("preferenceDetails", preferenceDetails);

    return(
        <div>
            {(showDetails === false && step === 0) && <Location completeStep1={locationComplete} locationData={locationData} />}
            {(showDetails === false && step === 1) && <RiskProfile completeStep2={riskDetailsComplete} previousStep1={goBackToLocation} riskData={riskData} />}
            {(showDetails === false && step === 2) && <PropertyType completeStep3={propertyComplete} previousStep2={goBackToRiskDetails} propertyData={propertyData} />}
            {(showDetails === false && step === 3) && <Range finishInvest={completeInvestment} previousStep3={goBackToProperty} rangeData={rangeData} />}

            {(showDetails) && <InvestmentDetails preferenceDetails={preferenceDetails} rangeMoney={rangeMoney} editPreference={editedPreference} />}

            <div className="mt-4">
                <progress id="progressBar" value={progressVal} max="100"></progress>
            </div>
        </div>
    )
}

export default Investment;

