import React from 'react';
import { Grid } from '@material-ui/core/';

function CurrentTime() {
  const [date, setDate] = React.useState(new Date());
  const [hour, setHour] = React.useState('');

  React.useEffect(() => {
    let timing = date.getHours();
    if (timing >= 4 && timing <= 11) {
      setHour('morning');
    } else if (timing >= 12 && timing <= 16) {
      setHour('afternoon');
    } else if (timing >= 17 && timing <= 20) {
      setHour('evening');
    } else if (timing >= 21 || timing <= 3) {
      setHour('night');
    } else {
      setHour('');
    }
  }, []);

  React.useEffect(() => {
    let timer = setInterval(() => setDate(new Date(), 1000));

    return () => {
      clearInterval(timer);
    };
  });

  return (
    <Grid container style={{ width: '100%' }}>
      <Grid item sm={12} md={6} lg={6}>
        <h5>Good {hour}</h5>
        <h3>Sarah!</h3>
      </Grid>
      <Grid item sm={12} md={6} lg={6}>
        <h5 className="text-right">{date.toDateString()}</h5>
        <h3 className="text-right">{date.toLocaleTimeString()}</h3>
      </Grid>
    </Grid>
  );
}

export default CurrentTime;
