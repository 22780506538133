import React, {useState} from 'react';
import {
    Container,
    Grid,
    makeStyles,
    Paper
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    cardCls: {
        background: '#FFF9E8 0% 0% no-repeat padding-box',
        borderRadius: 10,
        padding: 14,
        height: '100%'
    }
}));


const InvestmentDetails = ({preferenceDetails, rangeMoney, editPreference}) => {
    const classes = useStyles();
    const [isSearchable] = useState(true);
    return(
        <div>
            <h3 className="mt-4">My Investment Preferences</h3>
            <p>These are the investment preferences chosen by you. You can always edit these preferences by clicking the edit button below</p>
            <Grid container spacing={4} className="mt-5">
                <Grid item xs={12} sm={12} md={2} lg={3}>
                    <div className={classes.cardCls}>
                        <p>Investment Locations</p>
                        
                        {(preferenceDetails.investment.location) &&
                        <div>
                            {(preferenceDetails.investment.location.map((data, id) => {
                                return(
                                    <label className="locationBorderBoxDetails">{data}</label>
                                )
                            }))}
                        </div>
                        }
                            
                        
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={3}>
                    <div className={classes.cardCls}>
                        <p>Risk Profile</p>
                        {(preferenceDetails.riskProfile) &&
                        <label className="locationBorderBoxDetails">{preferenceDetails.riskProfile}</label>}
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={3}>
                <div className={classes.cardCls}>
                        <p>Property Types</p>
                        {(preferenceDetails.propertyTypes) &&
                        <div>
                            {(preferenceDetails.propertyTypes.map((data, id) => {
                                return(
                                    <label className="locationBorderBoxDetails">{data}</label>
                                )
                            }))}
                        </div>
                        }
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={3}>
                <div className={classes.cardCls}>
                        <p>Investment Range</p>
                        <div>
                            <label className="locationBorderBoxDetails">{rangeMoney}</label>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid container spacing={4} className="mt-5">
                <Grid item xs={12} sm={12} md={2} lg={2}>
                    <button className="btnYellowBorder" style={{width: "100%"}} onClick={() => editPreference()}>Edit preferences</button>
                </Grid>
            </Grid>
            
        </div>
    )
}

export default InvestmentDetails;

