import React, { useState, useEffect, useRef } from 'react';
import { Avatar, Container, Grid, makeStyles, Paper } from '@material-ui/core';
import './account.css';
import Select from 'react-select';
import Checkbox from '@material-ui/core/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { getUserAccountDetails } from './../../store/user/user.actions';
import { getCountryDetails } from './../../store/countries/countries.actions';
import { connect } from 'react-redux';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {getBaseUrl} from '../../utils/request'

const countryOptions = [
  { value: 'India', label: 'India' },
  { value: 'England', label: 'England' },
];

const stateOptions = [
  { value: 'Karnataka', label: 'Karnataka' },
  { value: 'Kolkata', label: 'Kolkata' },
];

const Tabs = (props) => {
  const [selected, setSelected] = useState(props.selected || 0);
  const handleChange = (index) => {
    setSelected(index);
  };

  return (
    <div>
      <ul className="inline">
        {props.children.map((elem, index) => {
          let style = index == selected ? 'selected' : '';
          return (
            <li
              className={style}
              key={index}
              onClick={() => handleChange(index)}
            >
              {elem.props.title}
            </li>
          );
        })}
      </ul>
      <div className="tab p-0">{props.children[selected]}</div>
    </div>
  );
};

const Panel = (props) => {
  return <div>{props.children}</div>;
};

const useStyles = makeStyles((theme) => ({
  detailsText: {
    width: '100%',
    border: '1px solid #C5CACE',
    borderRadius: 10,
    padding: 16,
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const AccountDetails = () => {
  const classes = useStyles();
  const inputFileRef = useRef(null);
  const [selectedCountryOption, setSelectedCountryOption] = useState(null);
  const [selectedStateOption, setSelectedStateOption] = useState(null);
  const [checked, setChecked] = React.useState(true);
  const [userDetails, setUserDetails] = useState(null);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const dispatch = useDispatch();
  const [userData, setUserData] = useState(null);
  const [profileUrl, setProfileUrl] = useState('');
  const [country, setCountry] = useState([]);
  const [error, setError] = useState(null);
  const [states, setStates] = useState([]);
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [postalCode, setPostalCode] = useState('');

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleCountryChange = (selected) => {
    setSelectedCountryOption(selected);
    changeStateData(selected);
  };

  const handleStateChange = (selected) => {
    setSelectedStateOption(selected);
  };

  const handleChangeText = (data, event) => {};

  const handleBtnClick = () => {
    inputFileRef.current.click();
  };

  const handleProfilePic = (event) => {
    console.log('@', event.target.files);
    const config = {
      headers: {
        Authorization: 'Bearer ' + userInfo.accessToken,
      },
    };
    var formData = new FormData();
    formData.append('file', event.target.files[0]);
    axios
      .post(
        getBaseUrl('image/upload')
        ,
        formData,
        config,
      )
      .then((res) => {
        console.log('res', res);
        setProfileUrl(res.data.url);
      })
      .catch((err) => {
        setError(err);
      });
  };

  const updateProfile = () => {
    let obj = {
      email: userData ? userData.email : '',
      firstname: firstName ? firstName : userData.firstname,
      lastname: lastName ? lastName : userData.lastname,
      countryCode: countryCode ? countryCode : userData.countryCode,
      contact: phoneNumber ? phoneNumber : userData.contact,
      address: {
        addressLine: addressLine1
          ? addressLine1 + addressLine2
          : userData.address.country,
        locality: 'string',
        city: 'string',
        state: selectedStateOption
          ? selectedStateOption.value
          : userData.address.state,
        country: selectedCountryOption
          ? selectedCountryOption.label
          : userData.address.country,
        postCode: userData ? userData.address.postCode : '',
        type: 'billing',
      },
      profileUrl: profileUrl ? profileUrl : '',
      additionalAddress: [
        {
          addressLine: addressLine1
            ? addressLine1 + addressLine2
            : userData.address.country,
          locality: 'string',
          city: 'string',
          state: selectedStateOption
            ? selectedStateOption.value
            : userData.address.state,
          country: selectedCountryOption ? selectedCountryOption.label : '',
          postCode: postalCode ? postalCode : userData.address.postCode,
          type: 'billing',
        },
      ],
      role: ['buyer'],
    };
    const config = {
      headers: {
        Authorization: 'Bearer ' + userInfo.accessToken,
        'Content-Type': 'application/json',
      },
    };
    console.log('obj', obj);
    axios
      .put(getBaseUrl('users'), obj, config)
      .then((res) => {
        console.log('Res', res);
        getUserData();
        toast.success('Account credentials changed successfully', {
          position: 'bottom-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          transition: Zoom,
        });
      })
      .catch((error) => {
        toast('Something went wrong.', {
          position: 'bottom-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          transition: Zoom,
        });
        setError(error);
      });
  };

  const getAllCountries = (user) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + userInfo.accessToken,
        'Content-Type': 'application/json',
      },
    };
    axios
      .get(getBaseUrl('countries/all'), config)
      .then((res) => {
        console.log('Res', res);
        let newArr = res.data.map((data, id) => {
          let obj = {
            value: data.name,
            label: data.name,
            code: data.isoCode,
          };
          return obj;
        });
        console.log('newArr', newArr);
        setCountry(newArr);
        getStateData(user, newArr);
      });
  };

  const getStateData = (userDetails, arrCountry) => {
    console.log('userDetails', userDetails);
    if (userDetails) {
      let obj = {
        value: userDetails.address ? userDetails.address.country : null,
        label: userDetails.address ? userDetails.address.country : null,
        code: '',
      };
      setSelectedCountryOption(obj);

      let result = arrCountry.map((data, id) => {
        if (data.label == userDetails.address.country) {
          return data;
        }
      });
      let finalResult = result.filter(function (element) {
        return element !== undefined;
      });
      const config = {
        headers: {
          Authorization: 'Bearer ' + userInfo.accessToken,
          'Content-Type': 'application/json',
        },
      };
      console.log('finalResult', finalResult);

      if (finalResult) {
        let code = finalResult.length > 0 ? finalResult[0].code : '';
        if (code) {
          axios
            .get(
             getBaseUrl('states/country=' + code),
              config,
            )
            .then((res) => {
              console.log('res', res);
              let newArr = res.data.map((data, id) => {
                let obj = {
                  value: data.name,
                  label: data.name,
                  code: data.isoCode,
                };
                return obj;
              });
              setStates(newArr);
              let result = newArr.map((data, id) => {
                if (data.value === userDetails.address.state) {
                  return data;
                }
              });
              console.log('result', result);
              let finalResultState = result.filter(function (element) {
                return element !== undefined;
              });
              console.log('finalResultState', finalResultState);
              setSelectedStateOption(finalResultState[0]);
            });
        }
      }
    }
  };

  const changeStateData = (data) => {
    console.log('data', data);
    const config = {
      headers: {
        Authorization: 'Bearer ' + userInfo.accessToken,
        'Content-Type': 'application/json',
      },
    };
    if (data) {
      let code = data ? data.code : '';
      if (code) {
        axios
          .get(
           getBaseUrl('states/country=' + code),
            config,
          )
          .then((res) => {
            console.log('res', res);
            let newArr = res.data.map((data, id) => {
              let obj = {
                value: data.name,
                label: data.name,
                code: data.isoCode,
              };
              return obj;
            });
            setStates(newArr);
            let result = newArr.map((data, id) => {
              if (data.value == userData.address.state) {
                return data;
              }
            });
            let finalResultState = result.filter(function (element) {
              return element !== undefined;
            });
            console.log('finalResultState', finalResultState);
            setSelectedStateOption(finalResultState[0]);
          });
      }
    }
  };

  const getUserData = () => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + userInfo.accessToken,
        'Content-Type': 'application/json',
      },
    };
    axios
      .get(getBaseUrl('users'), config)
      .then((res) => {
        console.log('res', res);
        setUserData(res.data);
        getAllCountries(res.data);
        setProfileUrl(res.data.profileUrl);
      })
      .catch((error) => {
        setError(error);
      });
  };
  console.log('🔥🔥');
  console.log(selectedStateOption);
  console.log('🚀🚀');

  useEffect(() => {
    getUserData();
  }, []);

  if (error) {
    return (
      <div style={{ minHeight: '100vh' }}>
        <ToastContainer
          position="bottom-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          transition={Zoom}
        />
      </div>
    );
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={7}>
          <Paper className="p-5">
            <h4 className="mb-4">User Name</h4>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p className="mb-0">First Name</p>
                <input
                  type="text"
                  placeholder="Sarah"
                  onChange={(e) => setFirstName(e.target.value)}
                  defaultValue={userData ? userData.firstname : ''}
                  className={classes.detailsText}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p className="mb-0">Middle Name</p>
                <input
                  type="text"
                  placeholder="Sarah"
                  className={classes.detailsText}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p className="mb-0">Last Name</p>
                <input
                  type="text"
                  placeholder="Sarah"
                  onChange={(e) => setLastName(e.target.value)}
                  defaultValue={userData ? userData.lastname : ''}
                  className={classes.detailsText}
                />
              </Grid>
            </Grid>
          </Paper>
          <ToastContainer
            position="bottom-center"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            transition={Zoom}
          />
          <Paper className="pt-5 pl-5 pb-5 pr-2 mt-3">
            <h4 className="mb-4">Phone</h4>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <p className="mb-0">Country Code</p>
                <input
                  type="text"
                  placeholder="Sarah"
                  onChange={(e) => setCountryCode(e.target.value)}
                  defaultValue={userData ? userData.countryCode : ''}
                  className={classes.detailsText}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} className="pl-0">
                <p className="mb-0">Phone Number</p>
                <input
                  type="text"
                  placeholder="98765432"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  defaultValue={userData ? userData.contact : ''}
                  className={classes.detailsText}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} className="pl-0">
                <button
                  className="btnYellowBorder mt-4 pl-4 pr-4"
                  onClick={updateProfile}
                >
                  Change
                </button>
              </Grid>
            </Grid>
          </Paper>
          <Paper className="pt-5 pl-5 pb-5 pr-2 mt-3">
            <h4 className="mb-4">Email</h4>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p className="mb-0">Registered Email Address</p>
                <input
                  type="email"
                  className={classes.detailsText}
                  value={userData ? userData.email : ''}
                  style={{ background: '#F5F5F5' }}
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper className="pt-5 pl-5 pb-5 pr-2 mt-3">
            <h4 className="mb-4">Linked Social Accounts</h4>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p className="mb-0 socialMediaCls">+ Facebook</p>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p className="mb-0 socialMediaCls">+ Linkedin</p>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p className="mb-0 socialMediaCls">+ Instagram</p>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p className="mb-0 socialMediaCls">+ Instagram</p>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={5}>
          <Paper className="p-4">
            <h4 className="mb-4">Profile Picture</h4>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <Avatar
                  className={classes.avatar}
                  src={
                    profileUrl !== '' ? profileUrl : '/assets/icons/profile.jpg'
                  }
                  alt="Profile"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                className="pl-0 alignCenterCls"
              >
                <button
                  className="btnYellowBorder"
                  onClick={() => setProfileUrl('')}
                >
                  Remove Picture
                </button>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={1}
                className="alignCenterCls"
              >
                <p className="mb-0">OR</p>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                className="alignCenterCls"
              >
                <button className="btnYellowBorder" onClick={handleBtnClick}>
                  Upload New
                </button>
                {/* <label htmlFor="file-upload" className="custom-file-upload">
                                    Custom Upload
                                </label> */}
                <input
                  id="file-upload"
                  ref={inputFileRef}
                  onChange={handleProfilePic}
                  type="file"
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper className="p-4 mt-3">
            <h4 className="mb-4">Address</h4>
            <Tabs selected={0}>
              <Panel title="Residential">
                <label></label>
              </Panel>
              <Panel title="Billing">
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <label>Use same a residential address</label>
              </Panel>
            </Tabs>
            <p className="mb-0">Address Line 1</p>
            <input
              type="text"
              placeholder="Address Line 1"
              onChange={(e) => setAddressLine1(e.target.value)}
              defaultValue={userData ? userData.address.state : ''}
              className={classes.detailsText}
            />
            <p className="mb-0 mt-3">Address Line 2</p>
            <input
              type="text"
              placeholder="Address Line 2"
              onChange={(e) => setAddressLine2(e.target.value)}
              defaultValue={userData ? userData.address.country : ''}
              className={classes.detailsText}
            />

            <Grid container spacing={4} className="mt-2">
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p className="mb-0">Select Country</p>
                <Select
                  value={selectedCountryOption}
                  onChange={handleCountryChange}
                  options={country}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p className="mb-0">Select State</p>
                <Select
                  value={selectedStateOption}
                  onChange={handleStateChange}
                  options={states}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p className="mb-0">Postal Code</p>
                <input
                  type="text"
                  placeholder="Postal Code"
                  onChange={(e) => setPostalCode(e.target.value)}
                  defaultValue={userData ? userData.address.postCode : ''}
                  className={classes.detailsText}
                />
              </Grid>
            </Grid>
            <button className="btnYellowBorder mt-4" onClick={updateProfile}>
              Update Profile
            </button>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

// const mapStateToProps = (state) => {
//     console.log("state", state);
//     return{
//         userData: state.userAccountDataDetails
//     }
// }

export default AccountDetails;
