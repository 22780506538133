import React, {useState} from 'react';
import {
    Container,
    Grid,
    makeStyles,
    Paper
} from '@material-ui/core';
import Footer from './../../components/Footer';
import './journal.css';

const useStyles = makeStyles((theme) => ({
    container: {
   },
    gridCls: {
        marginTop: 20,
        borderRadius: 10,
    },
    gridBox: {
        background: '#fff',
        padding: 15,
        
    }
}));

const Journal = () => {
    const classes = useStyles();
    return(
        <div>
            <div className="containerCls">
                <h3>Realto <br /> Journal.</h3>
                <p className="mb-5">Stay updated with news, webinars and talks from around the world.</p>
                
                <Grid container spacing={2} className={classes.gridCls}>
                    <Grid item xs={12} sm={12} md={7} lg={7} className={classes.gridBox}>
                        <img src="/assets/images/building-1.svg" width="100%"  alt="building" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={5} className={classes.gridBox}>
                        <p className="mb-3">The Economic times</p>
                        <h3 className="mb-3">Realty hot spot series: A premium south Delhi residential area with good connectivity</h3>
                        <h5>In this week’s Realty Hot Spot Series we take a look at South Delhi’s Saket locality. This is a popular locality in south Delhi comprising premium residential areas, high-end malls and offices. The locality has numerous social and retail amenities along with sound road and metro connectivity.</h5>
                    </Grid>
                </Grid>
                


                <Grid container spacing={2} className={classes.gridCls}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className={classes.gridBox}>
                        <img src="/assets/images/building-1.svg" width="100%"  alt="building" />
                        <p className="mb-3">The Economic times</p>
                        <h3 className="mb-3">Mumbai: Homebuyer in quandary over CHS share certificate</h3>
                        <h5>In this week’s Realty Hot Spot Series we take a look at South Delhi’s Saket locality. This is a popular locality in south Delhi comprising premium residential areas, high-end malls and offices. The locality has numerous social and retail amenities along with sound road and metro connectivity.</h5>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className={classes.gridBox}>
                        <img src="/assets/images/building-3.svg" width="100%"  alt="building" />
                        <p className="mb-3">The Economic times</p>
                        <h3 className="mb-3">Mumbai: Homebuyer in quandary over CHS share certificate</h3>
                        <h5>In this week’s Realty Hot Spot Series we take a look at South Delhi’s Saket locality. This is a popular locality in south Delhi comprising premium residential areas, high-end malls and offices. The locality has numerous social and retail amenities along with sound road and metro connectivity.</h5>
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.gridCls}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className={classes.gridBox}>
                        <img src="/assets/images/building-4.svg" width="100%"  alt="building" />
                        <p className="mb-3">The Economic times</p>
                        <h3 className="mb-3">Mumbai: Homebuyer in quandary over CHS share certificate</h3>
                        <h5>In this week’s Realty Hot Spot Series we take a look at South Delhi’s Saket locality. This is a popular locality in south Delhi comprising premium residential areas, high-end malls and offices. The locality has numerous social and retail amenities along with sound road and metro connectivity.</h5>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className={classes.gridBox}>
                        <img src="/assets/images/building-2.svg" width="100%"  alt="building" />
                        <p className="mb-3">The Economic times</p>
                        <h3 className="mb-3">Mumbai: Homebuyer in quandary over CHS share certificate</h3>
                        <h5>In this week’s Realty Hot Spot Series we take a look at South Delhi’s Saket locality. This is a popular locality in south Delhi comprising premium residential areas, high-end malls and offices. The locality has numerous social and retail amenities along with sound road and metro connectivity.</h5>
                        </div>
                    </Grid>
                </Grid>

                <Grid container spacing={2} className={classes.gridCls}>
                    <Grid item xs={12} sm={12} md={5} lg={5} className={classes.gridBox + " p-4"}>
                        <p className="mb-3 mt-5">The Economic times</p>
                        <h3 className="mb-3">Realty hot spot series: A premium south Delhi residential area with good connectivity</h3>
                        <h5>In this week’s Realty Hot Spot Series we take a look at South Delhi’s Saket locality. This is a popular locality in south Delhi comprising premium residential areas, high-end malls and offices. The locality has numerous social and retail amenities along with sound road and metro connectivity.</h5>
                    </Grid>
                    <Grid item xs={12} sm={12} md={7} lg={7} className={classes.gridBox}>
                        <img src="/assets/images/building-1.svg" width="100%"  alt="building" />
                    </Grid>
                    
                </Grid>
            </div>
            <Footer />
        </div>
    )
}

export default Journal;

