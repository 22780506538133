import React, {useState, useEffect} from 'react';
import {
    Container,
    Grid,
    makeStyles,
    Paper
} from '@material-ui/core';
import Select from 'react-select';

const options = [{"value":"s","label":"Small"},{"value":"m","label":"Medium"},{"value":"l","label":"Large"}];


const useStyles = makeStyles((theme) => ({

}));

const riskProblem = ["all", "conservative", "regular", "aggresive"]

const RiskProfile = (props) => {
    const classes = useStyles();
    const [isSearchable] = useState(true);
    const [profile, setProfile] = useState("All");

    const selectProfileData = (data) => {
        setProfile(data);
    }

    useEffect(() => {
        if(props.riskData){
            setProfile(props.riskData);
        }
    }, [])

    return(
        <div>
            <h3 className="mt-4">Risk Profile</h3>
            <p>Select preferred risk profile for your investments.</p>
            
            <div style={{position: 'relative'}}>
            {(riskProblem.map((data, id) => {
                if(profile === data){
                    return(
                        <label key={id} className="locationBorderSelectedBox" onClick={() => selectProfileData(data)}>{data}</label>
                    )
                }else{
                    return(
                        <label key={id} className="locationBorderBox" onClick={() => selectProfileData(data)}>{data}</label>
                    )
                }
            }))}
            </div>
            <div>
                <p>1. Conservative risk profile has a comparatively lower risk level and is well suited for beginner investors.</p>
                <p>2. Regular risk level is suitable for investors who has previous investment experience.</p>
                <p>3. Aggressive risk level is best for experienced investors.</p>
            </div>
            <Grid container spacing={4} className="mt-5">
                <Grid item xs={12} sm={12} md={2} lg={2}>
                    <button className="btnYellowBorder" style={{width: "100%", background: "#F5F5F5", border: "none"}} onClick={() => props.previousStep1()}>Back</button> 
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                    <button className="btnYellowBorder" style={{width: "100%"}} onClick={() => props.completeStep2(profile)}>Next</button>
                </Grid>
            </Grid>
            
        </div>
    )
}

export default RiskProfile;

