import React, { useState, useEffect } from 'react';
import { makeStyles, Paper } from '@material-ui/core';

import clsx from 'clsx';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ApartmentIcon from '@material-ui/icons/Apartment';
import DescriptionIcon from '@material-ui/icons/Description';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import RoomIcon from '@material-ui/icons/Room';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import './index.css';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link, animateScroll as scroll } from 'react-scroll';

const useStyles = makeStyles((theme) => ({
  sideBarContainer: {
    width: '180px',
  },
  sidebarList: {},
  sidebarItem: {
    color: 'black',
    margin: theme.spacing(2, 0),
  },
  // sidebarItemActive: {
  //   transition: 'all ease .4s',
  //   color: 'white',
  //   marginLeft: '1rem',
  //   background: theme.common.yellowLight,
  //   borderRadius: '5px',
  //   marginBottom: 24,
  //   '&:hover': {
  //     background: '#FDC424',
  //     boxShadow: '0px 0px 20px #00000029',
  //   },
  // },
  // sidebarIconActive: {
  //   fontSize: '2rem',
  //   fill: 'white',
  // },
  sidebarIcon: {
    fontSize: '2rem',
    fill: 'black',
  },
}));

const marketPlaceDetails = [
  {
    menu: 'Investment overview',
    link: 'investment',
    icons: AssessmentIcon,
  },
  {
    menu: 'Property Highlights',
    link: 'property',
    icons: ApartmentIcon,
  },
  {
    menu: 'Legal Documents',
    link: 'documents',
    icons: DescriptionIcon,
  },
  {
    menu: 'Financial Overview',
    link: 'financial',
    icons: CreditCardIcon,
  },
  {
    menu: 'Webinars',
    link: 'webinars',
    icons: OndemandVideoIcon,
  },
  {
    menu: 'Location',
    link: 'location',
    icons: RoomIcon,
  },
  {
    menu: 'FAQs',
    link: 'faqs',
    icons: LiveHelpIcon,
  },
];

const dashboardSideBarData = [
  {
    menu: 'Overview',
    link: 'overview',
    icons: RoomIcon,
  },
  {
    menu: 'My Investments',
    link: 'investment',
    icons: RoomIcon,
  },
  {
    menu: 'My Earnings',
    link: 'earnings',
    icons: RoomIcon,
  },
  {
    menu: 'My DocVault',
    link: 'docVault',
    icons: RoomIcon,
  },
  {
    menu: 'Authorised Users',
    link: 'users',
    icons: RoomIcon,
  },
];

const accountSideBarData = [
  {
    menu: 'Account Details',
    link: 'account',
    icons: RoomIcon,
  },
  {
    menu: 'My Wallet',
    link: 'wallet',
    icons: RoomIcon,
  },
  {
    menu: 'My Preferences',
    link: 'preferences',
    icons: RoomIcon,
  },
  {
    menu: 'KYC Verification',
    link: 'verify',
    icons: RoomIcon,
  },
  {
    menu: 'Change Password',
    link: 'password',
    icons: RoomIcon,
  },
];

export default function SideBarMarketPlace(props) {
  const classes = useStyles();
  const [sideBarData, setSideBarData] = useState([]);
  const [type, setType] = useState('');
  const [mobileView, setMobileView] = useState(false);

  const handleType = (value) => {
    setType(value);
    props.sendOption(value);
  };

  useEffect(() => {
    if (window.location.pathname === '/dashboard') {
      setSideBarData(dashboardSideBarData);
      setType('overview');
    } else if (window.location.pathname === '/account') {
      setSideBarData(accountSideBarData);
      setType('account');
    } else {
      setSideBarData(props.getSidebarItems);
      setType('investment');
    }
  }, []);

  useEffect(() => {
    if (window.outerWidth < 576) {
      setMobileView(true);
    }
  }, [window.outerWidth]);

  return (
    <>
      {mobileView ? (
        <div className="sideBarMobileView">
          {sideBarData.map((menu, index) => {
            return (
              <Link
                activeClass="activeSidebarItem"
                to={index}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                key={index}
                onClick={() => handleType(menu.link)}
              >
                {/* <label
                  style={{ padding: 20 }}
                  className={clsx(
                    type === menu.link
                      ? classes.sidebarItemActive
                      : classes.sidebarItem,
                  )}
                >
                  {menu.menu}
                </label> */}
                <ListItem
                  className={clsx(
                    type === menu.link
                      ? classes.sidebarItemActive
                      : classes.sidebarItem,
                  )}
                  key={index}
                  button
                  disableRipple
                >
                  <ListItemIcon style={{ minWidth: '42px' }}>
                    <menu.icons
                      className={clsx(
                        type === menu.link
                          ? classes.sidebarIconActive
                          : classes.sidebarIcon,
                      )}
                    />
                  </ListItemIcon>

                  {menu.menu}
                </ListItem>
              </Link>
            );
          })}
        </div>
      ) : (
        <>
          <Paper elevation={0} className={classes.sideBarContainer}>
            <List
              component="nav"
              className={classes.sidebarList}
              aria-label="sidebar navigation"
            >
              {sideBarData?.map((menu, index) => {
                return (
                  <Link
                    activeClass="activeSidebarItem"
                    to={index}
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    onClick={() => handleType(menu.link)}
                  >
                    <ListItem
                      className={clsx(
                        type === menu.link
                          ? classes.sidebarItemActive
                          : classes.sidebarItem,
                      )}
                      key={index}
                      button
                      disableRipple
                    >
                      <ListItemIcon style={{ minWidth: '42px' }}>
                        <menu.icons
                          className={clsx(
                            type === menu.link
                              ? classes.sidebarIconActive
                              : classes.sidebarIcon,
                          )}
                        />
                      </ListItemIcon>

                      {menu.menu}
                    </ListItem>
                  </Link>
                );
              })}
            </List>
          </Paper>
        </>
      )}
    </>
  );
}
