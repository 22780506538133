
const initialState ={
    botterm:null,
  
};
const chatReducer =(state =initialState, action)=>{
    switch(action.type){
        case 'settext':
            return {
                botterm:action.term
            };

      

         default:
             return state;    
    }
}

export default chatReducer;