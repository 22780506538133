import React, { useState, memo } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { MinHoldType } from './../../../services/Tags';

const useStyles = makeStyles((theme) => ({
  checkBoxContainer: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(4),
    borderRadius: '5px',
    background: '#FDC424',
    border: '1px solid #FDC424',

    // border: '1px solid #C5CACE',
  },
  checkBoxContainerChecked: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(4),
    borderRadius: '5px',
    border: '1px solid #C5CACE',
  },

  checkBox: {
    width: theme.spacing(2.2),
    height: theme.spacing(2.2),
    color: 'white',
    color: '#F8F9FC',
    fill: 'white',
    '&$checked': {
      fill: 'white',
      color: '#F8F9FC',
    },
  },
  uncheckBox: {
    width: theme.spacing(2.2),
    height: theme.spacing(2.2),
    // this totally depends on the background color, gotta use white just in case if we change.
    color: '#F8F9FC',
  },
}));

export const MinHoldTypeFilter = memo((props) => {
  const classes = useStyles();

  const [minHoldTypes, setMinHoldTypes] = useState([]);

  const handleSelectAll = (event) => {
    const selectedOrders = event.target.checked
      ? MinHoldType.map((property) => property.years)
      : [];

    setMinHoldTypes(selectedOrders);
  };

  const handleMinHoldTypeFilters = (event, property) => {
    const selectedIndex = minHoldTypes.indexOf(property.years);
    let newSelectedOrders = [];

    if (selectedIndex === -1) {
      newSelectedOrders = newSelectedOrders.concat(
        minHoldTypes,
        property.years,
      );
    } else if (selectedIndex === 0) {
      newSelectedOrders = newSelectedOrders.concat(minHoldTypes.slice(1));
    } else if (selectedIndex === minHoldTypes.length - 1) {
      newSelectedOrders = newSelectedOrders.concat(minHoldTypes.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedOrders = newSelectedOrders.concat(
        minHoldTypes.slice(0, selectedIndex),
        minHoldTypes.slice(selectedIndex + 1),
      );
    }

    setMinHoldTypes(newSelectedOrders);
  };

  React.useEffect(() => {
    console.log('🐨');
    console.log(minHoldTypes);
    props.handleFilters(minHoldTypes);
  }, [minHoldTypes]);

  return (
    <>
      <FormControlLabel
        className={
          minHoldTypes.length === MinHoldType.length
            ? classes.checkBoxContainer
            : classes.checkBoxContainerChecked
        }
        control={
          <Checkbox
            checked={minHoldTypes.length === MinHoldType.length}
            disableRipple
            disableFocusRipple
            checkedIcon={
              <CheckCircleIcon size="small" className={classes.checkBox} />
            }
            icon={
              <CircleUnchecked size="small" className={classes.uncheckBox} />
            }
            onChange={handleSelectAll}
            name="All"
            value="All"
          />
        }
        label={<Typography variant="body1">All</Typography>}
      />
      {MinHoldType.slice(0).map((property) => (
        <FormControlLabel
          className={
            minHoldTypes.indexOf(property.years) !== -1
              ? classes.checkBoxContainer
              : classes.checkBoxContainerChecked
          }
          key={property.id}
          control={
            <Checkbox
              onChange={(event) => handleMinHoldTypeFilters(event, property)}
              disableRipple
              disableFocusRipple
              checked={minHoldTypes.indexOf(property.years) !== -1}
              checkedIcon={
                <CheckCircleIcon size="small" className={classes.checkBox} />
              }
              icon={
                <CircleUnchecked size="small" className={classes.uncheckBox} />
              }
              name={property.name}
              value={minHoldTypes.indexOf(property.years) !== -1}
            />
          }
          label={<Typography variant="body1">{property.name}</Typography>}
        />
      ))}
    </>
  );
});
