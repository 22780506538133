import React, { memo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import axios from 'axios';

import { makeStyles, createStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import {
  Divider,
  Grid,
  LinearProgress,
  Paper,
  Avatar,
  CardHeader,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';

import { ToastContainer, toast, Zoom } from 'react-toastify';

import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { useDispatch, useSelector } from 'react-redux';
import { getBaseUrl } from '../../../../../../utils/request';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    boxShadow: '2px 4px 10px #ccc',
    position: 'relative',
  },
  cardMedia: {
    cursor: 'auto',
  },
  cover: {
    borderRadius: '2px',
  },
  propertyCardFeature: {
    position: 'absolute',
    width: '100%',
    display: 'flex',
  },
  topTags: {
    textTransform: 'capitalize',
  },
  expand: {
    fill: 'white',
    cursor: 'pointer',
  },
  cardContent: {
    padding: '0.8rem',
    cursor: 'pointer',
  },
}));
const BorderLinearProgress = withStyles((theme) =>
  createStyles({
    root: {
      height: 10,
      borderRadius: 2,
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 2,
      backgroundColor: '#54B98A',
    },
  }),
)(LinearProgress);

const CuratedProperty = memo(
  ({ address, id, marketing, name, roi, token, match, history }) => {
    const classes = useStyles();
    const { url } = useRouteMatch();

    const subHeader = `${address.state}, ${address.city}, ${address.country}`;
    const { soldTokens, availableTokens } = token;
    const percentResult = Math.round((soldTokens / availableTokens) * 100);
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    // watchlisted properties ,to display favourite icon.
    const [favourite, setFavourite] = useState([]);
    React.useEffect(() => {
      const config = {
        headers: {
          Authorization: 'Bearer ' + userInfo.accessToken,
          'Content-Type': 'application/json',
        },
      };
      const getFavouriteProperties = async () => {
        const response = await axios.get(
      
          config,
        );

        setFavourite(response.data.propertyId);
      };
      getFavouriteProperties();
    }, []);

    const moveToWishList = async (id) => {
      console.log('id', id);
      const config = {
        headers: {
          Authorization: 'Bearer ' + userInfo.accessToken,
          'Content-Type': 'application/json',
        },
      };
      let res = await axios.get(
        getBaseUrl('watchlist'),
        config,
      );
      let watchlistedProperties = [...res.data.propertyId];
      let updatedProperties = [];
      let wasPresent = false;
      for (let i = 0; i < watchlistedProperties.length; i++) {
        if (watchlistedProperties[i] === id) {
          wasPresent = true;
          continue;
        } else {
          updatedProperties = [...updatedProperties, watchlistedProperties[i]];
        }
      }
      if (!wasPresent) {
        updatedProperties = [...updatedProperties, id];
      }

      // favourite dependencies to be updated
      let obj = {
        userId: userInfo.id,
        propertyId: updatedProperties,
      };

      const response = await axios.post(
        getBaseUrl('watchlist'),
        obj,
        config,
      );
      if (response && response.status === 201) {
        if (!wasPresent) {
          toast.success('Added to wishlist', {
            position: 'bottom-center',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            transition: Zoom,
          });
        } else {
          toast.success('Removed from wishlist', {
            position: 'bottom-center',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            transition: Zoom,
          });
        }
        setFavourite(updatedProperties);
      }
    };

    return (
      <Card className={classes.root}>
        <ToastContainer
          position="bottom-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          transition={Zoom}
        />
        <CardContent className={classes.propertyCardFeature}>
          <Grid container>
            <Paper
              style={{
                background: 'white',
                padding: '.24rem',
                margin: '0 .1rem',
              }}
            >
              <Typography
                variant="subtitle2"
                className={classes.topTags}
                style={{ color: '#FFAD3C' }}
              >
                Selling Fast
              </Typography>
            </Paper>
            <Paper
              style={{
                background: 'white',
                padding: '.24rem',
                margin: '0 .2rem',
              }}
            >
              <Typography
                variant="subtitle2"
                className={classes.topTags}
                color="primary"
              >
                Residential
              </Typography>
            </Paper>
          </Grid>
          {/* {favourite && favourite.indexOf(id) !== -1 ? (
            <FavoriteIcon
              className={classes.expand}
              onClick={() => moveToWishList(id)}
              htmlColor="#fff"
            />
          ) : (
            <FavoriteBorderIcon
              className={classes.expand}
              onClick={() => moveToWishList(id)}
              htmlColor="#fff"
            />
          )} */}
        </CardContent>

        <CardMedia
          component="img"
          className={classes.cardMedia}
          alt=""
          height="200"
          image={
            marketing && marketing.coverImage
              ? marketing.coverImage
              : 'https://images.unsplash.com/photo-1535919020263-f79f5313f336?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
          }
          title=""
        />

        <CardContent
          className={classes.cardContent}
          // onClick={() => history.push(`${url}/${id}`)}
        >
          <Grid container spacing={3}>
            <CardHeader
              avatar={
                <Avatar
                  className={classes.cover}
                  src={marketing && marketing.images[0]}
                  alt=""
                  variant="square"
                />
              }
              title={<Typography variant="subtitle2">{name}</Typography>}
              subheader={<Typography variant="caption">{subHeader}</Typography>}
            />
          </Grid>
        </CardContent>
        <Divider />
        <CardContent
          className={classes.cardContent}
          // onClick={() => history.push(`${url}/${id}`)}
        >
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Typography variant="body2">Targetted IRR</Typography>
              <Typography gutterBottom variant="h6">
                {roi.IRR} %
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" align="right">
                Net Cash Field
              </Typography>
              <Typography gutterBottom variant="h6" align="right">
                {roi.averageCashYield}%
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Targetted Equity</Typography>
              <Typography gutterBottom variant="h6">
                {roi.equityMultiple}x
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" align="right">
                Hold Period
              </Typography>
              <Typography gutterBottom variant="h6" align="right">
                {roi.minHoldPeriod}
              </Typography>
            </Grid>
          </Grid>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box width="20%" mr={1}></Box>
            <Box minWidth={35} mr={1}>
              <Typography variant="body2" style={{ color: '#54B98A' }}>
                {percentResult} % Funded
              </Typography>
            </Box>
          </Box>

          <div
            className=""
            style={{ border: '1.44px solid #f5f5f5', padding: '.28rem' }}
          >
            <BorderLinearProgress variant="determinate" value={percentResult} />
          </div>
        </CardContent>
      </Card>
    );
  },
);

export default CuratedProperty;
